import { commonActionsLabelTranslationPrefix } from "@core/models/filter-types.model";
import { IRawForm, IRawFormBase } from "@core/models/raw-form.types";
import { LOOKUP_PATHWAY } from "@core/services/lookup.service";
import { getLabel } from "@core/utilities/raw-form.utils";
import { SOURCE_DATA_TYPE } from "@shared/components/stepper/stepper.const";
import { ActionOptionType, ITableFilterActionModel } from "@shared/models/table-filter.models";
import { AUTO_WIDTH_FOR_COLUMNS } from "@shared/models/table-view.model";

const TABLE_HEADER_TYPE = 'CARBON_INTENSITY_PATHWAY_REPORT'; // CARBON_INTENSITY_PATHWAY_REPORT
export const CI_PATHWAY_REPORT_ID_KEY = 'CI_PATH_REP_ID';
export const CI_PATHWAY_REPORT_COMPLIANCE_PERIOD_FLD = 'compliancePeriod';
export const CI_PATHWAY_REPORT_DETAILS_PREFIX = 'CARBON_INTENSITY_PATHWAY_REPORT.reportDetails';
export const CI_PATHWAY_REPORT_POPUP_COMPLIANCE_PERIOD_FLD_LABEL = 'createReportCompliancePeriod';
export const CI_PATHWAY_REPORT_FACILITY_FLD = 'facility';
export const CI_PATHWAY_REPORT_FACILITY_FLD_LABEL = 'createReportfacility';
export const CI_PATHWAY_REPORT_FUEL_TYPE_FLD = 'fueltype';
export const CI_PATHWAY_REPORT_FUEL_TYPE_FLD_LABEL = 'createReportfueltype';
export const CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD = 'approvedCiId';
export const CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD_LABEL = 'createReportapprovedCiId';
export const CI_PREFIX = 'CARBON_INTENSITY_PATHWAY_REPORT';
export const CI_DATA_KEY = 'CI_PATHWAY_DATA';
export const NEW_CI_PATHWAY_CREATION_PAYLOAD_DATA_KEY = 'NEW_CI_PATHWAY_CREATION_PAYLOAD';
export const CI_CURRENT_STEP_KEY = 'CI_PATHWAY_STEP_KEY';
export const CI_PATHWAY_ADD_DETAIL_PREFIX = '.actualCIInformation';
export const CIPathwayApplyPrefix = (value: string, subPrefix: string = ''): string => `${CI_PREFIX}${subPrefix}.${value}`;
export const CI_PATHWAY_REPORT_LIST_TABLE_FILTER: ITableFilterActionModel = {
  filterListsModel: [],
  searchBox: true,
  searchButtons: false,
  dateRange: true,
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: TABLE_HEADER_TYPE,
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: TABLE_HEADER_TYPE,
        },
      ],
    },
  ],
  options: {
    dateRange: {
      placeholder: 'COMPLIANCE_REPORT.table.submittedOn',
    },
  },
};

export const CI_PATHWAY_REPORT_LIST_TABLE_CONFIG = {
  tableHeaderType: TABLE_HEADER_TYPE,
  tableFilter: CI_PATHWAY_REPORT_LIST_TABLE_FILTER,
  filtersFromConfig: true,
  hasViewTemple: true,
  hasActionTemple: true,
  adminFilters: ['facilityNames'],
};

export const getStaticText = (label: string, className: string = 'col-12', hideExpression?,): IRawForm => {
  return getLabel({
    label,
    className,
    hideExpression,
  });
};

export const CI_PATHWAY_REPORT_STEPS = {
  ACTUAL_CI_INFO: 'ActualCiInfoComponent',
  UPLOAD_ADDITIONAL_DOCUMENTS: 'CiUploadAdditionalDocumentsComponent',
  CONTACT_PERSON_INFO: 'CiContactPersonInfoComponent',
  SUMMARY: 'CiSummaryComponent'

};

export const CI_PATHWAY_REPORT = {
  //  page_title: 'Application Co-processed Low CI fuel',

  steps: [
    {
      id: 1,
      name: 'actualCIInfo',
      completed: false,
      component: CI_PATHWAY_REPORT_STEPS.ACTUAL_CI_INFO,
      code: 'STEP-1'
    },
    {
      id: 2,
      name: 'uploadAdditionalDoc',
      completed: false,
      component: CI_PATHWAY_REPORT_STEPS.UPLOAD_ADDITIONAL_DOCUMENTS,
      code: 'STEP-2'
      // inputData: {
      //   ciApplication: true,
      //   step: '2',
      // },
    },
    {
      id: 3,
      name: 'CIcontactPersonInfo',
      component: CI_PATHWAY_REPORT_STEPS.CONTACT_PERSON_INFO,
      code: 'STEP-3'
    },
    {
      id: 4,
      name: 'CIsummary',
      component: CI_PATHWAY_REPORT_STEPS.SUMMARY,
      code: 'STEP-4'
      // inputData: {
      //   documentType: 'ci',
      // },
    },
  ],

  buttons: {
    showSave: true,
    showSubmit: true,
    submitPrefix: 'submitRegistrationReportButton',
  },
};

export const CI_PATHWAY_REPORT_SECTION1: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'facilityName',
        label: CIPathwayApplyPrefix('approvedCIFacility', CI_PATHWAY_ADD_DETAIL_PREFIX),
        disabled: true,
        required: true,
        id: `approvedCIFacility`,
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'ciApplicationDto.fuel.name',
        label: CIPathwayApplyPrefix('approvedTypeOfCI', CI_PATHWAY_ADD_DETAIL_PREFIX),
        required: true,
        disabled: true,
        id: 'approvedTypeOfCI',
      },
    ],
  },
];

export const CI_PATHWAY_REPORT_SECTION1A: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'facilityName',
        label: CIPathwayApplyPrefix('chargedCIFacility', CI_PATHWAY_ADD_DETAIL_PREFIX),
        disabled: true,
        required: true,
        id: `approvedCIFacility`,
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'ciApplicationDto.fuel.name',
        label: CIPathwayApplyPrefix('approvedTypeOfCI', CI_PATHWAY_ADD_DETAIL_PREFIX),
        required: true,
        disabled: true,
        id: 'approvedTypeOfCI',
      },
    ],
  },
];

export const CI_PATHWAY_REPORT_SECTION1B: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'facilityName',
        label: CIPathwayApplyPrefix('fuelingCIFacility', CI_PATHWAY_ADD_DETAIL_PREFIX),
        disabled: true,
        required: true,
        id: `approvedCIFacility`,
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'ciApplicationDto.fuel.name',
        label: CIPathwayApplyPrefix('approvedTypeOfCI', CI_PATHWAY_ADD_DETAIL_PREFIX),
        required: true,
        disabled: true,
        id: 'approvedTypeOfCI',
      },
    ],
  },
];

export const CI_PATHWAY_REPORT_SECTION2: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col-6',
        key: 'ciApplicationDto.feedStock.id',
        id: 'feedStock',
        disabled: true,
        required: true,
        translatePrefix: 'COMMON.feedstockList',
        source: SOURCE_DATA_TYPE.FEED_STOCK,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_1',
        placeholder: 'selectItem',
      },
      {
        key: 'ciApplicationDto.feedstockRegion',
        type: 'input',
        className: 'col-6',
        label: CIPathwayApplyPrefix('approvedFeedstockRegion', CI_PATHWAY_ADD_DETAIL_PREFIX),
        required: true,
        id: 'feedstockRegion',
        disabled: true,
      },
    ],
  },
];

export const CI_PATHWAY_REPORT_LCA2: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'ciApplicationDto.feedstockRegion',
        label: CIPathwayApplyPrefix('approvedFeedstockRegion', CI_PATHWAY_ADD_DETAIL_PREFIX),
        required: true,
        disabled: true,
        id: 'approvedFeedstockRegionLCA',
        hideExpression: 'model.ciApplicationDto.type === "CI_CHARGING_STATIONS" || model.ciApplicationDto.type === "CI_FUELING_STATIONS"',
      },
    ]
  }

]

export const CI_PATHWAY_REPORT_SECTION3: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-12',
        key: 'approvedCarbonIntensity',
        label: CIPathwayApplyPrefix('approvedCIUnderSection85', CI_PATHWAY_ADD_DETAIL_PREFIX),
        required: true,
        disabled: true,
        id: 'approvedCIUnderSection85',
      },
      {
        type: 'input',
        className: 'col-12',
        key: 'ciApplicationDto.instrumentId',
        label: CIPathwayApplyPrefix('approvedAlphanumeric', CI_PATHWAY_ADD_DETAIL_PREFIX),
        required: true,
        disabled: true,
        id: 'approvedAlphanumeric',
      },
      getStaticText(CIPathwayApplyPrefix('approvedSubHeadingForSection3', CI_PATHWAY_ADD_DETAIL_PREFIX), 'col-12'),
    ],
  },
];


export const CI_INFO_ENERGY_TABLE_CONFIG_13 = {
  translationPrefix: 'SUBMISSION_MANAGEMENT_MODULE.ci_info',
  paginator: false,
  rowsPerPage: 20,
  dataKey: 'dataKey',
  columns: [
    // {
    //   field: 'id',
    //   sortField: 'id',
    //   header: 'field_15',
    //   width: AUTO_WIDTH_FOR_COLUMNS,
    //   useDropDownTemplate: true,
    //   dictionary: SOURCE_DATA_TYPE.LOOKUP_TYPE_OF_ENERGY,
    // },
    {
      field: 'name',
      sortField: 'name',
      header: 'field_16',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'quantity',
      sortField: 'quantity',
      header: 'field_17',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};

export const CI_INFO_ENERGY_TABLE_13 = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col',
        key: 'id',
        id: 'id',
        disabled: false,
        required: true,
        source: SOURCE_DATA_TYPE.LOOKUP_TYPE_OF_ENERGY,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_15',
        translatePrefix: 'SUBMISSION_MANAGEMENT_MODULE.ci_info',
        translateOptions: true,
      },
      {
        type: 'input',
        id: 'quantity',
        key: 'quantity',
        className: 'col',
        required: true,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_17',
        customTemplateOptions: {
          type: 'number',
        },
      },
    ],
  },
];

export const CI_PATHWAY_REPORT_CONTACT_PERSON: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'checkbox',
        key: 'contactPersonSame',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.sameAsContactPersonOrg',
        disabled: false,
        required: false,
        className: 'col-6',
        id: 'contactPersonSame',
        disabledExpression: 'model._disabled',
      },
      {
        type: 'checkbox',
        key: 'sameAsAuthorizedOfficial',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.sameAsAuthorizedOfficial',
        disabled: false,
        required: false,
        className: 'col-6',
        id: 'sameAsAuthorizedOfficial',
        disabledExpression: 'model._disabled',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'title',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.title',
        disabled: false,
        required: true,
        id: 'title',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'name',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.name',
        disabled: false,
        required: true,
        id: 'name',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'businessTelephone',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.telephoneNum',
        disabled: false,
        required: true,
        id: 'telephoneNum',
        placeholder: 'COMMON.messageSection.phonePlaceholder',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'email',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.email',
        disabled: false,
        required: true,
        id: 'email',
      },
    ],
  },
];

export const CI_PATHWAY_REPORT_CONTACT_PERSON_POSTAL_ADDRESS_FORM_SECTION = {
  prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
  key: 'postalAddress',
  changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
  sameAsOrganization: {
    hide: false,
    changeExpr: null,
  },
  hidePoBox: false,
  sameAsPostal: {
    hide: true,
  },
  hideLatLong: true,
};

export const CI_PATHWAY_REPORT_CONTACT_PERSON_CIVIC_ADDRESS_FORM_SECTION = {
  prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
  key: 'civicAddress',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: true,
  sameAsPostal: {
      hide: false,
      changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
  },
  hideLatLong: true,
}


