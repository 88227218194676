import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { ITaskAction, ITaskProgramField, TTaskFieldset, TTaskProgramType } from '@core/models/task.types';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { ObligationManagementService } from '@module/obligation-management/services/obligation-management.service';
import { TranslateService } from '@ngx-translate/core';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { taskAssignToAdminConfig, taskNotesConfig, TASKTYPE_ACTION, TASKTYPE_FIELDSET_MAP } from '@shared/components/task-details/task-details.const';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { TasksService } from '@shared/services/tasks.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
})
export class TaskDetailsComponent implements OnInit {

  @Input() task: IApp.ITask;
  @Input() showAction = false;
  @Output() successfullAction = new EventEmitter<boolean>();

  fieldList: TTaskFieldset;
  action: ITaskAction;
  private _destroy$ = new Subject<any>();

  constructor(
     private store: StoreService,
     private translate: TranslateService,
     private taskService: TasksService,
     private lookupservice: LookupService,
     private obligationManagementService: ObligationManagementService,
     private modalService: ModalService,
     private helperService: HelperService,
     private router: Router,
   ) { }


  ngOnInit() {
    const isAdmin =  this.store.user.hasPermission('TYPE_ADMIN') ;
    let hasPermission = isAdmin ? this.store.user.hasPermission(this.task.auditablePermissionName) :
    this.store.user.hasPermissionForAccount(this.task.auditablePermissionName, this.task.accountId, this.task.masterAccountId);
    if (!isAdmin && !hasPermission) {
      if (this.task.userType === 'TYPE_VB_MAO' &&
      (!isEmpty(this.task.verificationViewDto) && (this.task.verificationViewDto.assignmentRole == 'TYPE_PVB_MAO' ||
      this.task.verificationViewDto.assignmentRole == this.task.auditablePermissionName))) {
        hasPermission = true;
      }
    }
    this.showAction  = this.showAction && hasPermission ;
    this.fieldList = this.getTaskFieldset(this.task.taskType);
    this.action = TASKTYPE_ACTION[this.task.taskType] || {};

    if (this.task.entityActionList) {
      this.task.entityActionList.forEach(option => option.label = this.translate.instant(`COMMON.actionsLabel.${option.workflowAction}`));
    }

    let entityJson ;
    if (this.task.entityJson) {
      entityJson = JSON.parse(this.task.entityJson);
     }
    this.setFieldSet(isAdmin, entityJson);

    this.task.transferQuantity = this.task.quantity;
    this.task.compliancePeriod = this.task.complianceYear;

    if (this.task.taskType.toString() === 'SECTION_58_REPORT_SUBMISSION') {
      this.obligationManagementService
          .getPaymentById(this.task.entityId)
          .subscribe(result => {
            this.task.itpRefNumber = result.itpRefNumber;
            this.task.intendedPaymentDateStr = result.intendedPaymentDateStr;
            this.task.intendedPaymentAmount = result.intendedPaymentAmount.toLocaleString('en-US');
            this.task.accountName = result.facilityName;
          });
    } else if (this.task.obligationId) {
      this.taskService.obligationDetails(this.task.obligationId).subscribe(t => {
        if (t) {
          this.task.regularRateDeadline = t.regularRateDeadlineStr;
          this.task.increasedRateDeadline = t.increasedRateDeadlineStr ? t.increasedRateDeadlineStr : 'N/A';
          this.task.obligationAssignmentDate = t.assignmentDate;
          this.task.outstandingObligation = t.outstandingObligation.toLocaleString('en-US');
          this.task.compensationOutstandingObligation = this.task.outstandingObligation;
          this.task.reportingPeriod = `${t.reportingPeriodStartDate} - ${t.reportingPeriodEndDate}`
        }
      });
    } else if(this.task.taskType.toString() === 'USER_REVIEW_AO_REPLACEMENT'
          && this.store.user.isOffset()){
            this.task['role'] =  this.translate.instant(`COMMON.offsetRoleList.${this.task['role']}`)
    }

    if (this.task.entityType && this.task.entityType === 'NOTICE_OF_ERROR') {
      this.task.noenmiComments = this.task.nmiComments;
    }
    if (this.task.entityType && this.task.entityType === 'COMPLIMENTRY_COMPLIANCE_REPORT') {
      this.task.participantComments = this.task.comments;
    }
    console.log(this.fieldList);
    console.log(this.task);
  }

  private getTaskFieldset(taskType: string): TTaskFieldset {
    const program = this.store.user.program;
    return TASKTYPE_FIELDSET_MAP[taskType]
      ? TASKTYPE_FIELDSET_MAP[taskType]
        .filter(f => {
          return typeof f === 'string'
            || (typeof f === 'object' && f[Object.keys(f)[0]].includes(program as TTaskProgramType));
        })
        .map(f => typeof f === 'object' && Object.keys(f)[0] || f)
      : [];
  }

  setFieldSet(isAdmin: boolean, entityJson: any) {
     if (this.task.entityType === 'APPLICATION') {
      const entityType = this.task.taskType.toString() === 'APPLICATION_ASSIGNED_VFB' ? 'VERIFICATION' : this.task.entityType;
      this.task.participantOrganizationName = this.task.accountName;
      const taskType = isAdmin ? `${entityType}_${ entityJson.submissionType}_ADMIN` :  `${entityType}_${ entityJson.submissionType}`;
      this.fieldList = this.getTaskFieldset(taskType);
        } else if (this._isNmiCommentsVisible(entityJson)) {
          this.fieldList = [...this.getTaskFieldset(this.task.taskType)];
          this.fieldList.push('nmiComments');
          this.fieldList.push('regulateeResponse');
        } else {
          this.fieldList = this.getTaskFieldset(this.task.taskType);
        }
  }

  doAction(workflowAction: string) {
    const subAction = !['VIEW_DETAIL', 'TAKE_ACTION', 'RESPOND_TO_NOTICE'].includes(workflowAction) ? workflowAction : null;
    this.taskService.doAction(this.task, subAction);
  }

  public triggerActionController(action: any): void {

    switch (action.workflowAction) {

       case 'VIEW_HISTORY':
       this.router.navigate(this.helperService.getTranslatedPath(`/tasks/history/${ this.task.id }`));
       break;

      case 'TAKE_ACTION':
      case 'VIEW_DETAIL':
        this.taskService.doAction(this.task);
        break;

      case 'ASSIGN_TO_ME':
        this.taskService.assignToMe(this.task.id)
        .pipe(
          takeUntil(this._destroy$),
        ).subscribe(
          data => {
            this.successfullAction.emit(true);
          },
        );
        break;

       case 'UNASSIGN':
          this.taskService.unassign(this.task.id)
          .pipe(
            takeUntil(this._destroy$),
          ).subscribe(
            data => {
              this.successfullAction.emit(true);
            },
          );
          break;
      case 'ASSIGN_TO_ADMIN_USER':
      case 'RE_ASSIGN':
        this.openAsignToAdminPop();
        break;

      case 'ADD_NOTE':
        this.openNotesPopUp();
        break;

      case 'VIEW_REGISTRATION_REPORT':
        const reportType = this.task.accountSubTypes === 'VB' ? 'VB': 'PS';
        this.router.navigate(this.helperService.getTranslatedPath(
          `/cfr-registration/cfr/RR${reportType}/${this.task.accountId}/${this.task.entityId}`
        ));
        break;

      case 'VIEW_CREDIT_ADJUSTMENT_REPORT':
        this.router.navigate(this.helperService.getTranslatedPath(
          `/credit-adjustment-reports/report/${ this.task.entityId }`
        ));
        break;

      case 'VIEW_CREDIT_BALANCE_REPORT':
        this.router.navigate(this.helperService.getTranslatedPath(
          `/credit-balance/report/${ this.task.entityId }`
        ));
        break;
      case 'VIEW_CREDIT_REVENUE_REPORT':
        this.router.navigate(this.helperService.getTranslatedPath(
          `/credit-revenue/report/${ this.task.entityId }`
        ));
        break;
       case 'VIEW_COMPLIMENTRY_COMPLIANCE_REPORT':
        this.router.navigate(this.helperService.getTranslatedPath(
          `/complimentary-compliance-report/report/${ this.task.entityId }`
        ));
        break;
        case 'VIEW_CI_PATHWAY_REPORT':
          this.router.navigate(this.helperService.getTranslatedPath(
            `/ci-pathway-reports/report/${this.task.entityId}`
          ));        
    }

  }

  private openNotesPopUp() {
    const defaultValues =  {
      id: this.task.id,
      notes: this.task.notes,
    };
    this.modalService.open(TableNewRowComponent, { form: taskNotesConfig, defaultValues,
      actionType: TABLE_NEW_ROW_ACTION.EDIT } as TableNewRow).afterClosed().subscribe(data => {
      this.successfullAction.emit(true);
    });
  }

  private openAsignToAdminPop() {
    const defaultValues =  {
      id: this.task.id,
      assignedTo: this.task.assignedTo ? `${this.task.assignedTo}` : null,
    };
    this.modalService.open(TableNewRowComponent, { form: taskAssignToAdminConfig, defaultValues,
      actionType: TABLE_NEW_ROW_ACTION.EDIT, editTitle: 'assignToAdminUser' } as TableNewRow).afterClosed().subscribe(data => {
        this.successfullAction.emit(true);
      });
  }

  private _isNmiCommentsVisible(entityJson) {
    return (this.task.taskType.toString() === 'ACCOUNT_REVIEW' ||
      this.task.taskType.toString() === 'REVIEW_RESPONSE_FOR_REGISTRATION_REPORT_REVIEW') &&
      entityJson && entityJson.regulateeResponse;
  }

}
