import { IApp } from '@core/models/app.interfaces';
import { CarbonIntensityTableFilter } from '@core/models/filter-types.model';


export const CI_CANCEL_PREFIX = 'ciCancel';
export const CI_MARK_AS_ACTIVE_PREFIX = 'ciMarkAsActive';
export const CI_MARK_AS_INVALID_PREFIX = 'ciMarkAsInvalid';
export const CI_MARK_AS_UNDER_REVIEW_PREFIX = 'ciMarkAsUnderReview';

export const CARBON_INTENSITY_TABLE_DETAILS = {
  tableListApiUri: '/cfr-service/application/pageSearch',
  tableHeaderType: 'CARBON_INTENSITY_SEARCH',
  tableFilter: CarbonIntensityTableFilter,
  hasViewTemple: false,
  hasActionTemple: true,
};

export const CANCELLATION = {
  action: 'CANCEL',
  uiUri: '/carbon-intensity/cancel-ci',
  uri: '/reviewCancellationCarbonIntensity',
  type: 'CI_CARBON_INTENSITY_CANCELLATION',
  id: 'cancellationId',
  status: 'cancellationStatus',
  prefix: CI_CANCEL_PREFIX
};

export const REQUEST_CI_CANCELLATION_ADMIN = {
  action: 'CANCEL',
  uiUri: '/carbon-intensity/cancel-ci',
  uri: '/reviewCancellationCarbonIntensity',
  type: 'CI_CARBON_INTENSITY_CANCELLATION',
  id: null,
  status: null,
  prefix: CI_CANCEL_PREFIX
};

export const REQUEST_CANCELLATION = {
  action: 'SUBMIT',
  uiUri: '/carbon-intensity/cancel-ci',
  type: 'CI_CARBON_INTENSITY_CANCELLATION',
  uri: '/cancelCarbonIntensity',
  id: null,
  status: null,
  prefix: CI_CANCEL_PREFIX,
  actionLabel: 'requestCancellationFor',
};

export const VIEW_ASSOCIATED_PATHWAY = {
  uiUri: '/submission-management/wizard/application/${pathwayId}'
}

export const MARK_AS_ACTIVE = {
  action: 'CANCEL',
  uiUri: '/carbon-intensity/mark-as-active', 
  uri: '/markAsActive',
  type: 'CI_PRODUCTION_OF_LOW_CI_FUELS',
  id: 'id',
  status: null,
  entityAction: 'mark-as-active',
  prefix: CI_MARK_AS_ACTIVE_PREFIX
};

export const MARK_AS_INVALID = {
  action: 'CANCEL',
  uiUri: '/carbon-intensity/mark-as-invalid', 
  uri: '/markAsInvalid',
  type: 'CI_PRODUCTION_OF_LOW_CI_FUELS',
  id: 'id',
  status: null,
  entityAction: 'mark-as-invalid',
  prefix: CI_MARK_AS_INVALID_PREFIX
};

export const VIEW_INVALIDATION_REQUEST = {
  ...MARK_AS_INVALID,
  entityAction: 'invalid-ci'
};


export const MARK_UNDER_REVIEW = {
  action: 'CANCEL',
  uiUri: '/carbon-intensity/mark-as-under-review', 
  uri: '/markAsUnderReview',
  type: 'CI_PRODUCTION_OF_LOW_CI_FUELS',
  id: 'id',
  entityAction: 'mark-as-under-review',
  status: null,
  prefix: CI_MARK_AS_UNDER_REVIEW_PREFIX
};

export const VIEW_UNDER_REVIEW_REQUEST = {
  ...MARK_UNDER_REVIEW,
  entityAction: 'view-under-review-request',
};

export const VIEW_ACTIVATION_REQUEST = {
  ...MARK_AS_ACTIVE,
  entityAction: 'view-activation-request',
};

export const CARBON_INTENSITY_ACTIONS: IApp.ITableAction = {
  VIEW_CI_APPLICATION: {
    uiUri: '/submission-management/wizard/application/${entityId}',
  },
  VIEW_CANCELLATION: CANCELLATION,
  APPROVE_CANCELLATION: CANCELLATION,
  REVIEW_REQUEST_CANCELLATION: CANCELLATION,
  REJECT_REQUEST_CANCELLATION: CANCELLATION,
  REQUEST_CANCELLATION,
  REQUEST_CI_CANCELLATION_ADMIN,
  VIEW_ASSOCIATED_PATHWAY,
  MARK_AS_ACTIVE,
  MARK_AS_INVALID,
  VIEW_INVALIDATION_REQUEST,
  MARK_UNDER_REVIEW,
  VIEW_UNDER_REVIEW_REQUEST,
  VIEW_ACTIVATION_REQUEST
};
