import { Component, OnInit, ViewChild, Inject, } from '@angular/core';
import { MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX,
  getOrgSearchFields,
  getMBUniqueIdentifier,
  getCITotalQuantity,
  CONTACT_PERSON_FUEL_INFO_FILEDS,
  FUEL_INFO_ITEM_2_CODE,
 } from '../material-balance-consts/material-balance.const';
import { Dialog } from 'primeng';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MaterialBalanceReportService } from '../material-balance-report.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IRawFormBase } from '@core/models/raw-form.types';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { FormFactoryService } from '@shared/services/form-factory.service';
import {
  COMP_REP_CONTACT_PERSON_CIVIC_ADDRESS_FORM_SECTION,
  COMP_REP_CONTACT_PERSON_POSTAL_ADDRESS_FORM_SECTION,
} from '@module/compliance-report/compliance-report.const';
import { cloneDeep } from 'lodash'

@Component({
  selector: 'app-material-balance-import-fuel-info-details',
  templateUrl: './material-balance-import-fuel-info-details.component.html',
  styleUrls: ['./material-balance-import-fuel-info-details.component.scss']
})
export class MaterialBalanceImportFuelInfoDetailsComponent implements OnInit {

  @ViewChild('dialog', { static: false }) dialog: Dialog;

  public translationPrefix = MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX;

  public quantityFields: FormlyFieldConfig[];
  public searchField: FormlyFieldConfig[];
  public addressFields: FormlyFieldConfig[];
  public form = new FormGroup({});
  public model: any = {};
  public organizationDetail;
  public visible = true;
  public isSearched = false;
  private selectedOrganizations = [];
  public orgErrorMsg = '';
  public stepModified = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<MaterialBalanceImportFuelInfoDetailsComponent>,
    private service: MaterialBalanceReportService,
    private submissionManagementService: SubmissionManagementService,
    private formFactoryService: FormFactoryService,
  ) { }

  ngOnInit() {
    this.model = this.data.currentData || {};
    if (this.model.fsOrganizationId) {
      this.searchOrg();
    }
    this.updateModel();
    this.searchField = this.formFactoryService.configureForm(this.getSearchField());
    const postalAddress = {
      ...COMP_REP_CONTACT_PERSON_POSTAL_ADDRESS_FORM_SECTION,
      optionalPoBox: true,
      sameAsOrganization: {
        hide: false,
        changeExpr: `this.disableForm($event, field); this.cloneFormGroup($event, field, '_organizationAddress', 'postalAddress')`,
      },
    };
    const civicAddress = {
      ...COMP_REP_CONTACT_PERSON_CIVIC_ADDRESS_FORM_SECTION,
    };
    this.addressFields = this.formFactoryService.configureForm(CONTACT_PERSON_FUEL_INFO_FILEDS);
    this.addressFields.push(this.formFactoryService.configurFormPostWithCivicAddress(postalAddress, civicAddress, '_organizationAddress')[0]);
    this.quantityFields = this.formFactoryService.configureForm(this.getQuantityFields());
  }

  private getSearchField(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          {
            groupFields: false,
            className: 'col-12',
            fieldGroup: [
              ...getOrgSearchFields('fsOrganizationId', () => this.searchOrg(), () => this.clearSearch('fsOrganizationId')),
            ],
          },
        ],
      }];
  }

  private getQuantityFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          {
            groupFields: false,
            className: 'col-12',
            fieldGroup: [
              getMBUniqueIdentifier(),
              getCITotalQuantity(),
            ],
          },
        ],
      }];
  }

  searchOrg() {
    if (this.model.fsOrganizationId && !this.selectedOrganizations.includes(this.model.fsOrganizationId)) {
      this.service.getOrganizationDetail(null, this.model.fsOrganizationId)
      .subscribe(data => {
        this.isSearched = true;
        if (data) {
          this.organizationDetail = {...data};
          this.addressFields[0].hideExpression = false;
          this.model._organizationAddress = cloneDeep(data['postalAddress']);
          if (this.data.viewMode) {
            setTimeout(() => {
              this.form.disable();
            });
          }
        } else {
          this.orgErrorMsg = 'noOrganizationFound';
          this.organizationDetail = null;
          if (this.addressFields) {
            this.addressFields[0].hideExpression = true;
          }
        }
      },
      error => {
        this.isSearched = true;
      }
    );
    } else {
      this.isSearched = true;
      this.organizationDetail = null;
      if (this.addressFields) {
        this.addressFields[0].hideExpression = true;
      }
      if (!this.model.fsOrganizationId) {
        this.orgErrorMsg = 'noOrganizationEntered';
      } else {
        this.orgErrorMsg = 'orgAlreadySelected';
      }
    }
  }

  public clearSearch(field) {
    this.isSearched = false;
    const control = this.form.get(field);
    if (control) {
      control.reset();
    } else {
      this.model[field] = null;
    }
    this.organizationDetail = null;
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  updateModel() {
    if (this.model.contactPerson) {
      this.model = { ...this.model, ...this.model.contactPerson};
      delete this.model.contactPerson;
    }
    if (this.model.fsCITotalQuantity) {
      this.data.totalSumUsed -= Number(this.model.fsCITotalQuantity);
    }
    if (this.data.tableData) {
      this.selectedOrganizations = this.data.tableData.map(( rec ) => rec.fsOrganizationId);
    }
  }

  save() {
    this.formFactoryService.markFormGroupDirty(this.form);
    if (this.form.valid) {
      if (!this.organizationDetail) {
        this.service.showError('noOrgSelected', `${this.translationPrefix}.`);
        return;
      }
      const savedData = cloneDeep(this.model);
      this.data.totalSumUsed += Number(savedData.fsCITotalQuantity);
      const totalQtyTobeUsed = this.service.currentData.reportDetails.filter(data => data.mbrLineItemCode === FUEL_INFO_ITEM_2_CODE)[0].totalQuantity;
      if (this.data.totalSumUsed > totalQtyTobeUsed) {
        this.data.totalSumUsed -= Number(savedData.fsCITotalQuantity);
        this.service.showError('quantityAddedExceededTotalQty', `${this.translationPrefix}.`);
        return;
      }
      delete savedData._organizationAddress;
      const response = {
        fsUniqueIdentifier: savedData.fsUniqueIdentifier,
        fsOrganizationId: savedData.fsOrganizationId,
        fsCITotalQuantity: savedData.fsCITotalQuantity,
        legalName: this.organizationDetail.legalName,
        id: this.data.currentData.id ? this.data.currentData.id : null,
        contactPerson: null,
        totalSumUsed: 0,
      };
      delete savedData.fsUniqueIdentifier;
      delete savedData.fsOrganizationId;
      delete savedData.fsCITotalQuantity;
      delete savedData.legalName;
      response.contactPerson = savedData;
      response.totalSumUsed = this.data.totalSumUsed;
      this.dialogRef.close(response);
    }
  }

  public close(): void {
    if (this.stepModified) {
      const sub$ = this.service.openWarningPopUp('confirmOnClose').subscribe(result => {
        sub$.unsubscribe();
        if (result) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

}
