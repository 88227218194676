import { ElementRef, OnDestroy } from '@angular/core';
import { getNestedProp, setNestedProp } from '@core/utilities/utilities.constants';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { AppInjector } from '@shared/services/app-injector.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import { omit, cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
const DEBUG_DIRTY_DATASET = false;
export class BaseStepperService extends AbstractPaginationService {
    constructor() {
        super();
        this._currentStep$ = new BehaviorSubject(null);
        this.currentStep$ = this._currentStep$.asObservable();
        this.stepValidationRequest$ = new BehaviorSubject(null);
        this.stepValidationResponse$ = new BehaviorSubject(null);
        this.nonNullObjects = [];
        this.onSaveAndProceed$ = new Subject();
        this.onSubmit$ = new Subject();
        this.onSaveAsDraft$ = new Subject();
        this.onProcessAction$ = new Subject();
        this.onError$ = new Subject();
        this._wasModified = false;
        this.fieldsToOmit = [];
        this.adminCanUpdate = new BehaviorSubject(false);
        this.prevStepData = null;
        this._wasDirty = false;
        this._destroy$ = new Subject();
        this.resetStepper();
        const injector = AppInjector.getInjector();
        if (injector) {
            this.translateService = injector.get(TranslateService);
            this.translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event) => {
                this.translateSteps();
            });
        }
    }
    set wasModified(value) {
        this._wasModified = value;
    }
    get wasModified() {
        return this._wasModified;
    }
    get scrollEl() {
        return this.scrollContainerDir;
    }
    set scrollEl(el) {
        this.scrollContainerDir = el;
    }
    resetStepper() {
        this._initialData = null;
        this._currentStep$.next({ id: 1, data: {} });
        this.prevStepData = null;
    }
    init(registrationSteps, formContainer) {
        this._registrationSteps = registrationSteps;
        this.translateSteps();
        this.hasSteps && (this.currentStep = this.currentStep);
        this.scrollContainerDir = formContainer;
    }
    get currentStep() {
        return this._currentStep$.getValue();
    }
    set currentStep(value) {
        let stepSrc = value ? this.getStepById(value.id, value.subId, false) : {};
        if (stepSrc && stepSrc.skipMainStep && !value.subId && stepSrc.subSteps && stepSrc.subSteps.length) {
            stepSrc = this.getStepById(value.id, 1, false);
            value.subId = 1;
        }
        this._currentStep$.next(Object.assign({}, stepSrc, value));
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = value;
    }
    set yearsToExclude(value) {
        this._yearsToExclude = value;
    }
    get yearsToExclude() {
        return this._yearsToExclude;
    }
    set carUnderSectionEligibleYear(value) {
        this._carUnderSectionEligibleYear = value;
    }
    get carUnderSectionEligibleYear() {
        return this._carUnderSectionEligibleYear;
    }
    disable() {
        this._disabled = true;
    }
    enable() {
        this._disabled = false;
    }
    set steps(data) {
        this._registrationSteps = data;
        this.translateSteps();
    }
    translateSteps() {
        if (!this._registrationSteps || this._registrationSteps.length === 0) {
            return [];
        }
        this._registrationStepsTranslated = [
            ...this._registrationSteps.map(step => {
                const _step = Object.assign({}, step, { name: step.skipTranslate ? step.name : this.translateService.instant(`${this.translationPrefix}${step.name}`) });
                if (step.subSteps) {
                    _step.subSteps = step.subSteps.map(subStep => (Object.assign({}, subStep, { name: subStep.skipTranslate ? subStep.name : this.translateService.instant(`${this.translationPrefix}${subStep.name}`) })));
                }
                return _step;
            }),
        ];
    }
    get steps() {
        if (!this._registrationStepsTranslated || this._registrationStepsTranslated.length === 0) {
            return [];
        }
        const steps = [
            ...this._registrationStepsTranslated.map(step => {
                const _step = Object.assign({}, step, { enabledSubSteps: step.enabledSubStepsProp
                        ? this.currentData[step.enabledSubStepsProp]
                        : step.enabledSubSteps });
                return _step;
            }),
        ];
        if (JSON.stringify(this._steps) !== JSON.stringify(steps)) {
            this._steps = JSON.parse(JSON.stringify(steps));
        }
        return this.setExtraSteps(this._steps);
    }
    setExtraSteps(steps) {
        if (this.extraSteps && this.currentData) {
            Object.keys(this.extraSteps).forEach(key => {
                if (this.currentData[key]) {
                    const extraStep = Object.assign({}, this.extraSteps[key], { id: steps.length + 1, name: this.translateService.instant(`${this.translationPrefix}${this.extraSteps[key].name}`), extra: true });
                    steps = [...steps, extraStep];
                }
            });
        }
        return steps;
    }
    set stepValidationResponse(valid) {
        this.stepValidationResponse$.next(valid);
    }
    get stepComponent() {
        const regStep = this.getStepById(this.currentStep.id, this.currentStep.subId);
        return regStep.component;
    }
    get hasSteps() {
        return this._registrationSteps && this._registrationSteps.length > 0;
    }
    getStepById(id, subId = null, stepOnly = false) {
        const step = this.steps.find(s => s.id === id);
        if (step) {
            return subId && step.subSteps
                ? stepOnly
                    ? step
                    : step.subSteps.find(ss => ss.subId === subId)
                : step;
        }
        else {
            return null;
        }
    }
    nextStep(data = null) {
        const currentStepId = this.currentStep.id;
        let currentSubStepId = this.currentStep.subId || 0;
        let nextStep = this.currentStep;
        const step = this.getStepById(currentStepId, currentSubStepId, true);
        if (step.subSteps && step.subSteps.length > 0 && step.enabledSubSteps) {
            const subSteps = step.subSteps.filter(ss => !ss.hidden);
            const _currentIdx = subSteps.findIndex(ss => ss.id === this.currentStep.id && ss.subId === this.currentStep.subId);
            const nextSubStep = subSteps[_currentIdx + 1];
            if (nextSubStep) {
                nextStep = Object.assign({}, nextStep, { subId: nextSubStep.subId, data: data ? data : nextStep.data });
            }
            else {
                currentSubStepId = null;
            }
        }
        else {
            currentSubStepId = null;
        }
        let _nextStep;
        if (currentSubStepId === null) {
            _nextStep = this.getStepById(currentStepId + 1);
            if (_nextStep && _nextStep.skipMainStep && _nextStep.subSteps && _nextStep.subSteps.length) {
                _nextStep = this.getStepById(currentStepId + 1, 1);
            }
            if (!nextStep) {
                return;
            }
            else {
                nextStep = Object.assign({}, _nextStep, { data: data ? data : nextStep.data });
            }
        }
        const nextStepSrc = this.getStepById(nextStep.id, nextStep.subId, false);
        if (nextStepSrc.inputData) {
            this.inputData = Object.assign({}, this.inputData, nextStepSrc.inputData);
        }
        this.currentStep = Object.assign({}, nextStep, nextStepSrc);
        this.updateStepNum();
        this.prevStepData = cloneDeep(this.currentStep.data);
    }
    updateStepNum() {
        let step;
        if (this.currentData.step) {
            step = parseFloat(this.currentData.step);
            const current = parseFloat(this.stepNum);
            if (current > step) {
                this.currentData.step = this.stepNum;
            }
        }
        else {
            this.currentData.step = '1';
        }
    }
    get stepNum() {
        return `${this.currentStep.id}${this.currentStep.subId ? '.' + this.currentStep.subId : ''}`;
    }
    getMaxStep() {
        return this.currentData.step && parseFloat(this.currentData.step);
    }
    prevStep(data = null) {
        const currentStepId = this.currentStep.id;
        let currentSubStepId = this.currentStep.subId;
        let prevStep = this.currentStep;
        const step = this.getStepById(currentStepId, currentSubStepId, true);
        let prevSubStep = null;
        if (currentSubStepId) {
            const subSteps = step.subSteps.filter(ss => !ss.hidden);
            const _currentIdx = subSteps.findIndex(ss => ss.id === currentStepId && ss.subId === currentSubStepId);
            prevSubStep = subSteps[_currentIdx - 1];
            if (prevSubStep) {
                currentSubStepId = prevSubStep.subId;
                prevStep = Object.assign({}, prevStep, { subId: prevSubStep.subId, data: data ? data : prevStep.data });
            }
        }
        let _prevStep;
        let skipResetSubId = false;
        if (currentSubStepId === 0 && !prevSubStep) {
            _prevStep = this.getStepById(currentStepId);
            if (_prevStep && _prevStep.skipMainStep && _prevStep.subSteps && _prevStep.subSteps.length) {
                const prevMainStep = this.getStepById(currentStepId - 1);
                if (prevMainStep.skipMainStep && prevMainStep.subSteps && prevMainStep.subSteps.length) {
                    _prevStep = this.getStepById(currentStepId - 1, prevMainStep.subSteps.length);
                    prevStep = Object.assign({}, prevStep, _prevStep);
                }
                else {
                    _prevStep = prevMainStep;
                    prevStep = Object.assign({}, prevStep, _prevStep, { subId: null });
                }
            }
            else {
                prevStep = Object.assign({}, prevStep, _prevStep, { subId: null });
            }
        }
        else if (!prevSubStep) {
            _prevStep = this.getStepById(currentStepId - 1);
            if (_prevStep && _prevStep.skipMainStep && _prevStep.subSteps && _prevStep.subSteps.length) {
                _prevStep = this.getStepById(currentStepId - 1, _prevStep.subSteps.length);
                skipResetSubId = true;
            }
            if (!_prevStep) {
                return;
            }
            if (_prevStep.subSteps &&
                _prevStep.subSteps.length > 0 &&
                _prevStep.enabledSubSteps) {
                prevStep = {
                    id: _prevStep.id,
                    subId: Math.max(..._prevStep.subSteps.map(s => s.subId)),
                    data: data ? data : prevStep.data,
                };
            }
            else {
                prevStep = {
                    id: _prevStep.id,
                    subId: skipResetSubId ? _prevStep.subId : null,
                    data: data ? data : prevStep.data,
                };
            }
        }
        prevStep = Object.assign({}, prevStep, { data: data ? data : prevStep.data });
        const prevStepSrc = this.getStepById(prevStep.id, prevStep.subId, false);
        if (prevStepSrc.inputData) {
            this.inputData = Object.assign({}, this.inputData, prevStepSrc.inputData);
        }
        this.currentStep = Object.assign({}, prevStep, prevStepSrc);
    }
    ensureObjectsNotNull(data) {
        data === null && (data = {});
        if (this.nonNullObjects && this.nonNullObjects.length > 0) {
            this.nonNullObjects.forEach(key => {
                if (getNestedProp(data, key) === null) {
                    setNestedProp(data, key, {});
                }
            });
        }
        return data;
    }
    set currentData(data) {
        this.currentStep = Object.assign({}, this.currentStep, { data: this.ensureObjectsNotNull(data) });
        this.updateStepNum();
    }
    get currentData() {
        return this.currentStep.data;
    }
    get isLastStep() {
        if (this.currentStep.subId) {
            if (!this.getStepById(this.currentStep.id, this.currentStep.subId + 1)) {
                return this.getStepById(this.currentStep.id + 1) === null;
            }
            else {
                return false;
            }
        }
        else {
            const currentStep = this.getStepById(this.currentStep.id);
            if (currentStep &&
                currentStep.subSteps &&
                (currentStep.enabledSubSteps === undefined ||
                    currentStep.enabledSubSteps)) {
                return false;
            }
            else {
                return this.getStepById(this.currentStep.id + 1) === null;
            }
        }
    }
    get subStepsChooserSelected() {
        const currentStep = this.getStepById(this.currentStep.id);
        if (currentStep.subSteps) {
            return currentStep.enabledSubSteps !== undefined;
        }
        else {
            return true;
        }
    }
    validateData() {
        this.stepValidationRequest$.next(true);
        return this.stepValidationResponse$;
    }
    disableFormIfApplicable(form) {
        if (this.disabled) {
            setTimeout(() => form.disable());
        }
    }
    initModelChangeTracking(wasModified = false) {
        this._wasModified = wasModified;
        this._wasDirty = false;
        this._initialData = JSON.parse(JSON.stringify(this.currentData));
        this.prevStepData = cloneDeep(this.currentStep.data);
    }
    get isDirty() {
        return this.checkIsDirty();
    }
    checkIsDirty() {
        if (this._initialData) {
            const fieldsToOmit = [...this.fieldsToOmit, 'step', '_organizationAddress'];
            const initialData = omit(this._initialData, fieldsToOmit);
            const currentData = omit(this.currentData, fieldsToOmit);
            const isDirty = JSON.stringify(initialData) !== JSON.stringify(currentData);
            if (DEBUG_DIRTY_DATASET) {
                console.log(initialData);
                console.log(currentData);
                console.log('IS DIRTY: ', isDirty);
            }
            if (this._wasDirty !== isDirty) {
                this._wasDirty = isDirty;
                setTimeout(() => {
                    this._wasModified = isDirty;
                });
            }
            return isDirty;
        }
        else {
            return true;
        }
    }
    get prevData() {
        return cloneDeep(this.prevStepData || this.currentData);
    }
    preventDblClick(event) {
        if (event) {
            const el = event.currentTarget;
            el.setAttribute('disabled', 'disabled');
            setTimeout(() => {
                el.removeAttribute('disabled');
            }, 1000);
        }
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
BaseStepperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseStepperService_Factory() { return new BaseStepperService(); }, token: BaseStepperService, providedIn: "root" });
