import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { CARApplyPrefix, CAR_CURRENT_STEP_KEY, CAR_DATA_KEY, CAR_NONEDITABLE_YEAR_PROPERTY, CAR_STEP_COMPONENTS, CAR_STEP_PREFIX, CAR_UNDER_SECTION_ELIGIBLE_YEAR_PROPERTY, CONFIRMATION_MESSAGES_ON_SUBMISSION, } from '@module/credit-adjustment-report/const/credit-adjustment-report.const';
import { CCR_APPROVED_CARBONINTENSITIES, CCR_SAVE_AND_PROCEED, CCR_SAVE_REPORT, CreditCreationReportsService, GET_REPORT_BY_ID, GET_REPORT_BY_ID_AND_STEP } from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { sortBy } from 'lodash';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../shared/services/helper.service";
import * as i4 from "../../shared/services/modal.service";
import * as i5 from "../../core/store/store.service";
export const CAR_FACILITIES_URI = '/cfr-service/creditCreationReport/getFacilities';
export const CAR_CARBON_INTENSITY_IDS_LOOKUP_URI = '/cfr-service/application/lookupCarbonIntensityIDs';
export const CAR_FEEDSTOCK_TYPE_URI = '/account-service/application/getForCCRLookup';
export const CAR_APPLICATION_DETAILS_URI = CAR_FEEDSTOCK_TYPE_URI;
export const CAR_FUELS_FOR_SUBTYPE = '/cfr-service/complianceReport/getFuelsForSubType';
export const CAR_GET_ACTIVE_CREDITS = '/obps-service/findActiveCredits';
export const CAR_CREDIT_TYPE_REPLACEMENT_URI = '/cfr-service/creditCreationReport/getCreditTypeForFuelType';
export const GET_FINAL_SUMMARY_BY_ID = '/cfr-service/creditCreationReport/getFinalCARSummary/';
export const CAR_CREDIT_BLOCKS = '/obps-service/unit/findAll';
export const CAR_UNDER_SECTION_TABLE_DATA_URI = '/cfr-service/creditCreationReport/getFinalCi';
export const CAR_GET_REPORT_DATA_FOR_CI_UNDER_SECTION = '/cfr-service/creditCreationReport/getReportDataForCi';
export class CreditAdjustmentReportService extends CreditCreationReportsService {
    constructor(http, router, helperService, modalService, store) {
        super(http, router, helperService, modalService, store);
        this.http = http;
        this.router = router;
        this.helperService = helperService;
        this.modalService = modalService;
        this.store = store;
        this._report = new BehaviorSubject(null);
        this.stepRoutineMap = {};
        this.currentStepInvalid = false;
    }
    set report(report) {
        this._report.next(report);
    }
    get report() {
        return this._report.getValue();
    }
    getFacilities(payload) {
        return this.http.post(`${environment.apiUrl}${CAR_FACILITIES_URI}`, payload);
    }
    getAlphanumericIDofCI(payload) {
        return this.http.post(`${environment.apiUrl}${CAR_CARBON_INTENSITY_IDS_LOOKUP_URI}`, payload);
    }
    getAdjustmentReportById(reportId, summary) {
        const uri = summary ? `${reportId}/${summary}` : `${reportId}`;
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID}${uri}`);
    }
    getReportByIdAndStep(reportId, currentStepCode, quarter, summary = false) {
        const uri = summary ? `${reportId}/${summary}` : quarter !== undefined ? `${reportId}/${currentStepCode}/${quarter}` : `${reportId}/${currentStepCode}`;
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID_AND_STEP}${uri}`);
    }
    getUnderSectionTableData(payload) {
        return this.http.post(`${environment.apiUrl}${CAR_UNDER_SECTION_TABLE_DATA_URI}`, Object.assign({}, payload));
    }
    getReportDataForCi(payload) {
        return this.http.post(`${environment.apiUrl}${CAR_GET_REPORT_DATA_FOR_CI_UNDER_SECTION}`, Object.assign({}, payload));
    }
    getSummaryReportById(reportId, summary, quarter) {
        const uri = `${reportId}/${summary}/${quarter}`;
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID}${uri}`);
    }
    getFinalCARSummary(reportId) {
        return this.http.get(`${environment.apiUrl}${GET_FINAL_SUMMARY_BY_ID}${reportId}`);
    }
    getStepContent(step, index, subStepIndex = null) {
        let component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_REPORT_STEP;
        if (step.code === 'STEP-5') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_EXPORT_LOW_CI_FUELS;
        }
        else if (step.code === 'STEP-SEC_88_90') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_UNDER_SECTION;
        }
        else if (step.code === 'STEP-6') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_OTHER_ADJUSTMENTS;
        }
        else if (step.code === 'STEP-7') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_REPORT_STEP;
        }
        else if (step.code === 'STEP-8') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_DOCUMENTS;
        }
        else if (step.code === 'STEP-9') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_CONTACT_PERSON;
        }
        else if (step.code === 'STEP-10') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_SUMMARY;
        }
        const _step = Object.assign({}, step, { name: this.getStepName(step[this.translateService.currentLang]), skipTranslate: true, completed: false, creditCreationReportId: step.creditCreationReportId, id: index + 1, component });
        _step.id = index + 1;
        if (subStepIndex !== null) {
            _step.subId = subStepIndex + 1;
        }
        return _step;
    }
    getSubStepContent(step, index, subStepIndex = null) {
        let component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_REPORT_STEP;
        let name = this.getStepName(step[this.translateService.currentLang]);
        if (step.code === 'STEP-11') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_SUB_STEP_SUMMARY;
            if (step.translate) {
                name = this.translateService.instant(CARApplyPrefix(step.codeToTranslate, CAR_STEP_PREFIX));
            }
        }
        if (step.code === 'STEP-SUB_SEC_90') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_UNDER_SECTION_DETAILS;
        }
        if (step.code === 'STEP-SUB_SEC_88') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_UNDER_SECTION_DETAILS;
        }
        if (step.code === 'STEP-SUB_SEC_SUMMRY') {
            component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_UNDER_SECTION_SUMMARY;
        }
        name = name.toLocaleLowerCase();
        const _step = Object.assign({}, step, { name: name.charAt(0).toUpperCase() + name.slice(1), skipTranslate: true, completed: false, id: index + 1, component });
        _step.id = index + 1;
        _step.subId = subStepIndex + 1;
        return _step;
    }
    setReportSteps() {
        const steps = this.currentData.associatedSteps.map((step, index) => {
            const currentStepIdentifier = step.code && step.code.split('-')[1].trim();
            const _step = this.getStepContent(step, index);
            const subSteps = [];
            if (['Q1', 'Q2', 'Q3', 'Q4'].includes(currentStepIdentifier)) {
                const currentSubSteps = this.currentData.associatedCreditAdjustmentSteps.filter(el => el.quarterCode === currentStepIdentifier)[0];
                if (currentSubSteps.associatedSteps && currentSubSteps.associatedSteps.length) {
                    sortBy(currentSubSteps.associatedSteps, ['sequence']).map((subStep, j) => {
                        subSteps.push(Object.assign({}, this.getSubStepContent(subStep, index, j), { creditCreationReportId: currentSubSteps.creditCreationReportId, creditCreationReportQuarterCode: currentSubSteps.quarterCode, nilReport: currentSubSteps.nilReport }));
                    });
                }
            }
            else if (step.code === 'STEP-SEC_88_90') {
                if (this.currentData.associatedCreditAdjustmentSubSteps && this.currentData.associatedCreditAdjustmentSubSteps.length) {
                    const underSectionSubsteps = this.currentData.associatedCreditAdjustmentSubSteps.filter(ele => ele.parentId === step.id);
                    sortBy(underSectionSubsteps, ['sequence']).map((subStep, j) => {
                        subSteps.push(Object.assign({}, this.getSubStepContent(subStep, index, j)));
                    });
                }
            }
            if (subSteps.length) {
                _step.subSteps = subSteps;
                _step.skipMainStep = true;
                if (step.code === 'STEP-SEC_88_90') {
                    _step.skipMainStep = false;
                }
                _step.enabledSubSteps = true;
            }
            return _step;
        });
        this.steps = [...steps];
        return [...steps];
    }
    getStepName(val) {
        const arr = val.split(': ');
        arr.shift();
        return arr.join(': ');
    }
    saveAndProceed(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_SAVE_AND_PROCEED}`, Object.assign({}, payload, { action: 'SAVE' }));
    }
    saveReport(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_SAVE_REPORT}`, Object.assign({}, payload, { action: 'SAVE' }));
    }
    get quarterNumber() {
        return this.currentStep && this.currentStep.code && Number(this.currentStep.code.replace('STEP-Q', ''));
    }
    get quarter() {
        return this.currentStep && this.currentStep.code && this.currentStep.code.replace('STEP-', '');
    }
    getFuelsForSubType(subType) {
        return this.http.get(`${environment.apiUrl}${CAR_FUELS_FOR_SUBTYPE}/${subType}`);
    }
    fetchProperty() {
        let propertyUrl = CAR_NONEDITABLE_YEAR_PROPERTY;
        return this.http.get(`${environment.apiUrl}${propertyUrl}`);
    }
    fetchCARUnderSectionEligibleYear() {
        let propertyUrl = CAR_UNDER_SECTION_ELIGIBLE_YEAR_PROPERTY;
        return this.http.get(`${environment.apiUrl}${propertyUrl}`);
    }
    getActiveCredits(accountId) {
        const payload = {
            freeText: null,
            unitStatusList: ['ACTIVE'],
            ccr: true,
            beforeComplianceYear: true,
            compliancePeriodList: [this.currentData.complianceYear],
            issuanceYear: this.currentData.complianceYear
        };
        return this.http.post(`${environment.apiUrl}${CAR_GET_ACTIVE_CREDITS}/${accountId}`, payload);
    }
    getCreditType(type) {
        return this.http.get(`${environment.apiUrl}${CAR_CREDIT_TYPE_REPLACEMENT_URI}/${type}`);
    }
    checkForConfirmationMessage(totalCreditsToBeCreated, totalCreditsToBeTransferred, totalCreditsToBeCancelled) {
        let confirmationMessage = '';
        let metaDataList = [];
        if (totalCreditsToBeCreated && totalCreditsToBeCreated > 0 &&
            (!totalCreditsToBeCancelled || totalCreditsToBeCancelled === 0) &&
            (!totalCreditsToBeTransferred || totalCreditsToBeTransferred === 0)) {
            confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsToBeCreated;
            metaDataList = [totalCreditsToBeCreated];
        }
        else if (totalCreditsToBeCreated && totalCreditsToBeCreated > 0 &&
            totalCreditsToBeTransferred && totalCreditsToBeTransferred > 0 &&
            (!totalCreditsToBeCancelled || totalCreditsToBeCancelled === 0)) {
            confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsTransferUponCreation;
            metaDataList = [totalCreditsToBeCreated, totalCreditsToBeTransferred];
        }
        else if (totalCreditsToBeCancelled && totalCreditsToBeCancelled > 0 &&
            (!totalCreditsToBeCreated || totalCreditsToBeCreated === 0) &&
            (!totalCreditsToBeTransferred || totalCreditsToBeTransferred === 0)) {
            confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsCancelAfterCreation;
            metaDataList = [totalCreditsToBeCancelled];
        }
        else if (totalCreditsToBeCreated && totalCreditsToBeCreated > 0 &&
            totalCreditsToBeCancelled && totalCreditsToBeCancelled > 0 &&
            (!totalCreditsToBeTransferred || totalCreditsToBeTransferred === 0)) {
            confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsCancelAndDeposit;
            metaDataList = [totalCreditsToBeCreated, totalCreditsToBeCancelled];
        }
        else if ((!totalCreditsToBeCreated || totalCreditsToBeCreated === 0) &&
            (!totalCreditsToBeCancelled || totalCreditsToBeCancelled === 0)) {
            confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageNoCreditsCancelAndDeposit;
            metaDataList = [];
        }
        else if (totalCreditsToBeCreated && totalCreditsToBeCreated > 0 &&
            totalCreditsToBeCancelled && totalCreditsToBeCancelled > 0 &&
            totalCreditsToBeTransferred && totalCreditsToBeTransferred > 0) {
            confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsDepositCancelAndTransfered;
            metaDataList = [totalCreditsToBeCreated, totalCreditsToBeCancelled, totalCreditsToBeTransferred];
        }
        const result = {
            confirmationMessage,
            metaDataList
        };
        return result;
    }
    storeCurrentData() {
        const data = JSON.stringify(Object.assign({}, this.currentData));
        sessionStorage.setItem(CAR_DATA_KEY, data);
        const currentStep = JSON.stringify(this.currentStep);
        sessionStorage.setItem(CAR_CURRENT_STEP_KEY, currentStep);
    }
    restoreCurrentData(skipRemove = false) {
        const data = sessionStorage.getItem(CAR_DATA_KEY);
        if (data) {
            !skipRemove && sessionStorage.removeItem(CAR_DATA_KEY);
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }
    restoreLastStep() {
        const currentStep = sessionStorage.getItem(CAR_CURRENT_STEP_KEY);
        if (currentStep) {
            sessionStorage.removeItem(CAR_CURRENT_STEP_KEY);
            return JSON.parse(currentStep);
        }
        else {
            return null;
        }
    }
    getCreditBlocks(pagination, complianceYear, accountId = this.currentData.accountId) {
        const unitStatusList = ['ACTIVE'];
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        const filterState = {
            freeText: null, unitStatusList,
            ccr: true,
            beforeComplianceYear: true,
            compliancePeriodList: [this.currentData.complianceYear],
            issuanceYear: this.currentData.complianceYear,
            facilityNameList: [accountId],
        };
        if (complianceYear) {
            filterState.compliancePeriodList = [complianceYear];
            filterState.issuanceYear = complianceYear;
        }
        else {
            filterState.compliancePeriodList = [this.currentData.complianceYear];
            filterState.issuanceYear = this.currentData.complianceYear;
        }
        return this.http.post(`${environment.apiUrl}${CAR_CREDIT_BLOCKS}`, filterState, { params });
    }
    showErrorMessage(message, metaDataList = []) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                { message },
            ],
            message,
            metaDataList,
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    getApprovedCarbonIntensities(complianceYear, fuelId, facilityName, reg = false, defaultCICode = null) {
        let uri;
        if (facilityName) {
            facilityName = facilityName.replace(/[^\w\s]/gi, '');
            uri = `${CCR_APPROVED_CARBONINTENSITIES}${complianceYear}/${fuelId}/${facilityName}`;
            if (defaultCICode) {
                uri += `/${defaultCICode}`;
            }
            if (reg) {
                uri += '?reg=true';
            }
        }
        else {
            uri = `${CCR_APPROVED_CARBONINTENSITIES}${complianceYear}/${fuelId}/12`;
        }
        return this.http.get(`${environment.apiUrl}${uri}`);
    }
    checkPermission() {
        if ((this.store.user.hasPermission('SAVE_CREDIT_CREATION_REPORT') ||
            this.store.user.hasPermission('SUBMIT_CREDIT_CREATION_REPORT'))) {
            return true;
        }
        return false;
    }
}
CreditAdjustmentReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditAdjustmentReportService_Factory() { return new CreditAdjustmentReportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HelperService), i0.ɵɵinject(i4.ModalService), i0.ɵɵinject(i5.StoreService)); }, token: CreditAdjustmentReportService, providedIn: "root" });
