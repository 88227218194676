import { TemplateRef } from '@angular/core';
import { commonActionsLabelTranslationPrefix } from '@core/models/filter-types.model';
import { IRawForm, IRawFormBase } from '@core/models/raw-form.types';
import { getDictSelect, getInput, getRadio, getButton, } from '@core/utilities/raw-form.utils';
import { CI_PATHWAY_REPORT_COMPLIANCE_PERIOD_FLD } from '@module/ci-pathway-report/ci-pathway-report.const';
import {
  CCR_CREATE_POPUP,
  CCR_ORGANIZATION_NAME_FLD,
  CCR_PREFIX,
  CCRApplyPrefix,
} from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { ActionOptionType, ITableFilterActionModel } from '@shared/models/table-filter.models';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export const MATERIAL_BALANCE_STEP_COMPONENT_MAP = {
  'STEP-1': 'MaterialBalanceFeedstockInfoComponent',
  'STEP-2': 'MaterialBalanceFuelQuantityInfoComponent',
  'STEP-3': 'MaterialBalanceImportFuelInfoComponent',
  'STEP-4': 'MaterialBalanceContactPersonComponent',
  'STEP-5': 'MaterialBalanceSummaryComponent',
};

export const MATERIAL_BALANCE_REPORT_PREFIX = 'MATERIAL_BALANCE_REPORT';
export const MATERIAL_BALANCE_REPORT_STEP_PREFIX = '.reportStep';

export const MB_FS_PARAGRAPH = 'feedstockParagraph';
export const MB_FQI_PREFIX = 'fuelQuantityInformation';

export const MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX = 'MATERIAL_BALANCE_REPORT.fuelInfoImport';
export const MB_SUMMARY_TRANSLATION_PREFIX = 'MATERIAL_BALANCE_REPORT.summary';
export const FUEL_INFO_ITEM_2_CODE = 'QUANTITY_LOW_CI_CCR_ITEM2';
export const FUEL_INFO_ITEM_1_CODE = 'TOTAL_QUANTITY_LOW_CI_ITEM1';

export const MB_REPORT_LIST_TABLE_FILTER: ITableFilterActionModel = {
  filterListsModel: [],
  searchBox: true,
  searchButtons: false,
  dateRange: true,
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'MATERIAL_BALANCE_REPORT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'MATERIAL_BALANCE_REPORT',
        },
        {
          id: 'exportReconciliationExcel',
          label: commonActionsLabelTranslationPrefix + 'exportReconciliationExcel',
          type: ActionOptionType.EXPORT,
          uri: 'VIEW_RECONCILIATION',
        },
      ],
    },
  ],
  options: {
    dateRange: {
      placeholder: 'COMPLIANCE_REPORT.table.submittedOn',
    },
  },
};

export const MATERIAL_BALANCE_REPORT_LIST_TABLE_CONFIG = {
  tableHeaderType: 'MATERIAL_BALANCE_REPORT',
  tableFilter: MB_REPORT_LIST_TABLE_FILTER,
  filtersFromConfig: true,
  hasViewTemple: true,
  hasActionTemple: true,
  adminFilters: ['facilityNames'],
};

export const getOrganizationName = (hideExpression, onChange: (event) => void): IRawForm => {
  return getDictSelect({
    id: CCR_ORGANIZATION_NAME_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.ACCOUNT_NAMES,
    label: CCRApplyPrefix(CCR_ORGANIZATION_NAME_FLD, CCR_CREATE_POPUP),
    disabled: false,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
    skipInitialBlur: true,
    hideExpression,
    onChange,
  });
};

export const getMBCompliancePeriod = (source, onChange: (event) => void): IRawForm => {
  return getDictSelect({
      id: CI_PATHWAY_REPORT_COMPLIANCE_PERIOD_FLD,
      className: 'col-6',
      source,
      label: `MATERIAL_BALANCE_REPORT.reportDetails.compliancePeriod`,
      translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
      required: true,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      skipInitialBlur: true,
      onChange,
  });
};

export const getMBFuelType = (source: any, onChange: (event) => void): IRawForm => {
  return getDictSelect({
      id: 'fuelId',
      className: 'col-6',
      source,
      label: `MATERIAL_BALANCE_REPORT.reportDetails.fuelType`,
      translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
      required: true,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      skipInitialBlur: true,
      onChange,
  });
};

export const getMBApprovedCiId = (source, onChange: (event) => void): IRawForm => {
  return getDictSelect({
      id: 'carbonIntensityId',
      className: 'col-6',
      bindValue: 'code',
      source,
      label: `MATERIAL_BALANCE_REPORT.reportDetails.carbonIntensityId`,
      translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
      required: true,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      skipInitialBlur: true,
      onChange,
  });
};

export const getMBApprovedCiIdFeedstock = (): IRawForm => {
  return getInput({
    id: 'approvedCIId',
    className: 'col-6',
    key: 'approvedCIId',
    label: `MATERIAL_BALANCE_REPORT.reportDetails.carbonIntensityId`,
    required: false,
    disabled: true,
  });
};

export const getMBTypeOfFeedstock = (): IRawForm => {
  return getInput({
    id: 'feedstock',
    className: 'col-4',
    key: 'feedstock',
    label: `MATERIAL_BALANCE_REPORT.feedstockInformation.feedstockType`,
    disabledExpression: `model.carbonIntensityId !== 'DEFAULT'`,
    required: true,
  });
};

export const getMBFeedstockFuelType = (): IRawForm => {
  return getInput({
    id: 'feedstockFuelType',
    className: 'col-4',
    key: 'feedstockFuelType',
    label: `MATERIAL_BALANCE_REPORT.feedstockInformation.feedstockFuelType`,
    required: true,
    disabled: true,
  });
};

export const getMBFeedstockApprovedCi = (): IRawForm => {
  return getInput({
    id: 'ciValue',
    className: 'col-4',
    key: 'ciValue',
    label: `MATERIAL_BALANCE_REPORT.feedstockInformation.feedstockCiValue`,
    required: true,
    disabled: true,
  });
};

export const getFeedstockParagraph = (onChange?: () => void): IRawForm => {
  return getRadio({
    id: 'feedstockParagraphRegulation',
    className: 'col-12',
    label: 'MATERIAL_BALANCE_REPORT.feedstockInformation.feedstockParagraph',
    options: [{key: 'Paragraph 46(1)(b)', value: `MATERIAL_BALANCE_REPORT.feedstockInformation.feedstockParagraphOption1`},
      {key: 'Paragraph 46(1)(c)', value: `MATERIAL_BALANCE_REPORT.feedstockInformation.feedstockParagraphOption2`}],
    onChange,
    dummyInputForLabel: true,
    required: true,
  });
};

export const getMBFeedstockEnergyDensity = (source: BehaviorSubject<any>, onChange?: () => void): IRawForm => {
    return getDictSelect({
      id: `energyDensityLowCIFuel`,
      key: 'energyDensityLowCIFuel',
      className: 'col-4',
      source,
      label:  'MATERIAL_BALANCE_REPORT.feedstockInformation.energyDensityLowCIFuel',
      onChange,
      bindValue: 'densityValue',
      dataType: 'string',
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      required: true,
      translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
    });
};

export const MBR_FUEL_QUANTITY_TABLE = (templateRef: Array<TemplateRef<any>>): ITableViewConfig => {
  return {
    translationPrefix: `${MATERIAL_BALANCE_REPORT_PREFIX}.${MB_FQI_PREFIX}`,
    paginator: false,
    hasTotalRecord: true,
    columns: [
      {
        field: 'mbrLineItemCode',
        header: 'lineItemDescription',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: false,
        translate: true,
        ignoreTruncate: true,
        templateRef: templateRef[4],
      },
      {
        field: 'quarter1',
        header: 'quarter1',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        translate: true,
        validators: ['onlyNumbers', 'positiveOrZeroNumber'],
        ignoreTruncate: true,
        required: true,
        templateRef: templateRef[0],
      },
      {
        field: 'quarter2',
        header: 'quarter2',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        translate: true,
        ignoreTruncate: true,
        required: true,
        validators: ['onlyNumbers', 'positiveOrZeroNumber'],
        templateRef: templateRef[1],
      },
      {
        field: 'quarter3',
        header: 'quarter3',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        translate: true,
        ignoreTruncate: true,
        required: true,
        validators: ['onlyNumbers', 'positiveOrZeroNumber'],
        templateRef: templateRef[2],
      },
      {
        field: 'quarter4',
        header: 'quarter4',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        translate: true,
        ignoreTruncate: true,
        required: true,
        validators: ['onlyNumbers', 'positiveOrZeroNumber'],
        templateRef: templateRef[3],
      },
      {
        field: 'totalQuantity',
        header: 'totalQuantity',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        inlineTooltip: true,
        required: true,
        validators: ['onlyNumbers', 'positiveOrZeroNumber'],
      },
    ],
  };
};

export const getOrgSearchFields = (fieldId, searchCallback: () => void, clearCallback: ()=> void, customTemplateOptions ={} ): IRawForm[] => {
  return [
    getInput({
      id: fieldId,
      className: 'col-6',
      label: MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX + `.${fieldId}`,
      placeholder: MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX + `.${fieldId}`,
      translatePrefix: MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX,
      required: true,
      customTemplateOptions: {
        type: 'number',
        hideArrows: true,
      }
    }),
    getButton({
      id: 'search',
      label: MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX + '.search',
      className: 'col-0 mx-3',
      customTemplateOptions: {
        onClick: searchCallback
      }
    }),
    getButton({
      id: 'clear',
      label: MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX + '.clear',
      className: 'col-0',
      customTemplateOptions: {
        onClick: clearCallback
      }
    }),
  ]
};

export const getMBUniqueIdentifier = (): IRawForm => {
  return getInput({
    id: 'fsUniqueIdentifier',
    className: 'col-6',
    key: 'fsUniqueIdentifier',
    label: `${MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX}.fsUniqueIdentifier`,
    required: true,
  });
};

export const getCITotalQuantity = (): IRawForm => {
  return getInput({
    id: 'fsCITotalQuantity',
    className: 'col-6',
    key: 'fsCITotalQuantity',
    label: `${MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX}.fsCITotalQuantity`,
    required: true,
    dataType: 'number',
    icon: {
      text: `${MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX}.fsCITotalQuantityTooltip`,
    },
    validators: ['positiveOrZeroNumber'],
  });
};

export const CONTACT_PERSON_FUEL_INFO_FILEDS: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'title',
        label: `${MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX}.title`,
        disabled: false,
        required: true,
        id: 'title',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'name',
        label: `${MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX}.name`,
        disabled: false,
        required: true,
        id: 'name',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'businessTelephone',
        label: `${MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX}.telephoneNum`,
        disabled: false,
        required: true,
        id: 'telephoneNum',
        placeholder: 'COMMON.messageSection.phonePlaceholder',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'email',
        label: `${MB_IMPORT_FUEL_INFO_TRANSLATION_PREFIX}.email`,
        disabled: false,
        required: true,
        id: 'email',
      },
    ],
  },
];
