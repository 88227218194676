<p-dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1200px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  [header] = "'CREDIT_CREATION_REPORT.reportStep.' + getTranslatedValues('Heading') | translate">

  <form [formGroup]="formGroup">
    <formly-form [form]="formGroup" [model]="model" [fields]="fields"></formly-form>
  </form>

  <div class="row mt-3 pb-3">
    <div class="col-12 align">
      <p>{{'CREDIT_CREATION_REPORT.reportStep.' + getTranslatedValues('coOrdinates') | translate}} </p>&nbsp;
      <!-- <fa-icon *ngIf="tooltip" pTooltip="{{tooltip | translate}}"
        tooltipStyleClass="tooltip-min-width" [escape]="false" tooltipPosition="top" [icon]="['fas', 'info-circle']">
      </fa-icon> -->
    </div>
    <div class="col-12" *ngIf="model.civicAddress">
      <span>{{model.civicAddress.latitude}} </span><span *ngIf="model.civicAddress.longitude">,
        {{model.civicAddress.longitude}}</span>
    </div>
  </div>

  <div class="row mt-3 border-bottom pb-3">
    <div class="col-6">
      <app-address-view
              [legend]="'CREDIT_CREATION_REPORT.reportStep.postalAddress' | translate"
              [address]="model?.postalAddress" >
      </app-address-view>
    </div>
    <div class="col-6">
      <app-address-view
              [legend]="'CREDIT_CREATION_REPORT.reportStep.civicAddress' | translate"
              [address]="model?.civicAddress">
      </app-address-view>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-2">
    <button type="button" disableOnUpload class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
  </div>

</p-dialog>

