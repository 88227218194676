<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1200px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  (onShow)="scrollToTop()"
  [header] = "'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.TitleAddDetails' | translate">

  <h6 class="font-weight-bold mx-4"> {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.internalTitle' | translate}}</h6>

  <div class="container">
        <form [formGroup]="sectionFormLeft">
            <formly-form [form]="sectionFormLeft" [model]="model" [fields]="section1FieldList"></formly-form>
        </form>
    <p>{{ 'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.facilityDetailsForCI' | translate }}: <a class="link" href="#" (click)="viewFacilityDetails($event)">{{ 'COMMON.actionsLabel.VIEW' | translate }}</a></p>

    <div style="margin-top: 18px;">
        <form [formGroup]="sectionFormLeft">
            <formly-form [form]="sectionFormLeft" [model]="model" [fields]="section2FieldList"></formly-form>
        </form>
    </div>

    <h6 class="font-weight-light"> <b> {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.CIInfoTitle' | translate}} </b></h6>

    <div class="mt-4 mb-4">
        <p-table [ngClass]="'eccc'" [value]="dummy">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_5' | translate }}</th>
                    <th>
                        {{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_6' | translate }}
                        <fa-icon
                            pTooltip="{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_6_b' | translate }}"
                            tooltipPosition="top"
                            [icon]="['fas', 'info-circle']">
                        </fa-icon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td [innerHTML]="'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_7' | translate"></td>
                     <td>
                        <ng-select
                        wcag-label
                        [items]="feedStockList"
                        bindLabel="name"
                        bindValue="id"
                        [(ngModel)]="model.ciApplicationDto.lifecycleFeedstock.id"
                        [readonly]="true">

                          <ng-template ng-label-tmp let-item="item">
                            <span [innerHTML]="item?.name"></span>
                          </ng-template>

                          <ng-template ng-option-tmp let-item="item">
                            <span [innerHTML]="item?.name"></span>
                          </ng-template>

                        </ng-select>
                    </td> 
                </tr>
                <tr>
                    <td [innerHTML]="'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_8' | translate"><!--{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_8' | translate }}--></td>
                    <td>
                        <ng-select
                        wcag-label
                        [items]="prodAndTrans"
                        bindLabel="name"
                        bindValue="id"
                        [(ngModel)]="model.ciApplicationDto.stageProductionValue"
                        [readonly]="true"
                        >
                        </ng-select>
                    </td>
                </tr>
                <tr>
                    <td [innerHTML]="'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_9' | translate"><!--{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_9' | translate }}--></td>
                    <td>
                        <ng-select
                        wcag-label
                        [items]="compLiq"
                        bindLabel="name"
                        bindValue="id"
                        [(ngModel)]="model.ciApplicationDto.stageCompressionValue"
                        [readonly]="true"
                        >
                        </ng-select>
                    </td>
                </tr>
                <tr>
                    <td [innerHTML]="'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_10' | translate"><!--{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_10' | translate }}--></td>
                    <td>
                        <ng-select
                        wcag-label
                        [items]="electricity"
                        bindLabel="name"
                        bindValue="id"
                        [(ngModel)]="model.ciApplicationDto.stageElectricityValue"
                        [readonly]="true"
                        >
                        </ng-select>
                    </td>
                </tr>
                <tr>
                    <td [innerHTML]="'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_11' | translate"><!--{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_11' | translate }}--></td>
                    <td>
                        <ng-select
                        wcag-label
                        [items]="additionalTransPort"
                        bindLabel="name"
                        bindValue="id"
                        [(ngModel)]="model.ciApplicationDto.stageAdditionalTransportationValue"
                        [readonly]="true"
                        >
                        </ng-select>
                    </td>
                </tr>
                <tr>
                    <td [innerHTML]="'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_12' | translate"><!--{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_12' | translate }}--></td>
                    <td>
                        <ng-select
                        wcag-label
                        [items]="combustionUse"
                        bindLabel="name"
                        bindValue="id"
                        [(ngModel)]="model.ciApplicationDto.stageCombustionValue"
                        [readonly]="true"
                        >
                        </ng-select>
                    </td>
                </tr>
                <tr>
                    <td ><b [innerHTML]="'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_13' | translate"></b></td>
                    <td>
                        <label class="wcag-visuallyhidden" for="total">total</label>
                        <input type="text" id="total" class="form-control" [(ngModel)]="model.ciApplicationDto.stageCiTotal" disabled>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="row">
        <div class="col-6" #leftCol>
            <h6 style="margin-bottom: 4.6rem;"> <b> {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.DetailsTitleForApprovedCI' | translate}} </b></h6>
            <form [formGroup]="sectionFormLeft">
                <formly-form [form]="sectionFormLeft" [model]="model" [fields]="section3FieldList"></formly-form>
            </form>
            <div class = "table-area">
            <app-table-view style="display: inline;"
            [data]="tableData"
            [config]="energyTableConfig">
            </app-table-view>
            </div>
          
            <div class="container mt-4 mb-4" style="height: 125px;">
                <div class="col-5"></div>
                <div class="col-12" style="float: right;">
                    <div class="row mb-1">
                        <div class="col-6 text-right">
                            {{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_20' | translate }}
                            <fa-icon
                            pTooltip="{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_36' | translate }}"
                            tooltipPosition="top" [escape]="false"
                            [icon]="['fas', 'info-circle']">
                            </fa-icon>
                        </div>
                        <div class="col-6">
                            <label class="wcag-visuallyhidden" for="totalLowCiSources">totalLowCiSources</label>
                            <input type="number" id="totalLowCiSources" class="form-control" [(ngModel)]="model.ciApplicationDto.totalLowCiSources" disabled>
                        </div> 
                    </div>
                    <div class="row mb-1">
                        <div class="col-6 text-right">
                            {{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_21' | translate }}
                            <fa-icon
                            pTooltip="{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_37' | translate }}"
                            tooltipPosition="top"
                            [icon]="['fas', 'info-circle']">
                            </fa-icon>
                        </div>
                        <div class="col-6">
                            <label class="wcag-visuallyhidden" for="totalEnergy">totalEnergy</label>
                            <input type="number" id="totalEnergy" class="form-control" [(ngModel)]="model.ciApplicationDto.totalEnergy" disabled>
                        </div>    
                    </div>
                    <div class="row mb-1">
                        <div class="col-6 text-right">
                            {{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_22' | translate }}
                            <fa-icon
                            pTooltip="{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_38' | translate }}"
                            tooltipPosition="top"
                            [icon]="['fas', 'info-circle']">
                            </fa-icon>
                        </div>
                        <div class="col-6">
                            <label class="wcag-visuallyhidden" for="totalLowCiPercentage">totalLowCiPercentage</label>
                            <input type="number" id="totalLowCiPercentage" class="form-control" [(ngModel)]="model.ciApplicationDto.totalLowCiPercentage" disabled>
                        </div> 
                    </div>
                </div>
            </div>
            <p  *ngIf="showTranport" [innerHTML]="'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.SubTitleForTransport' | translate"></p>

            <div class="mt-4 mb-4" *ngIf="showTranport">
                <div class="mb-4" >
                    <p-table [ngClass]="'eccc'" [value]="dummy">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_24' | translate }}</th>
                                <th>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_25' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_26' | translate }}</td>
                                <td>
                                    <label class="wcag-visuallyhidden" for="distanceSourceToProduction">distanceSourceToProduction</label>
                                    <input type="number" id="distanceSourceToProductionLeft" class="form-control" 
                                    [(ngModel)]="model.ciApplicationDto.distanceSourceToProduction"  [disabled]="true"
                                     [class.is-invalid]="(0 > model.distanceSourceToProduction)" >
                                    <div class="invalid"
                                        *ngIf="0 > model.ciApplicationDto.distanceSourceToProduction">
                                        <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_27' | translate }}</td>
                                <td>
                                    <label class="wcag-visuallyhidden" for="distanceProductionToDistribution">distanceProductionToDistribution</label>
                                    <input type="number" id="distanceProductionToDistribution" class="form-control" [(ngModel)]="model.ciApplicationDto.distanceProductionToDistribution"  [disabled]="true"
                                     [class.is-invalid]="(0 > model.distanceProductionToDistribution)" >
                                    <div class="invalid"
                                        *ngIf="0 > model.ciApplicationDto.distanceProductionToDistribution">
                                        <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><b>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_28' | translate }}</b></td>
                                <td>
                                    <label class="wcag-visuallyhidden" for="totalDistance">totalDistance</label>
                                    <input type="number" id="totalDistance" class="form-control" [(ngModel)]="model.ciApplicationDto.totalDistance" disabled>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        <div class="col-6 border-left" #rightCol>
            <h6 style="margin-bottom: 6%;"> <b> {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.DetailsTitleForCIPathwayReport' | translate}} </b> </h6>
            <form [formGroup]="sectionFormRight">
                <formly-form [form]="sectionFormRight" [model]="model" [fields]="section4FieldList"></formly-form>
            </form>
            <!-- <div class = "table-area">
                <app-table-view style="display: inline;"
                [data]="tableData"
                [config]="energyTableConfig"
                [isEditable]="!fieldsEditable()">
                </app-table-view>
                </div> -->
                <div style="width: 90%; min-height: 200px;">
                <p-table [value]="dummy" [ngClass]="'eccc'" >
                    <ng-template pTemplate="header">
                      <tr>
                        <th [pSortableColumn]="'name'">{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_16' | translate}}
                         <p-sortIcon [field]="'name'"></p-sortIcon>  </th>
                        <th >{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_17' | translate}}
                        <p-sortIcon [field]="'name'"></p-sortIcon>  </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                      <tr *ngFor="let item of tableDataRight; let i= index">
                        <td>{{ item.name }}</td> 
                        <td><input type="number" [(ngModel)]="item.quantity" [disabled]="!fieldsEditable() || modelStatus()" (ngModelChange)="onInputChange(i, item.name, item.id, $event)"
                             class="form-control"  name="quantity{{i}}" #quantity="ngModel" required >
                             <div *ngIf="quantity.invalid && (quantity.dirty || quantity.touched)" class="text-danger">
                                <div *ngIf="quantity.errors.required">
                                  {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.mandatoryQuantity' | translate }}
                                </div>
                              </div></td> 
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
          
            <div class="container mt-4 mb-4" style="height: 125px;">
                <div class="col-5"></div>
                <div class="col-12" style="float: right;">
                    <div class="row mb-1">
                        <div class="col-6 text-right">
                            {{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_20' | translate }}
                            <fa-icon
                            pTooltip="{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_36' | translate }}"
                            tooltipPosition="top" [escape]="false"
                            [icon]="['fas', 'info-circle']">
                            </fa-icon>
                        </div>
                        <div class="col-6">
                            <label class="wcag-visuallyhidden" for="totalLowCiSources">totalLowCiSources</label>
                            <input type="number" id="totalLowCiSources" class="form-control" [(ngModel)]="model.ciApplicationDto.totalLowCiSources" disabled>
                        </div> 
                    </div>
                    <div class="row mb-1">
                        <div class="col-6 text-right">
                            {{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_21' | translate }}
                            <fa-icon
                            pTooltip="{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_37' | translate }}"
                            tooltipPosition="top"
                            [icon]="['fas', 'info-circle']">
                            </fa-icon>
                        </div>
                        <div class="col-6">
                            <label class="wcag-visuallyhidden" for="totalEnergy">totalEnergy</label>
                            <input type="number" id="totalEnergy" class="form-control" [(ngModel)]="model.ciApplicationDto.totalEnergy" disabled>
                        </div>    
                    </div>
                    <div class="row mb-1">
                        <div class="col-6 text-right">
                            {{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_22' | translate }}
                            <fa-icon
                            pTooltip="{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_38' | translate }}"
                            tooltipPosition="top"
                            [icon]="['fas', 'info-circle']">
                            </fa-icon>
                        </div>
                        <div class="col-6">
                            <label class="wcag-visuallyhidden" for="totalLowCiPercentage">totalLowCiPercentage</label>
                            <input type="number" id="totalLowCiPercentage" class="form-control" [(ngModel)]="model.ciApplicationDto.totalLowCiPercentage" disabled>
                        </div> 
                    </div>
                </div>
            </div>
 
            <p *ngIf="showTranport" [innerHTML]="'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.approvedSubTitleForTransport' | translate"></p>
            <div class="mt-4 mb-4" *ngIf="showTranport">
                <div class="mb-4" >
                    <p-table [ngClass]="'eccc'" [value]="dummy">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_24' | translate }}</th>
                                <th>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_25' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_26' | translate }}</td>
                                <td>
                                    <label class="wcag-visuallyhidden" for="distanceSourceToProductionRight">distanceSourceToProduction</label>
                                    <input type="number" id="distanceSourceToProductionRight" class="form-control" [(ngModel)]="model.distanceSourceToProduction" 
                                    [disabled]="!fieldsEditable() || modelStatus()" (change)="calculateTotalDistance($event)" [class.is-invalid]="((0 > model.distanceSourceToProduction) || (model.distanceSourceToProduction === null))"
                                     (keydown)="onKeyDown($event)">
                                    <div class="invalid"
                                         *ngIf="((0 > model.distanceSourceToProduction) || (model.distanceSourceToProduction === null))">
                                        <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_27' | translate }}</td>
                                <td>
                                    <label class="wcag-visuallyhidden" for="distanceProductionToDistribution">distanceProductionToDistribution</label>
                                    <input type="number" id="distanceProductionToDistribution" class="form-control" [(ngModel)]="model.distanceProductionToDistribution"  [disabled]="!fieldsEditable() || modelStatus()" 
                                    (change)="calculateTotalDistance($event)" [class.is-invalid]="((0 > model.distanceProductionToDistribution) || (model.distanceProductionToDistribution === null) )"
                                     (keydown)="onKeyDown($event)">
                                    <div class="invalid"
                                        *ngIf="((0 > model.distanceProductionToDistribution) || (model.distanceProductionToDistribution === null))">
                                        <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><b>{{'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_28' | translate }}</b></td>
                                <td>
                                    <label class="wcag-visuallyhidden" for="totalDistance">totalDistance</label>
                                    <input type="number" id="totalDistance" class="form-control" [(ngModel)]="model.totalDistance" disabled>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>   
        </div>
    </div>

    
    <form [formGroup]="sectionFormRight">
    <formly-form [form]="sectionFormRight" [model]="model" [fields]="section5FieldList"></formly-form>
    </form>

</div>


              
        


  <div class="d-flex justify-content-end mt-2">
    <button type="button" disableOnUpload class="btn btn-secondary mr-2" 
    (click)="close()">{{'CARBON_INTENSITY_PATHWAY_REPORT.actions.close' | translate}}</button>
    <button type="button"
            disableOnUpload
            class="btn btn-primary"
            (click)="save()"
            [disabled] = "reportstatus()">
      {{'CARBON_INTENSITY_PATHWAY_REPORT.actions.save' | translate}}
    </button>
  </div>
  </p-dialog>
