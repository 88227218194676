import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { CCR_CURRENT_STEP_KEY, CCR_DATA_KEY, CCR_STEP_COMPONENTS, CCR_TYPE, } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LOOKUP_ENERGY_EFFICIENCY_FOR_ANNUAL_STEP } from '@core/services/lookup.service';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { sortBy } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/services/helper.service";
import * as i4 from "../../../shared/services/modal.service";
import * as i5 from "../../../core/store/store.service";
export const NEW_CREDIT_CREATION_REPORT_URI = '/cfr-service/creditCreationReport/getNewCreditCreationReport';
export const CCR_FACILITIES_URI = '/cfr-service/creditCreationReport/getFacilities';
export const CCR_CARBON_INTENSITY_IDS_LOOKUP_URI = '/cfr-service/application/lookupCarbonIntensityIDs';
export const CCR_FUEL_ENERGY_DENSITY_URI = '/account-service/energyDensity/getForFuel';
export const CCR_CREDIT_TYPE_REPLACEMENT_URI = '/cfr-service/creditCreationReport/getCreditTypeForFuelType';
export const CCR_FEEDSTOCK_TYPE_URI = '/account-service/application/getForCCRLookup';
export const CCR_APPLICATION_DETAILS_URI = CCR_FEEDSTOCK_TYPE_URI;
export const CCR_REFERENCE_CARBON_INTENSITY = '/cfr-service/creditCreationReport/getReferencedCarbonIntensity';
export const CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL = '/cfr-service/creditCreationReport/getReferencedCarbonIntensityForFuel';
export const CCR_STEP_DETAILS = '/cfr-service/creditCreationReport/pageSearchCreditCreationReportDetail';
export const CCR_GET_REFERENCE_CI_ELECTRICITY = '/cfr-service/creditCreationReport/getReferencedCarbonIntensityForElectricity/';
export const CCR_SAVE_REPORT = '/cfr-service/creditCreationReport/saveCreditCreationReport';
export const CCR_SUBMIT_REPORT = '/cfr-service/creditCreationReport/submitCreditCreationReport';
export const CCR_RE_SUBMIT_REPORT = '/cfr-service/creditCreationReport/reSubmitCreditCreationReport';
export const CCR_SAVE_AND_PROCEED = '/cfr-service/creditCreationReport/saveAndProceedCreditCreationReport';
export const CCR_APPROVED_CARBONINTENSITIES = '/cfr-service/creditCreationReport/getApprovedCarbonIntensity/';
export const CCR_FUEL_REPLACEMENT_TYPE = '/cfr-service/creditCreationReport/getReplacementTypesForFuelType/';
export const CCR_UPDATE_STATUS = '/cfr-service/creditCreationReport/updateStatus';
export const CCR_DISCARDS = '/cfr-service/creditCreationReport/discard';
export const CCR_DOWNLOAD_REPORT = '/cfr-service/creditCreationReport/download/report/';
export const GET_FACILITY_DETAILS = '/account-service/cfrAccount/account/getFacilityDetail/';
export const GET_FOREIGN_SUPPLIER_DETAILS = '/account-service/account/getFacility/';
export const GET_REPORT_BY_ID = '/cfr-service/creditCreationReport/getReportById/';
export const GET_REPORT_BY_ID_AND_STEP = '/cfr-service/creditCreationReport/getReportByIdAndStep/';
export const GET_REPORT_BY_ID_TO_RESUBMIT = '/cfr-service/creditCreationReport/getReportToResubmit/';
export const REPORT_LIST_URI = '/cfr-service/creditCreationReport/pageSearchByFilter';
export const GET_FUELING_STATION = '/account-service/getFuelStation/';
export const CCR_PROJECT_LIST_FOR_ACCOUNT = '/cfr-service/creditCreationReport/getProjectListForAccount';
export const CCR_PROJECT_DETAILS = '/account-service/application/get';
export const GET_CI_OF_REGISTRATION_REPORT = '/account-service/agreementTuc/findCIsForActiveAgreements/';
export const LOAD_AGREEMENT_BY_ID = '/account-service/agreementTuc/load/';
export const CREDIT_TRANSFER_DOWNLOAD_PDF = '/obps-service/creditAction/download/transfer';
export const COMP_REP_DOC_UPLOAD_ID = '/cfr-service/complianceReport/getDocumentIdForReference';
export const CCR_PROPOSE = '/cfr-service/creditCreationReport/proposeCreditCreationReport';
export const CCR_GET_CI_DATA = '/cfr-service/creditCreationReport/getFinalCi';
export class CreditCreationReportsService extends BaseStepperService {
    constructor(http, router, helperService, modalService, store) {
        super();
        this.http = http;
        this.router = router;
        this.helperService = helperService;
        this.modalService = modalService;
        this.store = store;
        // public report: ICreditCreationReport;
        this.stepRoutineMap = {};
        this._refreshData$ = new Subject();
        this.refreshData$ = this._refreshData$.asObservable();
        this._callMethod$ = new BehaviorSubject(null);
        this.callMethod$ = this._callMethod$.asObservable();
        this.currentStepInvalid = false;
    }
    callMethod(method, args = null) {
        this._callMethod$.next({ method, args });
    }
    getNewCreditCreationReport(payload) {
        return this.http.post(`${environment.apiUrl}${NEW_CREDIT_CREATION_REPORT_URI}`, payload);
    }
    getCCRStepDetails(reportId, stepCode) {
        const payload = { reportId, stepCode };
        return this.http.post(`${environment.apiUrl}${CCR_STEP_DETAILS}`, payload);
    }
    getFacilities(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_FACILITIES_URI}`, payload);
    }
    getAlphanumericIDofCI(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_CARBON_INTENSITY_IDS_LOOKUP_URI}`, payload);
    }
    getFeedstockRegion(id) {
        return this.http.get(`${environment.apiUrl}${CCR_FEEDSTOCK_TYPE_URI}/${id}`);
    }
    getApplicationDetails(id) {
        return this.http.get(`${environment.apiUrl}${CCR_APPLICATION_DETAILS_URI}/${id}`);
    }
    getFuelEnergyDensity(fuelId) {
        return this.http.get(`${environment.apiUrl}${CCR_FUEL_ENERGY_DENSITY_URI}/${fuelId}`);
    }
    getCreditTypeReplacement(type) {
        return this.http.get(`${environment.apiUrl}${CCR_CREDIT_TYPE_REPLACEMENT_URI}/${type}`);
    }
    getReferenceCarbonIntensity(year, fuelType) {
        return this.http.get(`${environment.apiUrl}${CCR_REFERENCE_CARBON_INTENSITY}/${year}/${fuelType.toUpperCase()}`)
            .pipe(map((response) => Number(response.value)));
    }
    getCFRReferenceCarbonIntensity(year, fuelId) {
        return this.http.get(`${environment.apiUrl}${CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL}/${year}/${fuelId}`);
    }
    storeCurrentData() {
        const data = JSON.stringify(Object.assign({}, this.currentData));
        sessionStorage.setItem(CCR_DATA_KEY, data);
        const currentStep = JSON.stringify(this.currentStep);
        sessionStorage.setItem(CCR_CURRENT_STEP_KEY, currentStep);
    }
    restoreCurrentData(skipRemove = false) {
        const data = sessionStorage.getItem(CCR_DATA_KEY);
        if (data) {
            !skipRemove && sessionStorage.removeItem(CCR_DATA_KEY);
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }
    restoreLastStep() {
        const currentStep = sessionStorage.getItem(CCR_CURRENT_STEP_KEY);
        if (currentStep) {
            sessionStorage.removeItem(CCR_CURRENT_STEP_KEY);
            return JSON.parse(currentStep);
        }
        else {
            return null;
        }
    }
    saveReport(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_SAVE_REPORT}`, Object.assign({}, payload, { action: 'SAVE' }));
    }
    saveAndProceed(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_SAVE_AND_PROCEED}`, Object.assign({}, payload, { action: 'SAVE' }));
    }
    getApprovedCarbonIntensities(complianceYear, fuelId, facilityName, reg = false, defaultCICode = null) {
        facilityName = facilityName.replace(/[^\w\s]/gi, '');
        let uri = `${CCR_APPROVED_CARBONINTENSITIES}${complianceYear}/${fuelId}/${facilityName}`;
        if (defaultCICode) {
            uri += `/${defaultCICode}`;
        }
        if (reg) {
            uri += '?reg=true';
        }
        return this.http.get(`${environment.apiUrl}${uri}`);
    }
    getFuelReplacementType(fuelType) {
        return this.http.get(`${environment.apiUrl}${CCR_FUEL_REPLACEMENT_TYPE}${fuelType}`);
    }
    getFacilityDetails(facilityId, skipValidation = false) {
        let params = new HttpParams();
        params = params.append('skipValidation', `${skipValidation}`);
        return this.http.get(`${environment.apiUrl}${GET_FACILITY_DETAILS}${facilityId}`, { params });
    }
    getEnergyEfficiencyForAnnualStep(stepCode) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ENERGY_EFFICIENCY_FOR_ANNUAL_STEP}/${stepCode}`);
    }
    getForeignSupplierDetails(facilityId) {
        return this.http.get(`${environment.apiUrl}${GET_FOREIGN_SUPPLIER_DETAILS}${facilityId}`);
    }
    getReportById(reportId, summary) {
        const uri = summary ? `${reportId}/${summary}` : `${reportId}`;
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID}${uri}`);
    }
    getReportByIdandStep(reportId, currentStepCode, summary) {
        const uri = summary ? `${reportId}/${summary}` : `${reportId}/${currentStepCode}`;
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID_AND_STEP}${uri}`);
    }
    getCreditReportList(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${REPORT_LIST_URI}`, filterState, { params });
    }
    triggerActionController(metadata, redirect = false) {
        const action = metadata.action;
        const payload = metadata.data;
        if (action.workflowAction === 'VIEW') {
            this.router.navigate(this.helperService.getTranslatedPath(`/credit-creation-reports/report/${payload.id}`));
        }
        else if (action.workflowAction === 'DOWNLOAD_CREDIT_CREATION_REPORT') {
            this.downloadDocument(payload.id);
        }
        else if (action.workflowAction === 'VIEW_VERIFICATION_DETAILS') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/${payload.id}`));
        }
        else if (action.workflowAction === 'RESUBMIT') {
            if (payload.type === 'CREDIT_ADJUSTMENT') {
                this.router.navigate(this.helperService.getTranslatedPath(`/credit-adjustment-reports/report/${payload.id}/RESUBMIT`));
            }
            else {
                this.router.navigate(this.helperService.getTranslatedPath(`/credit-creation-reports/report/${payload.id}/RESUBMIT`));
            }
        }
        else if (action.workflowAction === 'SUBMIT_TO_ECCC_VERIFICATION_REPORT' || action.workflowAction === 'RESUBMIT_TO_ECCC_VERIFICATION_REPORT') {
            if (payload.type === 'CREDIT_ADJUSTMENT') {
                this.router.navigate(this.helperService.getTranslatedPath(`/credit-adjustment-reports/report/${payload.id}`));
            }
            else {
                this.router.navigate(this.helperService.getTranslatedPath(`/credit-creation-reports/report/${payload.id}`));
            }
        }
        else if (action.workflowAction === 'VIEW_VERIFICATION_REPORT') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/report-summary/report-submission/${payload.verificationReportId}`));
        }
        else {
            this.executeAction(payload, action, redirect);
        }
    }
    getReferenceCarbonIntensityForFuel(year, fuelId) {
        return this.http.get(`${environment.apiUrl}${CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL}/${year}/${fuelId}`)
            .pipe(map((response) => Number(response.value)));
    }
    updateCCR(payload) {
        const uri = payload.action === 'DISCARD' ? CCR_DISCARDS : CCR_UPDATE_STATUS;
        return this.http.post(`${environment.apiUrl}${uri}`, payload);
    }
    downloadDocument(documentId) {
        const uri = environment.serviceUrl + CCR_DOWNLOAD_REPORT + documentId;
        this.http.get(uri, { observe: 'response', responseType: 'blob' })
            .subscribe((response) => downloadBlob(response), () => this.http.get(uri, { observe: 'response' }).subscribe());
    }
    executeAction(payload, action, redirect) {
        const request = { id: payload.id, action: action.workflowAction };
        const confirmationMessage = action.workflowAction === 'CREATE_NOTICE_OF_ERROR' ? 'confirmationMessageNOECreation' : payload.type !== 'CREDIT_ADJUSTMENT' ? 'confirmationMessageCCReport' : 'confirmationMessageCAReport';
        const actionLabel = action.workflowAction;
        if (action.workflowAction === 'CREATE_NOTICE_OF_ERROR' ||
            action.workflowAction === 'CREATE_NOTICE_OF_SUSPENSION') {
            const uri = action.workflowAction === 'CREATE_NOTICE_OF_ERROR' ? '/notice-of-error' : '/notice-of-suspension';
            this.router.navigate(this.helperService.getTranslatedPath(uri), { state: { data: payload, formType: action.workflowAction } });
            return;
        }
        const sub$ = this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message: confirmationMessage,
            metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
                payload.legalName,
            ],
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe((result) => {
            sub$.unsubscribe();
            if (result) {
                this.modalService.closeAllMessageDialogs();
                this.updateCCR(request).subscribe(response => {
                    if (!redirect) {
                        this._refreshData$.next(true);
                    }
                    else {
                        this.resetStepper();
                        this.store.back();
                    }
                });
            }
        });
    }
    getFuelingStation(orgId, stationId) {
        return this.http.get(`${environment.apiUrl}${GET_FUELING_STATION}${orgId}/${stationId}`);
    }
    submitReport(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_SUBMIT_REPORT}`, Object.assign({}, payload, { action: 'SUBMIT' }));
    }
    getProjectListForAccount(accountId) {
        return this.http.get(`${environment.apiUrl}${CCR_PROJECT_LIST_FOR_ACCOUNT}/${accountId}`);
    }
    getProjectDetails(projectId) {
        return this.http.get(`${environment.apiUrl}${CCR_PROJECT_DETAILS}/${projectId}`);
    }
    openConfirmationModal(msg, yesNoButton = false) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: null,
            message: msg,
            type: "WARNING" /* WARNING */,
            yesNoButton,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    clearCallMethod() {
        this._callMethod$.next(null);
    }
    showErrorMessage(message) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message: message,
                },
            ],
            message: message,
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    showWarning(message, metaDataList) {
        return this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message: message,
            metaDataList,
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed();
    }
    getCarvonIntensitiesOfRegistrationReport(accountId, complianceYear) {
        return this.http.get(`${environment.apiUrl}${GET_CI_OF_REGISTRATION_REPORT}${accountId}/${complianceYear}`);
    }
    getAgreementByID(id) {
        return this.http.get(`${environment.apiUrl}${LOAD_AGREEMENT_BY_ID}${id}`);
    }
    getReferencedCarbonIntensityForElectricity(province) {
        return this.http.get(`${environment.apiUrl}${CCR_GET_REFERENCE_CI_ELECTRICITY}${province}`);
    }
    toggleCreditTransferStep(NILStepsCount) {
        if (NILStepsCount === 6) {
            this.steps = this.steps.filter(step => step.code !== 'STEP-7').map((step, index) => (Object.assign({}, step, { id: index + 1 })));
        }
        else if (this.steps.findIndex(step => step.code === 'STEP-7') === -1) {
            this.setReportSteps();
        }
    }
    getSubStepContent(step, index, subStepIndex = null) {
        let component = CCR_STEP_COMPONENTS.CREDT_ADJUSTMENT_UNDER_SEC_DETAILS;
        let name = this.getStepName(step[this.translateService.currentLang]);
        if (step.code === 'STEP-SUB_SEC_90') {
            component = CCR_STEP_COMPONENTS.CREDT_ADJUSTMENT_UNDER_SEC_DETAILS;
        }
        if (step.code === 'STEP-SUB_SEC_88') {
            component = CCR_STEP_COMPONENTS.CREDT_ADJUSTMENT_UNDER_SEC_DETAILS;
        }
        if (step.code === 'STEP-SUB_SEC_SUMMRY') {
            component = CCR_STEP_COMPONENTS.CREDT_ADJUSTMENT_SUMMARY;
        }
        name = name.toLocaleLowerCase();
        const _step = Object.assign({}, step, { name: name.charAt(0).toUpperCase() + name.slice(1), skipTranslate: true, completed: false, id: index + 1, component });
        _step.id = index + 1;
        _step.subId = subStepIndex + 1;
        return _step;
    }
    setReportSteps() {
        const steps = this.currentData.associatedSteps.map((step, index) => {
            const currentStepIdentifier = step.code.split('-')[1].trim();
            let component = CCR_STEP_COMPONENTS.CREDIT_CREATION_REPORT_STEP;
            if ((this.currentData.type === CCR_TYPE.QUARTERLY && currentStepIdentifier === '9') || (this.currentData.type === CCR_TYPE.ANNUAL && currentStepIdentifier === '7')) {
                component = CCR_STEP_COMPONENTS.CREDIT_CREATION_CONTACT_PERSON;
            }
            else if ((this.currentData.type === CCR_TYPE.QUARTERLY && currentStepIdentifier === '10') || (this.currentData.type === CCR_TYPE.ANNUAL && currentStepIdentifier === '8')) {
                component = CCR_STEP_COMPONENTS.CREDIT_CREATION_VERIFICATION;
            }
            else if ((this.currentData.type === CCR_TYPE.QUARTERLY && currentStepIdentifier === '11') || (this.currentData.type === CCR_TYPE.ANNUAL && currentStepIdentifier === '9')) {
                component = CCR_STEP_COMPONENTS.CREDIT_CREATION_SUMMARY;
            }
            else if (currentStepIdentifier === 'SEC_88_90') {
                component = CCR_STEP_COMPONENTS.CREDIT_CREATION_AJUSTMENTS;
            }
            const subSteps = [];
            if (step.code === 'STEP-SEC_88_90') {
                if (this.currentData.associatedCreditAdjustmentSubSteps && this.currentData.associatedCreditAdjustmentSubSteps.length) {
                    const underSectionSubsteps = this.currentData.associatedCreditAdjustmentSubSteps.filter(ele => ele.parentId === step.id);
                    sortBy(underSectionSubsteps, ['sequence']).map((subStep, j) => {
                        subSteps.push(Object.assign({}, this.getSubStepContent(subStep, index, j)));
                    });
                }
            }
            let _step = Object.assign({}, step, { name: this.getStepName(step[this.translateService.currentLang]), completed: false, skipTranslate: true, id: index + 1, component });
            if (subSteps.length) {
                _step.subSteps = subSteps;
                _step.skipMainStep = true;
                if (step.code === 'STEP-SEC_88_90') {
                    _step.skipMainStep = false;
                }
                _step.enabledSubSteps = true;
            }
            return _step;
        });
        this.steps = [...steps];
    }
    getStepName(val) {
        const arr = val.split(': ');
        arr.shift();
        return arr.join(': ');
    }
    reSubmitReport(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_RE_SUBMIT_REPORT}`, Object.assign({}, payload, { action: 'RESUBMIT' }));
    }
    getReportByIdToReSubmit(reportId) {
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID_TO_RESUBMIT}${reportId}`);
    }
    getDocUploadID() {
        return this.http.get(`${environment.apiUrl}${COMP_REP_DOC_UPLOAD_ID}`);
    }
    propose(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_PROPOSE}`, payload);
    }
    getCITableData(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_GET_CI_DATA}`, Object.assign({}, payload));
    }
}
CreditCreationReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditCreationReportsService_Factory() { return new CreditCreationReportsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HelperService), i0.ɵɵinject(i4.ModalService), i0.ɵɵinject(i5.StoreService)); }, token: CreditCreationReportsService, providedIn: "root" });
