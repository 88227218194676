import { Component, OnInit, Inject, ViewChild, TemplateRef, } from '@angular/core';
import { Dialog } from 'primeng';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { getInputField, CCR_ADJUSTMENT_STEP_PREFIX, CCR_TRANSLATE_PREFIX } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import { CreditCreationReportsService } from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { LookupService } from '@core/services/lookup.service';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
@Component({
  selector: 'app-ccr-adjustments-under-section-view-input-details',
  templateUrl: './ccr-adjustments-under-section-view-input-details.component.html',
  styleUrls: ['./ccr-adjustments-under-section-view-input-details.component.scss']
})
export class CcrAdjustmentsUnderSectionViewInputDetailsComponent implements OnInit {
  @ViewChild('dialog', { static: false }) dialog: Dialog;
  @ViewChild('allocateQtyTemplate', {static: true}) public allocateQtyTemplate: TemplateRef<any>;

  public translationPrefix = `${CCR_TRANSLATE_PREFIX}${CCR_ADJUSTMENT_STEP_PREFIX}`;
  public headerText = '';
  public visible = false;
  public fields: FormlyFieldConfig[];
  public form = new FormGroup({});
  public model: any = {};
  public tableConfig: ITableViewConfig;
  public tableData;

  @ViewChild('checkboxColTemplate', {static: true}) public checkboxColTemplate: TemplateRef<any>;
  @ViewChild('inputBoxColTemplate', {static: true}) public inputBoxColTemplate: TemplateRef<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CcrAdjustmentsUnderSectionViewInputDetailsComponent>,
    private formFactoryService: FormFactoryService,
    public service: CreditCreationReportsService,
    private lookupService: LookupService,
  ) { }

  ngOnInit() {
    this.translationPrefix = this.translationPrefix + `_${this.service.currentStep.code}`;
    this.setFields();
    this.setTableConfig();
  }

  setFields(){
    this.fields = this.formFactoryService.configureForm([
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getInputField('fuelName', `${CCR_ADJUSTMENT_STEP_PREFIX}_${this.service.currentStep.code}`, null, 'approvedCarbonIntensityRounded_info'),
          getInputField('approvedCarbonIntensityRounded', `${CCR_ADJUSTMENT_STEP_PREFIX}_${this.service.currentStep.code}`, null, 'approvedCarbonIntensityRounded_info'),
        ]
      }
     ]);
  }

  setTableConfig(){
    this.lookupService.getUnderSectionTableConfig('CREDIT_ADJUSTMENT_REPORT_SEC_90_DETAILS').subscribe(result=>{
      this.tableConfig = result
      const { columns, ...config } = this.tableConfig
      let _columns: any = [
        ...columns,
        {
          header: 'isOverride',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.checkboxColTemplate,
        },
        {
          header: 'overriddenValue',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.inputBoxColTemplate,
        },
      ];

      this.tableConfig = {
        ...config,
        ...{ columns: _columns },
      };
    })
  }

  onCheckBoxChange(element, data) {
    data.checked = !data.checked;
 }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    
  }

}
