import { Component, OnInit, Inject } from '@angular/core';
import { getOrganizationName } from '@module/credit-creation-reports/routines/create-credit-popup.routine';
import { IRawForm, IRawFormBase } from '@core/models/raw-form.types';
import { Router } from '@angular/router';

import { BehaviorSubject, Subject } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup, Validators } from '@angular/forms';
import { ICreditCreationReportPayload } from '@module/credit-creation-reports/credit-creation-reports.types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CreateCreditBalanceReportComponent } from '@module/credit-balance/components/create-credit-balance-report/create-credit-balance-report.component';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { StoreService } from '@core/store/store.service';
import { HelperService } from '@shared/services/helper.service';

import { CiPathwayReportService } from '@module/ci-pathway-report/ci-pathway-report.service';
import { getDictSelect } from '@core/utilities/raw-form.utils';
import { CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD, CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD_LABEL, CI_PATHWAY_REPORT_COMPLIANCE_PERIOD_FLD, CI_PATHWAY_REPORT_DETAILS_PREFIX, CI_PATHWAY_REPORT_FACILITY_FLD, CI_PATHWAY_REPORT_FACILITY_FLD_LABEL, CI_PATHWAY_REPORT_FUEL_TYPE_FLD, CI_PATHWAY_REPORT_FUEL_TYPE_FLD_LABEL, CI_PATHWAY_REPORT_POPUP_COMPLIANCE_PERIOD_FLD_LABEL, NEW_CI_PATHWAY_CREATION_PAYLOAD_DATA_KEY } from '@module/ci-pathway-report/ci-pathway-report.const';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { IApprovedCiFacilitiesListPayload, ICIPathwayReportSearchPayload, ICarbonIntensityPathwayReportPayload, ICiApprovedCiId, ICiFacilitiesFilterListData, ICiFacility, ICiFuelType } from '@module/ci-pathway-report/ci-pathway-report.types';
import { HttpErrorResponse } from '@angular/common/http';
import { FormService } from '@shared/services/form.service';
import { IDictCallback } from '@shared/services/form-factory.types';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-create-credit-revenue-report',
    templateUrl: './create-ci-pathway-report.component.html',
    styleUrls: ['./create-ci-pathway-report.component.scss']
})
export class CreateCiPathwayReportComponent implements OnInit {
    private _destroy$ = new Subject<any>();

    visible = true;
    fields: FormlyFieldConfig[];
    form = new FormGroup({});
    data: ICreditCreationReportPayload = {};

    public facilityList$: BehaviorSubject<Array<ICiFacility>> = new BehaviorSubject([]);
    public fuelTypeList$: BehaviorSubject<Array<ICiFuelType>> = new BehaviorSubject([]);
    public approvedCiIdList$: BehaviorSubject<Array<ICiApprovedCiId>> = new BehaviorSubject([]);
    public complianceYearList$: BehaviorSubject<Array<ICiApprovedCiId>> = new BehaviorSubject([]);
    initialFilterList: Array<any> = [];

    get isCurrentLangEnglish() {
        return this.translateService.currentLang === 'en' ? true : false;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public inputData: any,
        private dialogRef: MatDialogRef<CreateCreditBalanceReportComponent>,
        private formFactoryService: FormFactoryService,
        private service: CiPathwayReportService,
        private store: StoreService,
        private router: Router,
        private helperService: HelperService,
        private formService: FormService,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.fields = this.formFactoryService.configureForm(this.getFormFields());
        this.getComplianceYearListForCiPathwayReport();
    }

    getComplianceYearListForCiPathwayReport() {
        this.service.getComplianceYearForCiPathwayReport().subscribe(response => {
            if (response && response.length) { this.complianceYearList$.next(response); }
        });
    }

    getCiFacilitiesFilterData(complianceYear, accoundId?: number) {
        const payload: IApprovedCiFacilitiesListPayload = {
            complianceYear,
            facilityNameList: accoundId ? [accoundId] : []
        } as IApprovedCiFacilitiesListPayload;

        this.service.getCiFacilitiesReportList(payload).subscribe(
            (response: any) => {
                this.facilityList$.next(this.filterOutFacilities(response));
                this.initialFilterList = response;
                this.loadInititalApprovedCiIds(response); // used to pre-populate facility and fuel-type in specific case
            },
            (error: HttpErrorResponse) => this.formService.parseErrors(this.form, error.error)
        );
    }

    loadInititalApprovedCiIds(response: Array<any>) {
        const approvedCiIds = cloneDeep(response).map((ci) => {
            ci.name = ci.code;
            return ci;
        });
        const uniqueCiIds = Array.from(new Map(approvedCiIds.map(ci => [ci.id, ci])).values());
        this.approvedCiIdList$.next(uniqueCiIds as ICiApprovedCiId[]);
    }

    onApprovedCiIdChange(event: IDictCallback): void {
        // Used to pre-select facility and fuel-type if user already knows the Approved CI Id and selects it directly from dropdown without selecting the facility and fuel-type
        if (event && event.event && event.event['id']) {
            const selectedCI = this.initialFilterList.find((ci) => ci.id === event.event['id']);
            const selectedFacilityDetails = this.facilityList$.getValue().find((facility: ICiFacility) => facility.id === +selectedCI['dataId']);
            if (selectedFacilityDetails && selectedFacilityDetails.id) {
                this.fuelTypeList$.next(selectedFacilityDetails.fuelType);
                this.form.patchValue({
                    [CI_PATHWAY_REPORT_FACILITY_FLD]: +selectedCI['dataId'],
                    [CI_PATHWAY_REPORT_FUEL_TYPE_FLD]: +selectedCI['value'],
                    [CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD]: +selectedCI['id']
                });
            }
        }
    }

    updateFuelTypeSource(event: IDictCallback) {
        if (event && event.event && event.event['fuelType']) {
            this.fuelTypeList$.next(event.event['fuelType']);
            this.updatedApprovedCiIdsList(event.event['fuelType']);
            event.event['fuelType'].length > 1 && this.form.patchValue({ [CI_PATHWAY_REPORT_FUEL_TYPE_FLD]: null });
        }
    }

    updatedApprovedCiIdsList(fuelTypeList) {
        const approvedCiIds = [];
        fuelTypeList.forEach(fuelType => {
            approvedCiIds.push(...fuelType.approvedCiId)
        });
        this.approvedCiIdList$.next(approvedCiIds);
        approvedCiIds.length > 1 && this.form.patchValue({ [CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD]: null });
    }

    updateApprovedCiIdSource(event: IDictCallback) {
        if (event && event.event && event.event['approvedCiId']) {
            this.approvedCiIdList$.next(event.event['approvedCiId']);
        }
        this.form.get([CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD]).setErrors(null);
    }

    onCompliancePeriodChange(event: IDictCallback): void {
        if (event && event.event && event.event['id']) {
            const complianceYear = event.event['id'];
            this.getCiFacilitiesFilterData(complianceYear, this.hideOrgDropdown ? this.store.user.accountId : this.data.accountId);
        }
    }

    onAccountIdChange() {
        const complianceYear = this.form && (this.form.get('compliancePeriod') && this.form.get('compliancePeriod').value);
        const accountId = this.form && (this.form.get('accountId') && this.form.get('accountId').value);
        complianceYear && this.getCiFacilitiesFilterData(complianceYear && complianceYear, accountId && accountId);
    }

    filterOutFacilities(data: Array<ICiFacilitiesFilterListData> = []): Array<ICiFacility> {
        let filteredFacility: Array<ICiFacility> = [];
        const multipleFacilitiesArray = this.groupByDistinctField(data, 'dataId');
        multipleFacilitiesArray.forEach((groupedSingleFacilityArray) => {
            let facility: ICiFacility = {
                name: groupedSingleFacilityArray[0].name,
                id: groupedSingleFacilityArray[0].dataId,
                fuelType: this.filterOutFuelType(groupedSingleFacilityArray, 'fuelEn') // here will get the filtered multiple fuelType of same facility
            } as ICiFacility;

            filteredFacility.push(facility);
        });

        if (filteredFacility.length === 0) {
            this.resetDropdowns();
        }

        return filteredFacility;
    }

    resetDropdowns() {
        this.facilityList$.next([]);
        this.fuelTypeList$.next([]);
        this.approvedCiIdList$.next([]);
        this.form.patchValue({
            [CI_PATHWAY_REPORT_FACILITY_FLD]: null,
            [CI_PATHWAY_REPORT_FUEL_TYPE_FLD]: null,
            [CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD]: null
        });
        this.form.markAsPristine();
    }

    filterOutFuelType(array: Array<ICiFacilitiesFilterListData>, field: string): Array<ICiFuelType> {
        // Here will get all the objects of same facility but with different fuelType
        const filteredFueltype: Array<ICiFuelType> = [];

        const multipleFuelTypeArray = this.groupByDistinctField(array, field);
        multipleFuelTypeArray.forEach((groupedSingleFuelType: Array<ICiFacilitiesFilterListData>) => {
            let fuelType = {
                fuelEn: groupedSingleFuelType[0].fuelEn,
                fuelFr: groupedSingleFuelType[0].fuelFr,
                name: this.isCurrentLangEnglish ? groupedSingleFuelType[0].fuelEn : groupedSingleFuelType[0].fuelFr,
                id: +groupedSingleFuelType[0].value,
                approvedCiId: this.filterOutApprovedCiId(groupedSingleFuelType) // here will get the filtered multiple approveCiIds of same fuelType
            };
            filteredFueltype.push(fuelType);
        });

        return filteredFueltype;
    }

    filterOutApprovedCiId(array: Array<ICiFacilitiesFilterListData>): Array<ICiApprovedCiId> {
        // Here will get all the objects of same fuleType but with different approvedCiId's which are already distinct
        const filteredApprovedCiIds: Array<ICiApprovedCiId> = array.map((approvedCiId) => {
            return {
                id: approvedCiId.id,
                code: approvedCiId.code,
                name: approvedCiId.code
            }
        });

        return filteredApprovedCiIds;
    }

    groupByDistinctField(arr, field): Array<Array<ICiFacilitiesFilterListData>> {
        const groups = new Map();

        arr.forEach(item => {
            const value = item[field];
            if (!groups.has(value)) {
                groups.set(value, []);
            }
            groups.get(value).push(item);
        });

        return Array.from(groups.values());
    }

    get payload(): ICarbonIntensityPathwayReportPayload {
        return {
            accountId: this.hideOrgDropdown ? this.store.user.accountId : this.data.accountId,
            compliancePeriod: +this.form.value[CI_PATHWAY_REPORT_COMPLIANCE_PERIOD_FLD],
            facility: +this.form.value[CI_PATHWAY_REPORT_FACILITY_FLD],
            fuelType: +this.form.value[CI_PATHWAY_REPORT_FUEL_TYPE_FLD],
            approvedCiId: +this.form.value[CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD],
            facilityId: [+this.form.value[CI_PATHWAY_REPORT_FACILITY_FLD]]
        };
    }

    create(): void {
        this.form.get([CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD]).setValidators(Validators.required);
        this.form.markAllAsTouched();
        this.formFactoryService.markFormAsDirty(this.form);
        if (this.form.valid) {
            const apiPayload: ICIPathwayReportSearchPayload = { type: 'CI_PRODUCTION_OF_LOW_CI_FUELS' } as ICIPathwayReportSearchPayload;
            apiPayload.accountId = this.payload.accountId;
            apiPayload.complianceYear = this.payload.compliancePeriod;
            apiPayload.applicationId = this.payload.approvedCiId;
            apiPayload.facilityNameList = this.payload.facilityId;

            this.service.getCIApplication(apiPayload).subscribe(data => {
                if (data) {
                    this.service.currentData = { ...data, complianceYear: apiPayload.complianceYear, facilityId: apiPayload.facilityNameList }; // setting current data which will be used by other components  
                    this.service.prevStepData = { ...data, complianceYear: apiPayload.complianceYear, facilityId: apiPayload.facilityNameList }; // this will make sure other components have the initial data if user navigates directly through stepper (ie. without using the action button)
                }
                this.service.ciApplicationDataReceivedFromAPI$.next(true);
                this.close();
                this.router.navigate(this.helperService.getTranslatedPath(`/ci-pathway-reports/report`), { state: { data: data } });
            });
        }
    }

    getFormFields(): IRawFormBase[] {
        return [
            {
                groupFields: false,
                className: 'col-12',
                fieldGroup: [
                    getOrganizationName(() => this.onAccountIdChange(), this.hideOrgDropdown),
                    this.getCiPathwayCompliancePeriod(),
                    this.getCiPathwayFacility(),
                    this.getCiPathwayFuelType(),
                    this.getCiPathwayApprovedCiId()
                ],
            }];
    }


    getCiPathwayCompliancePeriod(onChange?: (event: IDictCallback) => void): IRawForm {
        return getDictSelect({
            id: CI_PATHWAY_REPORT_COMPLIANCE_PERIOD_FLD,
            className: 'col-6',
            source: this.complianceYearList$,
            label: `${CI_PATHWAY_REPORT_DETAILS_PREFIX}.${CI_PATHWAY_REPORT_POPUP_COMPLIANCE_PERIOD_FLD_LABEL}`,
            onChange: (event: IDictCallback) => this.onCompliancePeriodChange(event),
            required: true,
            ignoreTranslateOptions: true,
            ignoreDropdownAppend: true,
            skipInitialBlur: true,
            translatePrefix: CI_PATHWAY_REPORT_DETAILS_PREFIX,
        });
    };

    getCiPathwayFacility(onChange?: () => void): IRawForm {
        return getDictSelect({
            id: CI_PATHWAY_REPORT_FACILITY_FLD,
            className: 'col-6',
            source: this.facilityList$,
            label: `${CI_PATHWAY_REPORT_DETAILS_PREFIX}.${CI_PATHWAY_REPORT_FACILITY_FLD_LABEL}`,
            onChange: (event: IDictCallback) => this.updateFuelTypeSource(event),
            required: true,
            ignoreTranslateOptions: true,
            ignoreDropdownAppend: true,
            skipInitialBlur: true,
            translatePrefix: CI_PATHWAY_REPORT_DETAILS_PREFIX,
        });
    };

    getCiPathwayFuelType(onChange?: () => void, source?: any): IRawForm {
        return getDictSelect({
            id: CI_PATHWAY_REPORT_FUEL_TYPE_FLD,
            className: 'col-6',
            source: this.fuelTypeList$,
            label: `${CI_PATHWAY_REPORT_DETAILS_PREFIX}.${CI_PATHWAY_REPORT_FUEL_TYPE_FLD_LABEL}`,
            onChange: (event: IDictCallback) => this.updateApprovedCiIdSource(event),
            required: false,
            ignoreTranslateOptions: true,
            ignoreDropdownAppend: true,
            skipInitialBlur: true,
            translatePrefix: CI_PATHWAY_REPORT_DETAILS_PREFIX,
        });
    };

    getCiPathwayApprovedCiId(onChange?: () => void): IRawForm {
        return getDictSelect({
            id: CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD,
            className: 'col-6',
            source: this.approvedCiIdList$,
            label: `${CI_PATHWAY_REPORT_DETAILS_PREFIX}.${CI_PATHWAY_REPORT_APPROVED_CI_ID_FLD_LABEL}`,
            onChange: (event: IDictCallback) => this.onApprovedCiIdChange(event),
            required: true,
            ignoreTranslateOptions: true,
            ignoreDropdownAppend: true,
            skipInitialBlur: true,
            translatePrefix: CI_PATHWAY_REPORT_DETAILS_PREFIX,
        });
    };

    private get hideOrgDropdown(): boolean {
        const isCustomer = this.store.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER');
        if (this.store.user.associationsList.length === 1 && isCustomer) {
            this.data.accountId = this.store.user.associationsList[0].accountId;
            return true;
        } else {
            return false;
        }
    }

    close() {
        this.visible = false;
        this._destroy$.next();
        this._destroy$.complete();
        this.dialogRef.close();
    }

}
