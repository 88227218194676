import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import {
  CARApplyPrefix,
  CAR_CURRENT_STEP_KEY,
  CAR_DATA_KEY,
  CAR_NONEDITABLE_YEAR_PROPERTY,
  CAR_PREFIX,
  CAR_STEP_COMPONENTS,
  CAR_STEP_PREFIX,
  CAR_UNDER_SECTION_ELIGIBLE_YEAR_PROPERTY,
  CONFIRMATION_MESSAGES_ON_SUBMISSION,
} from '@module/credit-adjustment-report/const/credit-adjustment-report.const';
import {
  ICreditAdjustmentReport,
  ICreditAdjustmentReportGetAlphNumCI,
  ICreditAdjustmentReportGetFacilityPayload,
} from '@module/credit-adjustment-report/credit-adjustment-report.types';
import {
  CCR_APPROVED_CARBONINTENSITIES,
  CCR_SAVE_AND_PROCEED, CCR_SAVE_REPORT,
  CreditCreationReportsService, GET_REPORT_BY_ID,
  GET_REPORT_BY_ID_AND_STEP
} from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';


export const CAR_FACILITIES_URI = '/cfr-service/creditCreationReport/getFacilities';
export const CAR_CARBON_INTENSITY_IDS_LOOKUP_URI = '/cfr-service/application/lookupCarbonIntensityIDs';
export const CAR_FEEDSTOCK_TYPE_URI = '/account-service/application/getForCCRLookup';
export const CAR_APPLICATION_DETAILS_URI = CAR_FEEDSTOCK_TYPE_URI;
export const CAR_FUELS_FOR_SUBTYPE = '/cfr-service/complianceReport/getFuelsForSubType';
export const CAR_GET_ACTIVE_CREDITS = '/obps-service/findActiveCredits';
export const CAR_CREDIT_TYPE_REPLACEMENT_URI = '/cfr-service/creditCreationReport/getCreditTypeForFuelType';
export const GET_FINAL_SUMMARY_BY_ID = '/cfr-service/creditCreationReport/getFinalCARSummary/';
export const CAR_CREDIT_BLOCKS = '/obps-service/unit/findAll';
export const CAR_UNDER_SECTION_TABLE_DATA_URI = '/cfr-service/creditCreationReport/getFinalCi'
export const CAR_GET_REPORT_DATA_FOR_CI_UNDER_SECTION = '/cfr-service/creditCreationReport/getReportDataForCi'

@Injectable({
  providedIn: 'root',
})
export class CreditAdjustmentReportService extends CreditCreationReportsService {

  public registrationReportsDetail: any;
  private _report = new BehaviorSubject<ICreditAdjustmentReport>(null);

  stepRoutineMap: any = {};

  public currentStepInvalid = false;

  constructor(
    public http: HttpClient,
    public router: Router,
    public helperService: HelperService,
    public modalService: ModalService,
    public store: StoreService,
  ) {
    super(
      http,
      router,
      helperService,
      modalService,
      store,
      );
  }

  set report(report: ICreditAdjustmentReport) {
    this._report.next(report);
  }

  get report(): ICreditAdjustmentReport {
    return this._report.getValue();
  }

  getFacilities(payload: ICreditAdjustmentReportGetFacilityPayload) {
    return this.http.post(`${ environment.apiUrl }${ CAR_FACILITIES_URI }`, payload);
  }

  getAlphanumericIDofCI(payload: ICreditAdjustmentReportGetAlphNumCI): Observable<IApp.ILookup[]> {
    return this.http.post<IApp.ILookup[]>(`${ environment.apiUrl }${ CAR_CARBON_INTENSITY_IDS_LOOKUP_URI }`, payload);
  }

  getAdjustmentReportById(reportId, summary: boolean) {
    const uri = summary ? `${reportId}/${summary}` : `${reportId}`;
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID }${uri}`);
  }

  getReportByIdAndStep(reportId: number, currentStepCode: string, quarter: string, summary: boolean = false) {
    const uri = summary ? `${reportId}/${summary}` :quarter !== undefined ? `${reportId}/${currentStepCode}/${quarter}`:`${reportId}/${currentStepCode}` ;
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID_AND_STEP }${uri}`);
  }

  getUnderSectionTableData(payload){
    return this.http.post(`${ environment.apiUrl }${ CAR_UNDER_SECTION_TABLE_DATA_URI }`, {...payload});
  }

  getReportDataForCi(payload){
    return this.http.post(`${ environment.apiUrl }${ CAR_GET_REPORT_DATA_FOR_CI_UNDER_SECTION }`, {...payload})
  }

  getSummaryReportById(reportId: number, summary: boolean ,quarter: string,){
    const uri = `${reportId}/${summary}/${quarter}`;
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID }${uri}`);
  }

  getFinalCARSummary(reportId:number){
    return this.http.get<any>(`${environment.apiUrl}${GET_FINAL_SUMMARY_BY_ID}${reportId}`)
  }

  private getStepContent(step, index, subStepIndex = null) {
    let component =  CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_REPORT_STEP;
    if (step.code === 'STEP-5') {
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_EXPORT_LOW_CI_FUELS;
    } 
    else if (step.code === 'STEP-SEC_88_90') {
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_UNDER_SECTION;
    }
    else if (step.code === 'STEP-6') {
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_OTHER_ADJUSTMENTS;
    } else if (step.code === 'STEP-7') {
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_REPORT_STEP;
    }else if (step.code === 'STEP-8') {
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_DOCUMENTS;
    } else if (step.code === 'STEP-9') {
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_CONTACT_PERSON;
    }
    else if (step.code === 'STEP-10') {
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_SUMMARY;
    }
    const _step = {
        ...step,
        name: this.getStepName(step[this.translateService.currentLang]),
        skipTranslate: true,
        completed: false,
        creditCreationReportId: step.creditCreationReportId,
        id: index + 1,
        component,
      };
    _step.id = index + 1;
    if (subStepIndex !== null) {
      _step.subId = subStepIndex + 1;
    }
    return _step;
  }

  public getSubStepContent(step, index, subStepIndex = null) {
    let component =  CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_REPORT_STEP;
    let name = this.getStepName(step[this.translateService.currentLang])
    if(step.code === 'STEP-11'){
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_SUB_STEP_SUMMARY
      if(step.translate){
        name = this.translateService.instant(CARApplyPrefix(step.codeToTranslate,CAR_STEP_PREFIX));
      }
    }
    if(step.code === 'STEP-SUB_SEC_90'){
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_UNDER_SECTION_DETAILS
    }
    if(step.code === 'STEP-SUB_SEC_88'){
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_UNDER_SECTION_DETAILS
    }
    if(step.code === 'STEP-SUB_SEC_SUMMRY'){
      component = CAR_STEP_COMPONENTS.CREDIT_ADJUSTMENT_UNDER_SECTION_SUMMARY
    }
    name = name.toLocaleLowerCase()
    const _step = {
      ...step,
      name: name.charAt(0).toUpperCase() + name.slice(1) ,
      skipTranslate: true,
      completed: false,
      id: index + 1,
      component,
    };
    _step.id = index + 1;
    _step.subId = subStepIndex+1;
    return _step;
  }

  public setReportSteps() {
    const steps = this.currentData.associatedSteps.map((step, index) => {

      const currentStepIdentifier = step.code && step.code.split('-')[1].trim();

      const _step = this.getStepContent(step, index);
      const subSteps = [];
      if (['Q1', 'Q2', 'Q3', 'Q4'].includes(currentStepIdentifier)) {
        const currentSubSteps = this.currentData.associatedCreditAdjustmentSteps.filter(el => el.quarterCode === currentStepIdentifier)[0];
        if (currentSubSteps.associatedSteps && currentSubSteps.associatedSteps.length) {
          sortBy(currentSubSteps.associatedSteps, ['sequence']).map((subStep, j) => {
            subSteps.push({
              ...this.getSubStepContent(subStep, index, j),
              creditCreationReportId: currentSubSteps.creditCreationReportId,
              creditCreationReportQuarterCode: currentSubSteps.quarterCode,
              nilReport: currentSubSteps.nilReport
            });
          });
        }
      }
      else if(step.code === 'STEP-SEC_88_90'){
        if(this.currentData.associatedCreditAdjustmentSubSteps && this.currentData.associatedCreditAdjustmentSubSteps.length){
          const underSectionSubsteps = this.currentData.associatedCreditAdjustmentSubSteps.filter(ele=>ele.parentId === step.id)
          sortBy(underSectionSubsteps, ['sequence']).map((subStep, j) => {
            subSteps.push({
              ...this.getSubStepContent(subStep, index, j),
              
            });
          });
        }
      }

      if (subSteps.length) {
        _step.subSteps = subSteps;
        _step.skipMainStep = true;
        if(step.code === 'STEP-SEC_88_90'){
        _step.skipMainStep = false;

        }
        _step.enabledSubSteps = true;
      }
      return _step;
    });

    this.steps = [...steps];
    return [...steps];
  }

  public getStepName(val): string {
    const arr = val.split(': ');
    arr.shift();
    return arr.join(': ');
  }

  saveAndProceed(payload) {
    return this.http.post(`${ environment.apiUrl }${CCR_SAVE_AND_PROCEED}`, {...payload, action: 'SAVE'})
  }

  saveReport(payload) {
    return this.http.post(`${ environment.apiUrl }${CCR_SAVE_REPORT }`, {...payload, action: 'SAVE'})
  }

  get quarterNumber(): number {
    return this.currentStep && this.currentStep.code && Number(this.currentStep.code.replace('STEP-Q', ''));
  }

  get quarter(): string {
    return this.currentStep && this.currentStep.code && this.currentStep.code.replace('STEP-', '');
  }

  getFuelsForSubType(subType: string): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ CAR_FUELS_FOR_SUBTYPE }/${ subType }`);
  }

  fetchProperty(){
    let propertyUrl = CAR_NONEDITABLE_YEAR_PROPERTY;
    return this.http.get<IApp.Property>(`${environment.apiUrl}${propertyUrl}`)
     
  }
  fetchCARUnderSectionEligibleYear(){
    let propertyUrl = CAR_UNDER_SECTION_ELIGIBLE_YEAR_PROPERTY
    return this.http.get<IApp.Property>(`${environment.apiUrl}${propertyUrl}`)
  }

  getActiveCredits(accountId): Observable<any> {
    const payload = {
      freeText: null,
      unitStatusList: ['ACTIVE'],
      ccr: true,
      beforeComplianceYear: true,
      compliancePeriodList: [this.currentData.complianceYear],
      issuanceYear: this.currentData.complianceYear
    };
    return this.http.post<any>(`${ environment.apiUrl }${ CAR_GET_ACTIVE_CREDITS }/${ accountId }`, payload);
  }

  getCreditType(type: string): Observable<IApp.ILookup> {
    return this.http.get<IApp.ILookup>(`${ environment.apiUrl }${ CAR_CREDIT_TYPE_REPLACEMENT_URI }/${ type }`);
  }

  checkForConfirmationMessage(totalCreditsToBeCreated, totalCreditsToBeTransferred, totalCreditsToBeCancelled) {
    let confirmationMessage = '';
    let metaDataList = [];
    if (totalCreditsToBeCreated && totalCreditsToBeCreated > 0 &&
      (!totalCreditsToBeCancelled || totalCreditsToBeCancelled === 0) &&
      (!totalCreditsToBeTransferred || totalCreditsToBeTransferred === 0)) {
         confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsToBeCreated;
         metaDataList = [totalCreditsToBeCreated]
    } else if (totalCreditsToBeCreated && totalCreditsToBeCreated > 0 &&
      totalCreditsToBeTransferred && totalCreditsToBeTransferred > 0 &&
      (!totalCreditsToBeCancelled || totalCreditsToBeCancelled === 0)) {
        confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsTransferUponCreation;
        metaDataList = [totalCreditsToBeCreated, totalCreditsToBeTransferred]
    } else if (totalCreditsToBeCancelled && totalCreditsToBeCancelled > 0 &&
      (!totalCreditsToBeCreated || totalCreditsToBeCreated === 0) &&
      (!totalCreditsToBeTransferred || totalCreditsToBeTransferred === 0)) {
         confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsCancelAfterCreation;
         metaDataList = [totalCreditsToBeCancelled]
    } else if (totalCreditsToBeCreated && totalCreditsToBeCreated > 0 &&
      totalCreditsToBeCancelled && totalCreditsToBeCancelled > 0 &&
      (!totalCreditsToBeTransferred || totalCreditsToBeTransferred === 0)) {
        confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsCancelAndDeposit;
        metaDataList = [totalCreditsToBeCreated, totalCreditsToBeCancelled]
    } else if ((!totalCreditsToBeCreated || totalCreditsToBeCreated === 0) &&
      (!totalCreditsToBeCancelled || totalCreditsToBeCancelled === 0)) {
        confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageNoCreditsCancelAndDeposit;
        metaDataList = []
    }
    else if(totalCreditsToBeCreated && totalCreditsToBeCreated > 0 &&
      totalCreditsToBeCancelled && totalCreditsToBeCancelled > 0 && 
      totalCreditsToBeTransferred && totalCreditsToBeTransferred > 0){
        confirmationMessage = CONFIRMATION_MESSAGES_ON_SUBMISSION.confirmationMessageCreditsDepositCancelAndTransfered;
        metaDataList = [totalCreditsToBeCreated, totalCreditsToBeCancelled, totalCreditsToBeTransferred]
      }
    const result = {
      confirmationMessage,
      metaDataList
    };
    return result;
  }

  storeCurrentData() {
    const data = JSON.stringify({...this.currentData});
    sessionStorage.setItem(CAR_DATA_KEY, data);
    const currentStep = JSON.stringify(this.currentStep);
    sessionStorage.setItem(CAR_CURRENT_STEP_KEY, currentStep);
  }

  restoreCurrentData(skipRemove = false): any {
    const data = sessionStorage.getItem(CAR_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(CAR_DATA_KEY);
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  restoreLastStep(): any {
      const currentStep = sessionStorage.getItem(CAR_CURRENT_STEP_KEY);
      if (currentStep) {
        sessionStorage.removeItem(CAR_CURRENT_STEP_KEY);
        return JSON.parse(currentStep);
      } else {
        return null;
      }
  }

  public getCreditBlocks(pagination: IApp.IPagination, complianceYear?, accountId = this.currentData.accountId): Observable<any> {
    const unitStatusList = ['ACTIVE'];
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    const filterState = {
      freeText: null, unitStatusList,
      ccr: true,
      beforeComplianceYear: true,
      compliancePeriodList: [this.currentData.complianceYear],
      issuanceYear: this.currentData.complianceYear,
      facilityNameList: [accountId],
     };
    if (complianceYear) {
      filterState.compliancePeriodList = [complianceYear];
      filterState.issuanceYear = complianceYear;
    } else {
      filterState.compliancePeriodList = [this.currentData.complianceYear];
      filterState.issuanceYear = this.currentData.complianceYear;
    }
    return this.http.post<any>(`${ environment.apiUrl }${ CAR_CREDIT_BLOCKS }`, filterState, { params });
  }

  public showErrorMessage(message: string, metaDataList = []) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            { message },
          ],
          message,
          metaDataList,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }

  public getApprovedCarbonIntensities(complianceYear, fuelId, facilityName, reg = false, defaultCICode = null) {
    let uri: string;
    if (facilityName) {
      facilityName = facilityName.replace(/[^\w\s]/gi, '');
      uri = `${CCR_APPROVED_CARBONINTENSITIES}${complianceYear}/${fuelId}/${facilityName}`;
      if (defaultCICode) {
        uri += `/${defaultCICode}`;
      }
      if (reg) {
        uri += '?reg=true';
      }
    } else {
      uri = `${CCR_APPROVED_CARBONINTENSITIES}${complianceYear}/${fuelId}/12`;
    }
    return this.http.get(`${ environment.apiUrl }${uri}`);
  }

  checkPermission() {
    if ((this.store.user.hasPermission('SAVE_CREDIT_CREATION_REPORT') ||
      this.store.user.hasPermission('SUBMIT_CREDIT_CREATION_REPORT'))) {
      return true;
    }
    return false;
  }

}
