import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IRawFormBase } from '@core/models/raw-form.types';
import { CiPathwayReportService } from '@module/ci-pathway-report/ci-pathway-report.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';

@Component({
  selector: 'app-view-facility-details',
  templateUrl: './view-facility-details.component.html',
  styleUrls: ['./view-facility-details.component.scss']
})
export class ViewFacilityDetailsComponent implements OnInit {

  public visible = true;
  facilityPrefix;
  model: any = {};
  formGroup = new FormGroup({});
  fields: FormlyFieldConfig[];
  tooltip: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ViewFacilityDetailsComponent>,
    private formFactoryService: FormFactoryService,
    private translateService: TranslateService,
    private ciPathwayReportService: CiPathwayReportService
  ) { }

  ngOnInit() {
    if (this.data && this.data.facility) {
      this.model = this.data.facility;
      if(this.model['entity']){
        this.model['type'] = this.model['entity']['type'];
        this.model['name'] = this.model['entity']['name'];
      }
      this.tooltip = this.data.tooltip;
      if (this.data.facility.type === "PROVINCE") {
        this.model = { ...this.data.facility, name: this.translateService.instant(`COMMON.jurisdictionsList.${this.data.facility.name}`) }
      }
      this.facilityPrefix = this.formFactoryService.firstUpper(this.model.type);
    }
    this.fields = this.formFactoryService.configureForm(this.getFacilityDetails());
  }

  getTranslatedValues(type: 'Heading' | 'coOrdinates' | 'name'){
    if(this.ciPathwayReportService.currentData && this.ciPathwayReportService.currentData.ciApplicationDto){
      if(this.ciPathwayReportService.currentData.ciApplicationDto.type === "CI_CHARGING_STATIONS"){
        switch(type){
          case 'Heading' : return 'chargingStationDetails';
          case 'coOrdinates' : return 'gpsCoordinatesChargingStation';
          case 'name': return 'chargingStationName';
        }
      } else if (this.ciPathwayReportService.currentData.ciApplicationDto.type === "CI_FUELING_STATIONS"){
        switch(type){
          case 'Heading' : return 'fuelingStationDetails';
          case 'coOrdinates' : return 'gpsCoordinatesfuelingStation';
          case 'name': return 'fuelingStationName';
        }
      } else {
        switch(type){
          case 'Heading' : return 'facilityDetailsTitle';
          case 'coOrdinates' : return 'gpsCoordinates';
          case 'name': return 'facilityName';
        }
      }
    }
    return '';
  }

  getFacilityDetails(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        fieldGroup: [
          {
            type: 'input',
            className: 'col-6',
            key: 'name',
            label: `REGISTRATION_PAGE.${this.facilityPrefix}Form.${this.getTranslatedValues('name')}`,
            disabled: true,
            required: false,
            id: 'organizationName',
          },
        ],
      },
    ];
  }

  public close(): void {
    this.dialogRef.close(false);
  }


}
