<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1200px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  (onShow)="scrollToTop()"
  [header] = "headerText | translate">
  <h3 class="m-0 mt-4 pb-2"> {{translationPrefix + '.viewDetailsHeading' | translate}} </h3>

  <form [formGroup]="form">
    <formly-form [form]="form" [model]="model"  [fields]="fields"></formly-form>
  </form>

  <ng-template>
    <div class="table-area table1">
      <app-table-view
        [data]="tableData"
        [config]="tableConfig"
      >
      </app-table-view>
    </div>
  </ng-template>

  <div class="d-flex justify-content-end mt-2">
    <button type="button" disableOnUpload class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
    <button type="button"
            disableOnUpload
            class="btn btn-primary"
            (click)="save()">
      {{'CREDIT_CREATION_REPORT.reportStep.save' | translate}}
    </button>
  </div>
</p-dialog>

<ng-template #checkboxColTemplate let-data>
  <input
  #inputEl
  [(ngModel)]="data.checked"
  class="checkbox-align"
  (change)="onCheckBoxChange(this, data)"
  type="checkbox"
/>
</ng-template>

<ng-template #inputBoxColTemplate let-data>
  <input
  *ngIf="data.checked"
  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]="'separator.5'"
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data._quantity"
  [disabled]="isDisabled || data.disabled"
  [class.is-invalid]="!isDisabled && !data.disabled && data._quantity > data.quantity"
  class="form-control"
  type="text"
/>

<div class="invalid-feedback d-block"
     *ngIf="!data.checked">
  <span class="table-action-text">{{translationPrefix + '.notApplicable' | translate}}</span>
</div>
</ng-template>

<ng-template #allocateQtyTemplate let-data>
  <label for="{{data.id}}" class="wcag-visuallyhidden">dummy</label>
  <input
  #inputEl
  [allowNegativeNumbers]="false"
  [id]="data.tempId"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]="'separator.0'"
  [thousandSeparator]=""
  [(ngModel)]="data.offeredQuantity"
  class="form-control"
  [disabled]="service.disabled || data.disabled"
  type="text"
/>
</ng-template>

<ng-template #fuelQtyTemplate let-data>
  <label for="{{data.id}}" class="wcag-visuallyhidden">dummy</label>
  <input
  #inputEl
  [allowNegativeNumbers]="false"
  [id]="data.tempId"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]="'separator.0'"
  [thousandSeparator]=""
  [(ngModel)]="data.offeredQuantity"
  class="form-control"
  [disabled]="service.disabled || data.disabled"
  type="text"
/>
</ng-template>

<ng-template #energyDensityTemplate let-data>
  <label for="{{data.id}}" class="wcag-visuallyhidden">dummy</label>
  <input
  #inputEl
  [allowNegativeNumbers]="false"
  [id]="data.tempId"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]="'separator.0'"
  [thousandSeparator]=""
  [(ngModel)]="data.offeredQuantity"
  class="form-control"
  [disabled]="service.disabled || data.disabled"
  type="text"
/>
</ng-template>

<ng-template #liquidCreditsTemplate let-data>
  <label for="{{data.id}}" class="wcag-visuallyhidden">dummy</label>
  <input
  #inputEl
  [allowNegativeNumbers]="false"
  [id]="data.tempId"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]="'separator.0'"
  [thousandSeparator]=""
  [(ngModel)]="data.offeredQuantity"
  class="form-control"
  [disabled]="service.disabled || data.disabled"
  type="text"
/>
</ng-template>

<ng-template #gaseousCreditsTemplate let-data>
  <label for="{{data.id}}" class="wcag-visuallyhidden">dummy</label>
  <input
  #inputEl
  [allowNegativeNumbers]="false"
  [id]="data.tempId"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]="'separator.0'"
  [thousandSeparator]=""
  [(ngModel)]="data.offeredQuantity"
  class="form-control"
  [disabled]="service.disabled || data.disabled"
  type="text"
/>
</ng-template>

<ng-template #alphanumericIdDropdownTemplate let-data>

</ng-template>