import * as tslib_1 from "tslib";
import { OnInit, TemplateRef, } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { getInputField, CCR_ADJUSTMENT_STEP_PREFIX, CCR_TRANSLATE_PREFIX } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
export class CcrAdjustmentsUnderSectionViewInputDetailsComponent {
    constructor(data, dialogRef, formFactoryService, service, lookupService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.formFactoryService = formFactoryService;
        this.service = service;
        this.lookupService = lookupService;
        this.translationPrefix = `${CCR_TRANSLATE_PREFIX}${CCR_ADJUSTMENT_STEP_PREFIX}`;
        this.headerText = '';
        this.visible = false;
        this.form = new FormGroup({});
        this.model = {};
    }
    ngOnInit() {
        this.translationPrefix = this.translationPrefix + `_${this.service.currentStep.code}`;
        this.setFields();
        this.setTableConfig();
    }
    setFields() {
        this.fields = this.formFactoryService.configureForm([
            {
                groupFields: false,
                className: 'col-12',
                fieldGroup: [
                    getInputField('fuelName', `${CCR_ADJUSTMENT_STEP_PREFIX}_${this.service.currentStep.code}`, null, 'approvedCarbonIntensityRounded_info'),
                    getInputField('approvedCarbonIntensityRounded', `${CCR_ADJUSTMENT_STEP_PREFIX}_${this.service.currentStep.code}`, null, 'approvedCarbonIntensityRounded_info'),
                ]
            }
        ]);
    }
    setTableConfig() {
        this.lookupService.getUnderSectionTableConfig('CREDIT_ADJUSTMENT_REPORT_SEC_90_DETAILS').subscribe(result => {
            this.tableConfig = result;
            const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
            let _columns = [
                ...columns,
                {
                    header: 'isOverride',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.checkboxColTemplate,
                },
                {
                    header: 'overriddenValue',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.inputBoxColTemplate,
                },
            ];
            this.tableConfig = Object.assign({}, config, { columns: _columns });
        });
    }
    onCheckBoxChange(element, data) {
        data.checked = !data.checked;
    }
    scrollToTop() {
        setTimeout(() => {
            this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
        }, 500);
    }
    close() {
        this.dialogRef.close(false);
    }
    save() {
    }
}
