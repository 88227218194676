import { HttpParams } from '@angular/common/http';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { ADDITION_OF_PROJECT_TASKS, CCR_SUBMIT_REMINDER_TASKS, COMPLIANCE_REPORT_TASKS, NOTICE_OF_ERROR_TASKS, TASKTYPE_ACTION, COMPLIMENTARY_COM_REP_STATUS, COMPLIMENTARY_COM_REP_ACTIONS, COMP_REV_REP_ACTIONS } from '@shared/components/task-details/task-details.const';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { SUBMIT_PROJECT_ADDITIONS } from '@shared/services/project.service';
import { replaceParam } from '@core/utilities/utilities.constants';
import { notificationType, reportType, } from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.constants';
import { AdminTakeActionModalComponent } from '@shared/components/admin-take-action-modal/admin-take-action-modal.component';
import { CCR_COMPLIANCE_PERIOD_FLD, CCR_ORGANIZATION_NAME_FLD, CCR_QUARTER_FLD, CCR_TYPE_FLD } from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import { CCR_TYPE } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import { activityTypeMap } from '@module/credit-creation-reports/routines/create-credit-popup.routine';
import { ITP_ADMIN_REMARKS, REGISTRATION_REVIEW_UPDATE, RESPOND_TOADMIN_COMMENTS, CREDIT_ADJUSTMENT_REPORT_REVIEW, COCR_REVIEW_UPDATE, RESPOND_TOADMIN_COMMENTS_COCR, RESPOND_TOADMIN_COMMENTS_CCRR, getMBRReviewFields, } from '@shared/components/stepper/stepper.const';
import { EntityHistoryListModalComponent } from '@shared/components/entity-history-list-modal/entity-history-list-modal.component';
import { CiPathwayActionModalComponent } from '@module/ci-pathway-report/components/ci-pathway-action-modal/ci-pathway-action-model.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./helper.service";
import * as i4 from "../../modules/unit-management/services/unit-management.service";
import * as i5 from "../../modules/obligation-management/services/obligation-management.service";
import * as i6 from "./project.service";
import * as i7 from "../../core/store/store.service";
import * as i8 from "../../modules/cfr-registration/services/cfr-registration.service";
import * as i9 from "./modal.service";
import * as i10 from "../../modules/credit-creation-reports/services/credit-creation-reports.service";
import * as i11 from "../../modules/submission-management/services/submission-management.service";
import * as i12 from "../../modules/credit-clearance-mechanism/credit-clearance-mechanism.service";
import * as i13 from "../../core/services/lookup.service";
import * as i14 from "../../modules/material-balance-report/material-balance-report.service";
export const FETCH_ALL_TASKS = '/registry-server/todo/getAllTodo';
export const UNASSIGN_TASK = '/registry-server/todo/unAssign';
export const ASSIGN_TO_ME_TASK = '/registry-server/todo/assignToMe';
export const MARK_AS_READ_TASK = '/registry-server/todo/markRead';
export const OBLIGATION_DETAILS_FOR_TASK = '/registry-server/obligation/getByObligationIdForTask';
export const PROJECT_TASKS = '/registry-server/todo/getAllProjectTodo';
export const MARK_ITP_PROCESSED = '/obps-service/transaction/markItpProcessed';
export const CFR_RR_NEED_MORE_INFO = '/account-service/cfrAccount/registrationReportNeedMoreInfo';
export const CFR_RR_MARK_TASK_COMPLETE = '/account-service/cfrAccount/markAdminReviewComplete';
export const CFR_RR_RESPOND_TO_ADMIN_COMMENTS = '/account-service/cfrAccount/registrationReportResponseSubmit';
export const CFR_CAR_MARK_TASK_COMPLETE = '/cfr-service/creditCreationReport/updateStatus';
export const COMP_COMPLIANCE_REPORT_REVIEW = '/cfr-service/complimentryComplianceReport/review';
export const COMP_REV_REPORT_REVIEW = '/cfr-service/complianceCreditRevReport/review';
export const SUBMIT_RESPONSE_CCRR = '/cfr-service/complianceCreditRevReport/submitResponse';
export const CFR_CBR_MARK_TASK_COMPLETE = '/cfr-service/creditBalanceReport/updateStatus';
export const SUBMIT_RESPONSE_COCR = '/cfr-service/complimentryComplianceReport/submitResponse';
export const MBR_REPORT_REVIEW = '/cfr-service/materialBalanceReport/review';
export const SUBMIT_RESPONSE_MBR = '/cfr-service/materialBalanceReport/submitResponse';
export class TasksService extends AbstractPaginationService {
    constructor(http, router, helperService, unitManagementService, obligationManagementService, projectService, store, service, modalService, CCRService, submissionService, creditClearanceMechanismService, lookupService, MBRService) {
        super();
        this.http = http;
        this.router = router;
        this.helperService = helperService;
        this.unitManagementService = unitManagementService;
        this.obligationManagementService = obligationManagementService;
        this.projectService = projectService;
        this.store = store;
        this.service = service;
        this.modalService = modalService;
        this.CCRService = CCRService;
        this.submissionService = submissionService;
        this.creditClearanceMechanismService = creditClearanceMechanismService;
        this.lookupService = lookupService;
        this.MBRService = MBRService;
        this.valueChanges$ = new BehaviorSubject(null);
    }
    getAll(pagination, filter = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${FETCH_ALL_TASKS}`, filter, { params });
    }
    unassign(id) {
        return this.http.post(`${environment.apiUrl}${UNASSIGN_TASK}`, { id });
    }
    assignToMe(id) {
        return this.http.post(`${environment.apiUrl}${ASSIGN_TO_ME_TASK}`, { id });
    }
    markAsRead(id) {
        return this.http.post(`${environment.apiUrl}${MARK_AS_READ_TASK}`, { id });
    }
    obligationDetails(id) {
        return this.http.get(`${environment.apiUrl}${OBLIGATION_DETAILS_FOR_TASK}/${id}`);
    }
    isCreditType(task) {
        return task.unitClassCode === 'SCR' || task.unitClassCode === 'OCR' || task.unitClassCode === 'CFR';
    }
    processIntentToPay(payload) {
        return this.http.post(`${environment.apiUrl}${MARK_ITP_PROCESSED}`, payload);
    }
    registrationReviewUpdate(url, payload) {
        return this.http.post(`${environment.apiUrl}${url}`, payload);
    }
    complimentaryCompReportReviewUpdate(payload) {
        return this.http.post(`${environment.apiUrl}${COMP_COMPLIANCE_REPORT_REVIEW}`, payload);
    }
    comRevReportReviewUpdate(payload) {
        return this.http.post(`${environment.apiUrl}${COMP_REV_REPORT_REVIEW}`, payload);
    }
    mbrReportReview(payload) {
        return this.http.post(`${environment.apiUrl}${MBR_REPORT_REVIEW}`, payload);
    }
    respondToAdminCommentsMBR(payload) {
        return this.http.post(`${environment.apiUrl}${SUBMIT_RESPONSE_MBR}`, payload);
    }
    respondToAdminCommentsCCRR(payload) {
        return this.http.post(`${environment.apiUrl}${SUBMIT_RESPONSE_CCRR}`, payload);
    }
    respondToAdminCommentsCoCR(payload) {
        return this.http.post(`${environment.apiUrl}${SUBMIT_RESPONSE_COCR}`, payload);
    }
    respondToAdminComments(payload) {
        return this.http.post(`${environment.apiUrl}${CFR_RR_RESPOND_TO_ADMIN_COMMENTS}`, payload);
    }
    doAction(task, workflowAction = null) {
        const taskType = workflowAction || task.taskType.toString();
        if (task.entityType === IApp.NSDocument.EntityTypeEnum.UNIT && this.isCreditType(task)) {
            this.doUnitAction(task);
        }
        else if (taskType.indexOf('REGISTRATION_REPORT_CANCELLATION_REVIEW') > -1) {
            const payload = {
                id: task.entityId,
                accountId: task.accountId,
                discriminatorType: 'REGISTRATION_ACCOUNT',
            };
            this.service.getRegistrationDetails(payload).subscribe(data => this.router.navigate(this.helperService.getTranslatedPath('/request-registration-cancellation'), { state: { data: { rawData: data, actionType: 'VIEW_CANCELLATION' } } }));
        }
        else if (taskType.indexOf('REGISTRATION_REPORT_PENDING_SUBMISSION') > -1) {
            const subType = this.store.user.associationsList[0].accountDto.accountSubTypes === 'VB' ? 'VB' : 'PS';
            this.router.navigate(this.helperService.getTranslatedPath(`/cfr-registration/cfr/RR${subType}/${task.accountId}/${task.entityId}`));
        }
        else if (taskType.indexOf('PROJECT_TRANSFER_REQUEST_VIEW_NEED_MORE_INFO') > -1) {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${task.projectPid}/REVIEW_PROJECT_TRANSFER_REQUEST`), { state: { data: task, formType: 'REVIEW_PROJECT_TRANSFER_REQUEST' } });
        }
        else if (taskType === 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST') {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${task.projectPid}/REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST`), { state: { data: { transferDestAccountId: task.accountId }, formType: 'REVIEW_PROJECT_TRANSFER_REQUEST' } });
        }
        else if (NOTICE_OF_ERROR_TASKS.includes(taskType)) {
            if (task.entityType === 'NOTICE_OF_ERROR') {
                this.router.navigate(this.helperService.getTranslatedPath(`/notice-of-error/${task.entityId}`));
            }
            else {
                this.router.navigate(this.helperService.getTranslatedPath(`/notice-of-suspension/${task.entityId}`));
            }
        }
        else if (COMPLIANCE_REPORT_TASKS.includes(taskType)) {
            this.router.navigate(this.helperService.getTranslatedPath(`/compliance-report/report/${task.entityId}`));
        }
        else if (taskType === 'PROCESS_INTENT_TO_PAY_ADMIN') {
            this._showIntentToPayModal(task.entityId);
        }
        else if (taskType === 'PROJECT_OFFSET_RECEIVER_PENDING_REVIEW_CONSENT') {
            this.router.navigate(this.helperService.getTranslatedPath(`/aggregation-transfer-request/${task.entityId}/${task.projectPid}/${task.accountId}`));
        }
        else if (taskType === 'REVIEW_REGISTRATION_REPORT_UPDATE' || taskType === 'REVIEW_RESPONSE_FOR_REGISTRATION_REPORT_REVIEW') {
            this._showReviewRRUpdate(task.accountId, task.entityId, task.status);
        }
        else if (taskType === 'REVIEW_ADMIN_COMMENTS_ON_REGISTRATION_REPORT') {
            this._showRespondToAdminComments(task.accountId, task.entityId);
        }
        else if (taskType === 'REVIEW_ENTITY_ACCESS_REQUEST') {
            this._showReviewEntityAccessRequest(task.entityId);
        }
        else if (ADDITION_OF_PROJECT_TASKS.includes(taskType)) {
            this.reviewAdditionOfProjectSubmission(task.entityId);
        }
        else if (CCR_SUBMIT_REMINDER_TASKS.includes(taskType)) {
            if (task.entityId) {
                this.router.navigate(this.helperService.getTranslatedPath(`'/credit-creation-reports/report/${task.entityId}`));
            }
            else {
                this.createNewCreditCreationReport(taskType, task);
            }
        }
        else if (taskType === 'ACCOUNT_REVIEW' && task.userType === 'TYPE_CUST' && task.status === 'PENDING_KYC') {
            this.router.navigate(this.helperService.getTranslatedPath('/registration-pending'));
        }
        else if (taskType === 'ASSIGN_VB') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/select-verification-body/${taskType}/${task.entityId}`));
        }
        else if (taskType === 'REVIEW_VERIFICATION_ASSIGNMENT') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/assignment/${taskType}/${task.entityId}`));
        }
        else if (taskType === 'SELECT_TL_IR') {
            this.submissionService.selectedTeamLead = {};
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/teamlead-assignment/${task.entityId}/add`));
        }
        else if (taskType === 'SUBMIT_COI_CHECKLIST') {
            this.submissionService.selectedIndependentReviewer = {};
            this.submissionService.selectedTeamLead = {};
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/coi-checklist-details/${task.entityId}`));
        }
        else if (taskType === 'RESUBMIT_COI_NOTICE_REVIEW') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-notice-of-coi/notice-of-coi/submission/${task.verificationViewDto.id}/${task.entityId}`));
        }
        else if (taskType === 'SUBMIT_VERIFICATION_REPORT') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/report-summary/submission/${task.verificationViewDto.id}`));
        }
        else if (taskType === 'SUBMIT_PENDING_COI_CHECKLIST') {
            this.submissionService.selectedIndependentReviewer = {};
            this.submissionService.selectedTeamLead = {};
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/coi-checklist-details/submit/${task.entityId}`));
        }
        else if (taskType === 'SUBMIT_VERIFICATION_REPORT_TO_ECCC' || taskType === 'RESUBMIT_VERIFICATION_REPORT_TO_ECCC') {
            const entityJson = task.entityJson ? JSON.parse(task.entityJson) : null;
            if (entityJson) {
                if (entityJson.submissionType === 'CI_APPLICATION') {
                    this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/wizard/application/${entityJson.entityId}`));
                }
                else if (entityJson.submissionType === 'ANNUAL_REPORT') {
                    this.router.navigate(this.helperService.getTranslatedPath(`/credit-creation-reports/report/${entityJson.entityId}`));
                }
                else if (entityJson.submissionType === 'ADJUSTMENT_REPORT') {
                    this.router.navigate(this.helperService.getTranslatedPath(`/credit-adjustment-reports/report/${entityJson.entityId}`));
                }
                else if (entityJson.submissionType === 'COMPLIMENTRY_COMPLIANCE_REPORT') {
                    this.router.navigate(this.helperService.getTranslatedPath(`/complimentary-compliance-report/report/${entityJson.entityId}`));
                }
                else if (entityJson.submissionType === 'COMPLIANCE_CREDIT_REVENUE_REPORT') {
                    this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/credit-revenue/report/${entityJson.entityId}`));
                }
                else if (entityJson.submissionType === 'CI_PATHWAY_REPORT') {
                    this.router.navigate(this.helperService.getTranslatedPath(`/ci-pathway-reports/report/${entityJson.entityId}`));
                }
                else if (entityJson.submissionType === 'MATERIAL_BALANCE_REPORT') {
                    this.router.navigate(this.helperService.getTranslatedPath(`/material-balance/report/${entityJson.entityId}`));
                }
                else {
                    this.router.navigate(this.helperService.getTranslatedPath(`/compliance-report/report/${entityJson.entityId}`));
                }
            }
        }
        else if (taskType === 'SUBMIT_UPDATED_COI_CHECKLIST') {
            this.submissionService.selectedIndependentReviewer = {};
            this.submissionService.selectedTeamLead = {};
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/coi-checklist-details/${task.entityId}`));
        }
        else if (taskType === 'REVIEW_CREDIT_ADJUSTMENT_REPORT_ON_ADVERSE_OPINION') {
            this._showReviewCAR(task.accountId, task.entityId, task.status);
        }
        else if (taskType === 'MATERIAL_BALANCE_REPORT_REVIEW_MATERIAL_BALANCE_REPORT') {
            this._showReviewMBR(task);
        }
        else if (taskType === 'MATERIAL_BALANCE_REPORT_ADDITIONAL_INFORMATION_REQUESTED_FOR_CARBON_INTENSITY') {
            this._showRespondToAdminCommentsMBR(task);
        }
        else if (taskType === 'CREDIT_BALANCE_REPORT_PENDING_REVIEW') {
            this._showReviewCBR(task.accountId, task.entityId, task.status);
        }
        else if (taskType === 'CREDIT_ADJUSTMENT_REPORT_SEND_NOTICE_INSUFFICIENT_CREDIT_TO_CANCEL') {
            const metadata = {
                id: task.entityId,
                accountId: task.accountId ? task.accountId : null,
                complianceYear: task.complianceYear ? task.complianceYear : null,
                quantity: task.quantityOfCreditsToBeCancelled ? task.quantityOfCreditsToBeCancelled : null,
                creditCreationReportId: task.entityId ? task.entityId : null,
                submissionType: task.submissionType,
                new: true,
            };
            this.router.navigate(this.helperService.getTranslatedPath('/unit-management/list/notice-of-insufficient-credits'), { state: { data: metadata } });
        }
        else if (taskType === 'CREDIT_ADJUSTMENT_REPORT_CANCEL_EXCESS_COMPLIANCE_CREDIT') {
            const metadata = {
                submissionType: task.submissionType
            };
            this.router.navigate(this.helperService.getTranslatedPath(`/unit-management/list/notice-of-insufficient-credits/${task.insufficientCreditId}`), { state: { data: metadata } });
        }
        else if (taskType === 'REVIEW_CI_PATHWAY_REPORT' || taskType === 'CI_PATHWAY_REPORT_NEED_MORE_INFO' || taskType === 'REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION') {
            this._showReviewCiPathwayReport(task, (taskType === 'CI_PATHWAY_REPORT_NEED_MORE_INFO') && true);
        }
        else if (taskType === 'REVIEW_COMPLIMENTRY_COMPLIANCE_REPORT') {
            this._showReviewCoCR(task);
        }
        else if (taskType === 'PARTICIPANT_RESPONSE_RECIEVED_FOR_COMPLIMENTRY_COMPLIANCE_REPORT') {
            this._showReviewCoCR(task);
        }
        else if (taskType === 'MORE_INFO_REQUIRED_COMPLIMENTRY_COMPLIANCE_REPORT') {
            this._showRespondToAdminCommentsCoCR(task.accountId, task.entityId);
        }
        else if (taskType === 'REVIEW_COMPLIANCE_CREDIT_REVENUE_REPORT' || taskType === 'PARTICIPANT_RESPONSE_RECIEVED_FOR_CREDIT_REVENUE_REPORT') {
            this._showReviewCCRR(task);
        }
        else if (taskType === 'COMPLIANCE_CREDIT_REVENUE_REPORT_NEED_MORE_INFO') {
            this._showRespondToAdminCommentsCCCR(task.accountId, task.entityId, task);
        }
        else if (taskType === 'REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION' || taskType === 'REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_CUST' || taskType.includes('NOT_INT_CANCEL_PROJ_REG')) {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-notification/${notificationType.notificationOfCancelProjectRegistration}/${task.accountId}/${task.projectPid}/${task.entityId}`));
        }
        else {
            const action = TASKTYPE_ACTION[taskType] || {};
            let actionRoute = action.url;
            action.params.forEach(param => {
                actionRoute = replaceParam(actionRoute, param, task[param]);
            });
            if (taskType.indexOf('SECTION_181_OBLIGATION_REVIEW') > -1) {
                this.obligationManagementService.getObligationDetails(task.obligationId).subscribe(obligationData => {
                    const remittanceData = Object.assign({}, obligationData, { quantity: obligationData.originalCompensation, regularRateDeadline: obligationData.regularRateDeadlineStr });
                    this.router.navigate(this.helperService.getTranslatedPath(`/obligation-management/assign-obligation-section-181`), { state: { data: { remittanceData } } });
                });
            }
            else if (taskType.indexOf('OBLIGATION_OUTSTANDING') > -1) {
                this.router.navigate(this.helperService.getTranslatedPath(actionRoute), { state: { data: { obligationId: task.obligationId } } });
            }
            else if (taskType.indexOf('SUBMIT_COI_CHECKLIST') > -1) {
                if (task.entityId === -1 || task.entityType.toString() === 'VERIFICATION') {
                    this.showMessage(`new/${task.verificationViewDto.id}`, "WARNING" /* WARNING */, 'checklistCreationWarning');
                }
                else {
                    this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/coi-checklist/${task.entityId}`));
                }
            }
            else if (actionRoute === 'REDIRECT_TO_PROJECT') {
                this.projectService.getProjectDetails(task.entityId)
                    .subscribe(projectData => {
                    if (projectData.type === 'SUB') {
                        this.projectService.redirectToSubProject(projectData.masterProjectId, projectData.id);
                    }
                    else {
                        this.projectService.redirectToProjectScreen(null, task.entityId, projectData, 'project-details');
                    }
                });
            }
            else if (taskType.indexOf('SUBMIT_CORRECTED_REPORT') > -1) {
                this.store.setHistoryUrl();
                if (task.taskType.toString() === 'REVIEW_CREDIT_SUSPENSION') {
                    this.router.navigate(this.helperService.getTranslatedPath(`/corrected-report/${reportType.correctedReport}/${task.accountId}/${task.projectPid}/${task.projectReportId}`));
                }
                else {
                    this.router.navigate(this.helperService.getTranslatedPath(`/corrected-report/${reportType.correctedReport}/${task.accountId}/${task.projectPid}/${task.projectReportId}/${task.entityId}`));
                }
            }
            else if (actionRoute === 'REDIRECT_TO_PROJECT_TRANSFER') {
                const requestType = task['projectType'] === 'MASTER' ? 'TRANSFER_AGGREGATED_PROJECT' : 'TRANSFER_PROJECT';
                this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${task.projectPid}/${requestType}`), { state: { submissionId: task.entityId } });
            }
            else if (actionRoute === 'SETUP_CCM') {
                this.creditClearanceMechanismService.setupCCM({ complianceYear: task.complianceYear }).subscribe(result => {
                    this.router.navigate(this.helperService.getTranslatedPath('/credit-clearance/mechanism/details'), { state: { data: result } });
                });
            }
            else {
                this.store.setHistoryUrl();
                if (taskType === 'REVIEW_NOT_ERR_OMI_SUBMISSION_CUST') {
                    this.router.navigate(this.helperService.getTranslatedPath(actionRoute), { queryParams: { tasks: 1 } });
                }
                else {
                    this.router.navigate(this.helperService.getTranslatedPath(actionRoute));
                }
            }
        }
    }
    showMessage(entityId, type, message, metaData = null) {
        this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message: message,
                }
            ],
            message: message,
            metaData: metaData,
            type: type,
            continueOkButton: true,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe((result) => {
            if (result) {
                this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/coi-checklist/${entityId}`));
            }
        });
    }
    doUnitAction(task) {
        const filterState = {
            id: task.entityId,
            disabledAccountTypeOnly: false
        };
        this.unitManagementService
            .getAll(null, filterState)
            .subscribe(data => {
            if (data.content[0]) {
                this.unitManagementService.viewUnitDetails('SCR', data.content[0], false);
            }
            else {
                // for EIA account
                filterState.disabledAccountTypeOnly = true;
                this.unitManagementService
                    .getAll(null, filterState)
                    .subscribe(data => {
                    this.unitManagementService.viewUnitDetails('SCR', data.content[0], false);
                });
            }
        });
    }
    getProjectTasks(projectId = null) {
        const payload = { projectId };
        return this.http.post(`${environment.apiUrl}${PROJECT_TASKS}`, payload);
    }
    _showIntentToPayModal(entityId) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: entityId, itpAdminRemarks: '' },
            formConfig: ITP_ADMIN_REMARKS,
            title: 'TASK_TO_DO.adminTakeActionModal.title'
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                this.processIntentToPay(result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showReviewRRUpdate(accountId, entityId, status) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: entityId, accountId: accountId, adminRemark: '' },
            formConfig: REGISTRATION_REVIEW_UPDATE,
            title: 'TASK_TO_DO.registrationReportModal.title',
            customActionBtn: status === 'ACTIVE' ? 'TASK_TO_DO.adminTakeActionModal.needMoreInformation' : null,
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                const url = result.customAction ? CFR_RR_NEED_MORE_INFO : CFR_RR_MARK_TASK_COMPLETE;
                this.registrationReviewUpdate(url, result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showReviewCiPathwayReport(task, isNeedMoreInfo = false) {
        this.modalService.open(CiPathwayActionModalComponent, {
            model: { id: task.entityId, accountId: task.accountId, isNeedMoreInfo },
            title: `TASK_TO_DO.carbonIntensityPathwayReportModal.${isNeedMoreInfo ? 'needMoreInfoTitle' : 'title'}`,
            customActionBtn: isNeedMoreInfo ? '' : 'TASK_TO_DO.adminTakeActionModal.needMoreInformation',
            primaryActionBtn: `TASK_TO_DO.carbonIntensityPathwayReportModal.${isNeedMoreInfo ? 'submitResponse' : 'resolved'}`
        }).afterClosed().subscribe((result) => {
            result && this.valueChanges$.next(true);
        });
    }
    _showReviewCoCR(task) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: task.entityId, accountId: task.accountId, statusOfUnsatisfiedReductionRequirement: task.statusOfUnsatisfiedReductionRequirement ? task.statusOfUnsatisfiedReductionRequirement : null, adminRemark: '' },
            formConfig: COCR_REVIEW_UPDATE,
            title: 'TASK_TO_DO.complimentaryComplianceReportModal.title',
            customActionBtn: 'TASK_TO_DO.adminTakeActionModal.needMoreInformation',
            additionalActionBtn: 'TASK_TO_DO.complimentaryComplianceReportModal.referredToEnforcement',
            primaryActionBtn: 'TASK_TO_DO.complimentaryComplianceReportModal.resolved'
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                if (!result.customAction && !result.additionalAction) {
                    result.model.statusOfUnsatisfiedReductionRequirement = COMPLIMENTARY_COM_REP_STATUS.RESOLVED;
                    result.model.action = COMPLIMENTARY_COM_REP_ACTIONS.RESOLVED;
                }
                if (!result.customAction && result.additionalAction) {
                    result.model.statusOfUnsatisfiedReductionRequirement = COMPLIMENTARY_COM_REP_STATUS.REFERRED_TO_ENFORCEMENT;
                    result.model.action = COMPLIMENTARY_COM_REP_ACTIONS.REFERRED_TO_ENFORCEMENT;
                }
                if (result.customAction) {
                    result.model.statusOfUnsatisfiedReductionRequirement = COMPLIMENTARY_COM_REP_STATUS.ADDITIONAL_INFO;
                    result.model.action = COMPLIMENTARY_COM_REP_ACTIONS.ADDITIONAL_INFO;
                }
                this.complimentaryCompReportReviewUpdate(result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showReviewCCRR(task) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: task.entityId, accountId: task.accountId, adminRemark: '' },
            formConfig: COCR_REVIEW_UPDATE,
            title: 'TASK_TO_DO.complianceCreditRevenueReport.title',
            customActionBtn: 'TASK_TO_DO.adminTakeActionModal.needMoreInformation',
            additionalActionBtn: 'TASK_TO_DO.complianceCreditRevenueReport.creditSuspended',
            primaryActionBtn: 'TASK_TO_DO.complianceCreditRevenueReport.resolved'
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                if (!result.customAction && !result.additionalAction) {
                    result.model.statusOfUnsatisfiedReductionRequirement = COMPLIMENTARY_COM_REP_STATUS.RESOLVED;
                    result.model.action = COMP_REV_REP_ACTIONS.RESOLVED;
                }
                if (!result.customAction && result.additionalAction) {
                    result.model.action = COMP_REV_REP_ACTIONS.CREDIT_SUSPENDED;
                }
                if (result.customAction) {
                    result.model.action = COMP_REV_REP_ACTIONS.NEED_MORE_INFO;
                }
                this.comRevReportReviewUpdate(result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showRespondToAdminComments(accountId, entityId) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: entityId, accountId: accountId, userComment: '' },
            formConfig: this._getRespondToAdminCommentFormConfig(),
            title: 'TASK_TO_DO.respondToAdminComments.title',
            primaryActionBtn: 'TASK_TO_DO.respondToAdminComments.save'
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                this.respondToAdminComments(result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showRespondToAdminCommentsCoCR(accountId, entityId) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: entityId, accountId: accountId, comment: '' },
            formConfig: this._getRespondToAdminCommentCoCRFormConfig(),
            title: 'TASK_TO_DO.respondToAdminComments.title',
            primaryActionBtn: 'TASK_TO_DO.respondToAdminComments.save'
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                result.model.action = COMPLIMENTARY_COM_REP_ACTIONS.SUBMIT_RESPONSE;
                this.respondToAdminCommentsCoCR(result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showRespondToAdminCommentsCCCR(accountId, entityId, task) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: entityId, accountId: accountId, comment: '', adminRemark: task.comments },
            formConfig: this._getRespondToAdminCommentCCRRFormConfig(),
            title: 'TASK_TO_DO.respondToAdminComments.title',
            primaryActionBtn: 'TASK_TO_DO.respondToAdminComments.save'
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                result.model.action = COMPLIMENTARY_COM_REP_ACTIONS.SUBMIT_RESPONSE;
                this.respondToAdminCommentsCCRR(result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showReviewEntityAccessRequest(entityId) {
        this.modalService.open(EntityHistoryListModalComponent, { entityId: entityId, sourceKey: 'vbPreviousYearsEntities', editMode: true })
            .afterClosed()
            .subscribe((result) => {
            if (result) {
                this.valueChanges$.next(true);
            }
        });
    }
    _getRespondToAdminCommentFormConfig() {
        const formConfig = [...RESPOND_TOADMIN_COMMENTS];
        formConfig[0].fieldGroup[0].label = 'TASK_TO_DO.respondToAdminComments.comment';
        return formConfig;
    }
    _getRespondToAdminCommentCoCRFormConfig() {
        const formConfig = [...RESPOND_TOADMIN_COMMENTS_COCR];
        formConfig[0].fieldGroup[0].label = 'TASK_TO_DO.respondToAdminComments.comment';
        return formConfig;
    }
    _getRespondToAdminCommentCCRRFormConfig() {
        const formConfig = [...RESPOND_TOADMIN_COMMENTS_CCRR];
        formConfig[0].fieldGroup[0].label = 'TASK_TO_DO.respondToAdminComments.comment';
        return formConfig;
    }
    reviewAdditionOfProjectSubmission(entityId) {
        this.http.get(`${environment.apiUrl}${SUBMIT_PROJECT_ADDITIONS}/${entityId}`)
            .subscribe(response => {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-builder/sub/${response.id}`), { state: { masterProject: response } });
        });
    }
    createNewCreditCreationReport(taskType, task) {
        const payload = {
            [CCR_ORGANIZATION_NAME_FLD]: task.accountId,
            [CCR_COMPLIANCE_PERIOD_FLD]: task.complianceYear,
        };
        switch (taskType) {
            case 'SUBMIT_ANNUALY_CREDIT_CREATION_REPORT':
                payload[CCR_TYPE_FLD] = CCR_TYPE.ANNUAL;
                payload.activityCodes = activityTypeMap[CCR_TYPE.ANNUAL];
                break;
            case 'SUBMIT_QUARTERLY_CREDIT_CREATION_REPORT':
                payload[CCR_TYPE_FLD] = CCR_TYPE.QUARTERLY;
                payload[CCR_QUARTER_FLD] = task.quarterName;
                payload.activityCodes = activityTypeMap[CCR_TYPE.QUARTERLY];
                break;
            case 'SUBMIT_QUARTERLY_Q3Q4_CREDIT_CREATION_REPORT':
                payload[CCR_TYPE_FLD] = CCR_TYPE.QUARTERLY;
                payload[CCR_QUARTER_FLD] = 'Q3/Q4';
                payload.activityCodes = activityTypeMap[CCR_TYPE.QUARTERLY];
                break;
        }
        const sub$ = this.CCRService.getNewCreditCreationReport(payload)
            .subscribe(resp => {
            if (payload[CCR_TYPE_FLD] === CCR_TYPE.ANNUAL) {
                delete resp.quarterName;
            }
            sub$.unsubscribe();
            this.router.navigate(this.helperService.getTranslatedPath('/credit-creation-reports/report'), { state: { data: resp } });
        });
    }
    _showReviewCAR(accountId, entityId, status) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: entityId, accountId: accountId, adminRemark: '', action: 'MARK_REVIEWED_CAR_ADVERSE_OPINION' },
            formConfig: CREDIT_ADJUSTMENT_REPORT_REVIEW,
            title: 'TASK_TO_DO.creditAdjustmentReportModal.title',
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                const url = CFR_CAR_MARK_TASK_COMPLETE;
                this.registrationReviewUpdate(url, result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showReviewCBR(accountId, entityId, status) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: entityId, accountId: accountId, adminRemark: '', action: 'MARK_REVIEWED_CREDIT_BALANCE_REPORT' },
            formConfig: CREDIT_ADJUSTMENT_REPORT_REVIEW,
            title: 'TASK_TO_DO.creditBalanceReportModal.title',
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                const url = CFR_CBR_MARK_TASK_COMPLETE;
                this.registrationReviewUpdate(url, result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
    _showReviewMBR(task) {
        let model = null;
        this.MBRService.getReportById(task.entityId).subscribe((response) => {
            if (response) {
                model = response;
                const sources = {
                    carbonIntensityStatus: this.lookupService.getCIStatus(),
                    ciInvalidReason: this.lookupService.getInvalidReasons(),
                };
                this.modalService.open(AdminTakeActionModalComponent, {
                    model,
                    formConfig: getMBRReviewFields(sources, model),
                    title: 'TASK_TO_DO.MBR.reviewTitle',
                    customActionBtn: 'TASK_TO_DO.adminTakeActionModal.needMoreInformation',
                    primaryActionBtn: 'TASK_TO_DO.MBR.resolved',
                    customActionMandatoryFields: ['adminRemark'],
                    primaryActionMandatoryFields: ['carbonIntensityStatus'],
                }).afterClosed()
                    .subscribe((result) => {
                    if (result) {
                        if (result.model.carbonIntensityStatus !== 'INVALID') {
                            result.model.ciInvalidReason = null;
                        }
                        if (result.customAction) {
                            result.model.action = COMP_REV_REP_ACTIONS.NEED_MORE_INFO;
                        }
                        else {
                            result.model.action = COMP_REV_REP_ACTIONS.RESOLVED;
                        }
                        this.mbrReportReview(result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
                    }
                });
            }
        });
    }
    _showRespondToAdminCommentsMBR(task) {
        this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: task.entityId, accountId: task.accountId, comment: '' },
            formConfig: this._getRespondToAdminCommentCoCRFormConfig(),
            title: 'TASK_TO_DO.MBR.additionalInfoTitle',
            primaryActionBtn: 'TASK_TO_DO.MBR.additionalInfosave'
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                result.model.action = COMPLIMENTARY_COM_REP_ACTIONS.SUBMIT_RESPONSE;
                this.respondToAdminCommentsMBR(result.model).subscribe(() => this.valueChanges$.next(true), () => this.valueChanges$.next(false));
            }
        });
    }
}
TasksService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TasksService_Factory() { return new TasksService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HelperService), i0.ɵɵinject(i4.UnitManagementService), i0.ɵɵinject(i5.ObligationManagementService), i0.ɵɵinject(i6.ProjectService), i0.ɵɵinject(i7.StoreService), i0.ɵɵinject(i8.CfrRegistrationService), i0.ɵɵinject(i9.ModalService), i0.ɵɵinject(i10.CreditCreationReportsService), i0.ɵɵinject(i11.SubmissionManagementService), i0.ɵɵinject(i12.CreditClearanceMechanismService), i0.ɵɵinject(i13.LookupService), i0.ɵɵinject(i14.MaterialBalanceReportService)); }, token: TasksService, providedIn: "root" });
