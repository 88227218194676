import { ACCOUNT_SUMMARY_PUBLIC_FIELD } from '@module/account-management/account-management.const';
export const KYC_DOWNLOAD_TEMPLATE_URI = '/account-service/document/find/KYC_TEMPLATE';
export const obPsRegistrationPrefix = 'OBPS_REGISTRATION';
export const COMPONENT_ID = 'REGISTRATION';
export var REGISTRATION_TYPE;
(function (REGISTRATION_TYPE) {
    REGISTRATION_TYPE["INITIAL"] = "initial";
    REGISTRATION_TYPE["NFMP"] = "nfmp";
    REGISTRATION_TYPE["OBPS"] = "obps";
    REGISTRATION_TYPE["CFS"] = "cfs";
    REGISTRATION_TYPE["PUBLIC_BODY_GOVERNMENT"] = "publicBodyGovernment";
    REGISTRATION_TYPE["OFFSET"] = "offset";
    REGISTRATION_TYPE["CM"] = "cm";
})(REGISTRATION_TYPE || (REGISTRATION_TYPE = {}));
export var SOURCE_DATA_TYPE;
(function (SOURCE_DATA_TYPE) {
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["LOCAL"] = 0] = "LOCAL";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["BUSSINES_STRUCTURE"] = 1] = "BUSSINES_STRUCTURE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["COUNTRY"] = 2] = "COUNTRY";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["PROVINCE"] = 3] = "PROVINCE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ALL_PROVINCE"] = 4] = "ALL_PROVINCE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["AREA_OF_EXPERTISE"] = 5] = "AREA_OF_EXPERTISE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["AREA_OF_EXPERTISE_BY_ID"] = 6] = "AREA_OF_EXPERTISE_BY_ID";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["PROFESSIONAL_QUALIFICATIONS"] = 7] = "PROFESSIONAL_QUALIFICATIONS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ACCREDITATION_BODY"] = 8] = "ACCREDITATION_BODY";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ORGANIZATION"] = 9] = "ORGANIZATION";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ORGANIZATION_WITHOUT_AGREEMENT"] = 10] = "ORGANIZATION_WITHOUT_AGREEMENT";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ADDRESS"] = 11] = "ADDRESS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["FUELS"] = 12] = "FUELS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["SUB_ORGANIZATION"] = 13] = "SUB_ORGANIZATION";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["APPLICATION_SUBMISSION_TYPE"] = 14] = "APPLICATION_SUBMISSION_TYPE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ACCOUNT_NAMES"] = 15] = "ACCOUNT_NAMES";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["DELIVERY_MEDIUM"] = 16] = "DELIVERY_MEDIUM";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["QUANTIFICATION_METHOD"] = 17] = "QUANTIFICATION_METHOD";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["FEED_STOCK"] = 18] = "FEED_STOCK";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["STAGE_OF_PRODUCTION"] = 19] = "STAGE_OF_PRODUCTION";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["THERMAL_ELECTRICAL_COMPRESSION"] = 20] = "THERMAL_ELECTRICAL_COMPRESSION";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["STATIC_DATA"] = 21] = "STATIC_DATA";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CIMETHOD"] = 22] = "CIMETHOD";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["REGULATORY_REGIME"] = 23] = "REGULATORY_REGIME";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["VERIFICATION_BODY"] = 24] = "VERIFICATION_BODY";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["APPLICATION_SUBMISSION_ACTIVITY_TYPE"] = 25] = "APPLICATION_SUBMISSION_ACTIVITY_TYPE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["COMPLIANCE_PERIOD"] = 26] = "COMPLIANCE_PERIOD";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CFR_COMPLAINCE_PERIOD"] = 27] = "CFR_COMPLAINCE_PERIOD";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ELECTRIC_ENERGEY_SOURCE"] = 28] = "ELECTRIC_ENERGEY_SOURCE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ACTIVITY_SUBJECT_TO_APPLICATION"] = 29] = "ACTIVITY_SUBJECT_TO_APPLICATION";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["PREVIOUS_OPA"] = 30] = "PREVIOUS_OPA";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["PREVIOUS_OPA_ACCOUNT"] = 31] = "PREVIOUS_OPA_ACCOUNT";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["LOOKUP_TYPE_OF_ENERGY"] = 32] = "LOOKUP_TYPE_OF_ENERGY";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["OUTSIDE_CA_PROVINCE"] = 33] = "OUTSIDE_CA_PROVINCE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["PROVINCIAL_GRID"] = 34] = "PROVINCIAL_GRID";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ACCOUNT_TYPE"] = 35] = "ACCOUNT_TYPE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["LANGUAGE_OF_CORRESPONDANCE"] = 36] = "LANGUAGE_OF_CORRESPONDANCE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["GREEN_HOUSE_GAS"] = 37] = "GREEN_HOUSE_GAS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["OFFSET_PROTOCOLS"] = 38] = "OFFSET_PROTOCOLS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["PROVINCE_BY_COUNTRY"] = 39] = "PROVINCE_BY_COUNTRY";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["PROJECT_TYPE"] = 40] = "PROJECT_TYPE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["SUB_ACCOUNT_REASON"] = 41] = "SUB_ACCOUNT_REASON";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["MASTER_ACCOUNT"] = 42] = "MASTER_ACCOUNT";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["SEARCH_PROJECT_BY_ACCOUNT"] = 43] = "SEARCH_PROJECT_BY_ACCOUNT";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["PROJECT_CANCELLATION_REASON"] = 44] = "PROJECT_CANCELLATION_REASON";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["SUBMISSION_TYPES_LIST"] = 45] = "SUBMISSION_TYPES_LIST";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["SUBMISSION_REPORT_TYPE_LIST"] = 46] = "SUBMISSION_REPORT_TYPE_LIST";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ACTIVE_ACCOUNT_NAMES"] = 47] = "ACTIVE_ACCOUNT_NAMES";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ACTIVITY_COUNTRY"] = 48] = "ACTIVITY_COUNTRY";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ERP_PROJECT_TYPE"] = 49] = "ERP_PROJECT_TYPE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ERP_QUANTIFICATION_METHODS"] = 50] = "ERP_QUANTIFICATION_METHODS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ERP_APPROVAL_TEST_METHOD"] = 51] = "ERP_APPROVAL_TEST_METHOD";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["QM_STATUS_LIST"] = 52] = "QM_STATUS_LIST";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["QM_FUEL_TYPES"] = 53] = "QM_FUEL_TYPES";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CCR_TYPE"] = 54] = "CCR_TYPE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CCR_ACTIVITY_TYPE"] = 55] = "CCR_ACTIVITY_TYPE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CCR_COMPLIANCE_YEARS"] = 56] = "CCR_COMPLIANCE_YEARS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CCR_QUARTERS"] = 57] = "CCR_QUARTERS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CARBON_INTENSITY_IDS"] = 58] = "CARBON_INTENSITY_IDS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["AGREEMENT_TRANSFER_CREDIT_FUELS"] = 59] = "AGREEMENT_TRANSFER_CREDIT_FUELS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CI_APPLICATION_TYPE"] = 60] = "CI_APPLICATION_TYPE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["SECTION_FOR_CREDIT_CREATION"] = 61] = "SECTION_FOR_CREDIT_CREATION";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["ENERGY_EFFICIENCY_FOR_ANNUAL_STEP"] = 62] = "ENERGY_EFFICIENCY_FOR_ANNUAL_STEP";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["NOE_NATURE_OF_ERRORS"] = 63] = "NOE_NATURE_OF_ERRORS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["NOE_TYPE_OF_ACCOUNTS_FOR_NOTICE"] = 64] = "NOE_TYPE_OF_ACCOUNTS_FOR_NOTICE";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["AREA_OF_EXPERTISE_VB"] = 65] = "AREA_OF_EXPERTISE_VB";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["COMPLIANCE_REPORT_COMPLIANCE_YEARS"] = 66] = "COMPLIANCE_REPORT_COMPLIANCE_YEARS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CAR_COMPLIANCE_YEARS"] = 67] = "CAR_COMPLIANCE_YEARS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CREDIT_BALANCE_REPORT_COMPLIANCE_YEARS"] = 68] = "CREDIT_BALANCE_REPORT_COMPLIANCE_YEARS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CREDIT_REVENUE_REPORT_COMPLIANCE_YEARS"] = 69] = "CREDIT_REVENUE_REPORT_COMPLIANCE_YEARS";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["CCM_COMPLAINCE_PERIOD"] = 70] = "CCM_COMPLAINCE_PERIOD";
    SOURCE_DATA_TYPE[SOURCE_DATA_TYPE["COMPLIMENTARY_COMPLIANCE_REPORT_COMPLIANCE_YEARS"] = 71] = "COMPLIMENTARY_COMPLIANCE_REPORT_COMPLIANCE_YEARS";
})(SOURCE_DATA_TYPE || (SOURCE_DATA_TYPE = {}));
export const ACCOUNT_TYPE_IDS = {
    CM: 3,
    OFFSET: 6,
};
export const BUSINESS_STRUCTURE_IDS_BY_PROGRAM = {
    CM: 3,
    OFFSET: 13,
};
export var REGISTRATION_FIELDS;
(function (REGISTRATION_FIELDS) {
    REGISTRATION_FIELDS["ALL"] = "ALL";
    REGISTRATION_FIELDS["legalName"] = "legalName";
    REGISTRATION_FIELDS["individualName"] = "individualName";
    REGISTRATION_FIELDS["organizationBusEmail"] = "orgBusinessEmail";
    REGISTRATION_FIELDS["organizationBusPhone"] = "orgBusinessPhone";
    REGISTRATION_FIELDS["facilityId"] = "facilityId";
    REGISTRATION_FIELDS["businessStructure"] = "businessStructure.id";
    REGISTRATION_FIELDS["registrationNumber"] = "registrationNumber";
    REGISTRATION_FIELDS["certificateNumber"] = "certificateNumber";
    REGISTRATION_FIELDS["jurisdiction"] = "jurisdiction";
    REGISTRATION_FIELDS["registrationType"] = "registrationType";
    REGISTRATION_FIELDS["naics"] = "naics";
    REGISTRATION_FIELDS["address"] = "address";
    REGISTRATION_FIELDS["streetAddress"] = "streetAddress";
    REGISTRATION_FIELDS["municipality"] = "municipality";
    REGISTRATION_FIELDS["province"] = "province";
    REGISTRATION_FIELDS["country"] = "country";
    REGISTRATION_FIELDS["postalCode"] = "postalCode";
    REGISTRATION_FIELDS["latitude"] = "latitude";
    REGISTRATION_FIELDS["longitude"] = "longitude";
    REGISTRATION_FIELDS["name"] = "name";
    REGISTRATION_FIELDS["title"] = "title";
    REGISTRATION_FIELDS["telephone"] = "telephone";
    REGISTRATION_FIELDS["email"] = "email";
    REGISTRATION_FIELDS["businessTelephone"] = "businessTelephone";
    REGISTRATION_FIELDS["organizationId"] = "organizationId";
    REGISTRATION_FIELDS["responsibleName"] = "name";
    REGISTRATION_FIELDS["businessNumber"] = "businessNumber";
    REGISTRATION_FIELDS["persons"] = "persons";
    REGISTRATION_FIELDS["sapCustomerNumber"] = "sapCustomerNumber";
    REGISTRATION_FIELDS["sameAsOrganizationAddress"] = "sameAsOrganizationAddress";
    REGISTRATION_FIELDS["faxNumber"] = "faxNumber";
    REGISTRATION_FIELDS["publicView"] = "displayAccountInfoPublicView";
    REGISTRATION_FIELDS["facilityName"] = "facilityName";
    REGISTRATION_FIELDS["agreementRequired"] = "agreementRequired";
    REGISTRATION_FIELDS["englishTradeName"] = "englishTradeName";
    REGISTRATION_FIELDS["frenchTradeName"] = "frenchTradeName";
})(REGISTRATION_FIELDS || (REGISTRATION_FIELDS = {}));
export var ADDRESS_FIELDS;
(function (ADDRESS_FIELDS) {
    ADDRESS_FIELDS["sameAsOrganizationAddress"] = "sameAsOrganizationAddress";
    ADDRESS_FIELDS["sameAsPostalAddress"] = "sameAsPostalAddress";
    ADDRESS_FIELDS["streetNumber"] = "streetNumber";
    ADDRESS_FIELDS["streetName"] = "streetName";
    ADDRESS_FIELDS["city"] = "city";
    ADDRESS_FIELDS["province"] = "province";
    ADDRESS_FIELDS["poBox"] = "poBox";
    ADDRESS_FIELDS["postalCode"] = "postalCode";
    ADDRESS_FIELDS["country"] = "country";
    ADDRESS_FIELDS["latitude"] = "latitude";
    ADDRESS_FIELDS["longitude"] = "longitude";
})(ADDRESS_FIELDS || (ADDRESS_FIELDS = {}));
export var REGISTRATION_FIELDS_LABELS;
(function (REGISTRATION_FIELDS_LABELS) {
    REGISTRATION_FIELDS_LABELS["organizationId"] = "REGISTRATION_PAGE.cfsRegistrationForm.organizationID";
    REGISTRATION_FIELDS_LABELS["title"] = "REGISTRATION_PAGE.cfsRegistrationForm.title";
    REGISTRATION_FIELDS_LABELS["name"] = "REGISTRATION_PAGE.cfsRegistrationForm.name";
    REGISTRATION_FIELDS_LABELS["telNum"] = "REGISTRATION_PAGE.cfsRegistrationForm.telNum";
    REGISTRATION_FIELDS_LABELS["faxNumber"] = "REGISTRATION_PAGE.cfsRegistrationForm.faxNumber";
    REGISTRATION_FIELDS_LABELS["email"] = "REGISTRATION_PAGE.cfsRegistrationForm.email";
    REGISTRATION_FIELDS_LABELS["responsibleName"] = "REGISTRATION_PAGE.cfsRegistrationForm.responsibleName";
    REGISTRATION_FIELDS_LABELS["legalEntityNameLabel"] = "REGISTRATION_PAGE.cfsRegistrationForm.legalEntityName";
    REGISTRATION_FIELDS_LABELS["frenchTradeName"] = "REGISTRATION_PAGE.cfsRegistrationForm.frTradeName";
    REGISTRATION_FIELDS_LABELS["englishTradeName"] = "REGISTRATION_PAGE.cfsRegistrationForm.engTradeName";
})(REGISTRATION_FIELDS_LABELS || (REGISTRATION_FIELDS_LABELS = {}));
const ɵ0 = REGISTRATION_FIELDS.organizationBusPhone, ɵ1 = REGISTRATION_FIELDS.organizationBusEmail;
export const INITIAL_REGISTRATION_ENTITY_SECTION = {
    groupFields: false,
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.legalEntityNameLabel',
            disabled: false,
            required: true,
            id: 'legalname',
        },
        {
            type: 'dict-select',
            key: 'businessStructure.id',
            source: SOURCE_DATA_TYPE.BUSSINES_STRUCTURE,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.businessStructure',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.businessStructureList',
            id: 'businessStructure',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.organizationBusPhone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationBusPhone',
            disabled: false,
            required: true,
            id: ɵ0,
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.organizationBusEmail,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationBusEmail',
            disabled: false,
            required: true,
            id: ɵ1,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationNumberLabel',
            disabled: false,
            required: true,
            id: 'registrationNumber',
        },
    ],
};
export const INITIAL_REGISTRATION_ADDRESS_SECTION = {
    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'organizationAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,
            id: 'streetAddress',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,
            id: 'municipality',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.province,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            filter: { field: 'country', prop: 'code' },
            id: 'province',
        },
        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabled: false,
            required: true,
            defaultValue: 'CA',
            translatePrefix: 'COMMON.countryList',
            sort: false,
            id: 'country',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
            id: 'postalCode',
        },
    ],
};
export const INITIAL_REGISTRATION_AUTHORIZED_OFFICIAL_SECTION = {
    groupFields: true,
    key: 'authorizedOfficial',
    label: 'authorizedAccRep',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.name,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.name',
            disabled: false,
            required: true,
            id: 'name',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.title,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.title',
            disabled: false,
            required: true,
            id: 'title',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.email,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.businessEmail',
            disabled: false,
            required: true,
            id: 'businessEmail',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.businessTelephone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.businessTelephoneExt',
            disabled: false,
            required: true,
            id: 'businessTelephoneExt',
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.telephone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.mobilePhone',
            disabled: false,
            id: 'telephone',
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },
    ],
};
export const INITIAL_REGISTRATION_ENTITY_SECTION_INDIVIDUAL = {
    groupFields: false,
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.nameOfIndividual',
            disabled: false,
            required: true,
            id: 'legalName',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationNumberLabel',
            disabled: false,
            required: true,
            id: 'registrationNumber',
        },
    ],
};
export const INITIAL_REGISTRATION_ADDRESS_SECTION_INDIVIDUAL = {
    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'individualAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,
            id: 'streetAddress',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,
            id: 'municipality',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.province,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            filter: { field: 'country', prop: 'code' },
            id: 'province',
        },
        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabled: false,
            required: true,
            defaultValue: 'CA',
            translatePrefix: 'COMMON.countryList',
            sort: false,
            id: 'country',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
            id: 'postalCode',
        },
    ],
};
export const INITIAL_REGISTRATION_AUTHORIZED_OFFICIAL_SECTION_INDIVIDUAL = {
    groupFields: true,
    key: 'authorizedOfficial',
    label: 'individualContact',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.email,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.email',
            disabled: false,
            required: true,
            id: 'businessEmail',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.businessTelephone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.telephoneExt',
            disabled: false,
            required: true,
            id: 'businessTelephoneExt',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.telephone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.mobilePhone',
            disabled: false,
            id: 'telephone',
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },
    ],
};
export const NFMP_REGISTRATION_ENTITY_SECTION_ORG = {
    groupFields: false,
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.legalEntityNameLabel',
            disabled: false,
            required: true,
            id: 'legalName2',
        },
        {
            type: 'dict-select',
            key: 'businessStructure.id',
            source: SOURCE_DATA_TYPE.BUSSINES_STRUCTURE,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.businessStructure',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.businessStructureList',
            id: 'businessStructure2',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.organizationBusPhone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationBusPhone',
            disabled: false,
            required: true,
            id: 'businessTelephoneExt',
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.organizationBusEmail,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationBusEmail',
            disabled: false,
            required: true,
            id: 'businessEmail',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationNumberLabel',
            disabled: false,
            required: true,
            id: 'registrationNumber2',
        },
    ],
};
export const NFMP_REGISTRATION_ADDRESS_SECTION_ORG = {
    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'facilityAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.province,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.jurisdictionsList.',
        },
        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabledExpression: 'model.programType && model.programType !== "nfmp"',
            required: true,
            sort: false,
            translatePrefix: 'COMMON.countryList',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
        },
    ],
};
export const NFMP_REGISTRATION_ENTITY_SECTION_IND = {
    groupFields: false,
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.nameOfIndividual',
            disabled: false,
            required: true,
            id: 'legalName2',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationNumberLabel',
            disabled: false,
            required: true,
            id: 'registrationNumber2',
        },
    ],
};
export const NFMP_REGISTRATION_ADDRESS_SECTION_IND = {
    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'individualAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.province,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.jurisdictionsList.',
        },
        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabled: true,
            required: true,
            sort: false,
            translatePrefix: 'COMMON.countryList',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
        },
    ],
};
const ɵ2 = obPsRegistrationPrefix.concat('facilityId'), ɵ3 = obPsRegistrationPrefix.concat('certificateNumber'), ɵ4 = obPsRegistrationPrefix.concat('jurisdiction'), ɵ5 = obPsRegistrationPrefix.concat('registrationType'), ɵ6 = obPsRegistrationPrefix.concat('naics'), ɵ7 = obPsRegistrationPrefix.concat('sapCustomerNumber');
/*OBPS REGISTRATION FORM*/
export const OBPS_REGISTRATION_ENTITY_SECTION = {
    groupFields: false,
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.facilityName',
            disabled: false,
            required: true,
            id: COMPONENT_ID + '_legalName',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.facilityId,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.facilityId',
            disabled: false,
            required: true,
            id: ɵ2,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.certificateNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.certificateNumber',
            disabled: false,
            required: true,
            id: ɵ3,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.jurisdiction,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.jurisdiction',
            disabled: false,
            required: true,
            id: ɵ4,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationType',
            disabled: false,
            required: true,
            id: ɵ5,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.naics,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.naics',
            disabled: false,
            required: true,
            id: ɵ6,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.sapCustomerNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.sapCustomerNumber',
            disabled: false,
            required: true,
            id: ɵ7,
        },
        ACCOUNT_SUMMARY_PUBLIC_FIELD,
    ],
};
const ɵ8 = obPsRegistrationPrefix.concat('streetAddress'), ɵ9 = obPsRegistrationPrefix.concat('municipality'), ɵ10 = obPsRegistrationPrefix.concat('province'), ɵ11 = obPsRegistrationPrefix.concat('country'), ɵ12 = obPsRegistrationPrefix.concat('postalCode'), ɵ13 = obPsRegistrationPrefix.concat('latitude'), ɵ14 = obPsRegistrationPrefix.concat('longitude');
export const OBPS_REGISTRATION_ADDRESS_SECTION = {
    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'facilityAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,
            id: ɵ8,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,
            id: ɵ9,
        },
        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.province,
            source: SOURCE_DATA_TYPE.PROVINCE,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.jurisdictionsList',
            filter: { field: 'country', prop: 'code' },
            id: ɵ10,
        },
        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabled: true,
            required: true,
            sort: false,
            translatePrefix: 'COMMON.countryList',
            id: ɵ11,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
            id: ɵ12,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.latitude,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.latitude',
            max: 90,
            min: -90,
            disabled: false,
            required: false,
            id: ɵ13,
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.longitude,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.longitude',
            disabled: false,
            max: 180,
            min: -180,
            required: false,
            id: ɵ14,
        },
    ],
};
export const OBPS_REGISTRATION_RESPONSABLE_PERSON_SECTION = {
    groupFields: true,
    nestedPropertyKey: true,
    label: 'responsiblePerson',
    key: REGISTRATION_FIELDS.persons,
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [
        {
            type: 'input',
            parentKey: REGISTRATION_FIELDS.persons,
            key: REGISTRATION_FIELDS.organizationId,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationID',
            disabled: false,
            nestedKey: null,
            required: true,
        },
        {
            type: 'input',
            parentKey: REGISTRATION_FIELDS.persons,
            key: REGISTRATION_FIELDS.responsibleName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.name',
            disabled: false,
            nestedKey: null,
            required: true,
        },
        {
            type: 'input',
            parentKey: REGISTRATION_FIELDS.persons,
            key: REGISTRATION_FIELDS.businessNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.craBusinessNumber',
            disabled: false,
            nestedKey: null,
            required: true,
        },
    ],
};
export const PROVINCE = {
    type: 'dict-select',
    key: ADDRESS_FIELDS.province,
    source: SOURCE_DATA_TYPE.ALL_PROVINCE,
    translatePrefix: 'COMMON.jurisdictionsList',
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.provState',
    disabled: false,
    required: true,
    filter: { field: 'country', prop: 'code' },
    id: `province`,
};
export const DELIVERY_MEDIUM = {
    type: 'dict-select',
    key: 'deliveryMedium',
    source: SOURCE_DATA_TYPE.DELIVERY_MEDIUM,
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.receiveVerificationCode',
    disabled: false,
    required: true,
    translatePrefix: 'COMMON.deliveryType',
    id: 'deliveryMedium',
};
export const MOBILE = {
    type: 'input',
    key: 'telephone',
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.mobile',
    disabled: false,
    required: true,
    id: 'phoneNumber',
    placeholder: 'COMMON.messageSection.phonePlaceholder',
};
export const BUSINESS_TELEPHONE = {
    type: 'input',
    key: 'businessTelephone',
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.telNum',
    disabled: false,
    required: true,
    id: 'businessTelephone',
    placeholder: 'COMMON.messageSection.phonePlaceholder',
};
export const getOrganizationEmail = (options = {}) => {
    const field = {
        type: 'input',
        key: REGISTRATION_FIELDS.organizationBusEmail,
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.organizationEmail',
        disabled: false,
        required: true,
        id: REGISTRATION_FIELDS.organizationBusEmail,
    };
    return Object.assign({}, field, options);
};
export const getOrganizationPhone = (options = {}) => {
    const field = {
        type: 'input',
        key: REGISTRATION_FIELDS.organizationBusPhone,
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.organizationPhone',
        disabled: false,
        required: true,
        id: REGISTRATION_FIELDS.organizationBusPhone,
        placeholder: 'COMMON.messageSection.phonePlaceholder',
    };
    return Object.assign({}, field, options);
};
export const CFS_REGISTRATION_FORM = [
    {
        groupFields: true,
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.titleInfoSectionm',
        fieldGroup: [
            {
                type: 'input',
                key: 'legalName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.legalEntityName',
                disabled: false,
                required: true,
                id: 'legalname',
            },
            {
                type: 'input',
                key: 'registrationNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.busRegNum',
                disabled: false,
                required: false,
                id: 'registrationNumber',
            },
            {
                type: 'input',
                key: 'englishTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
                disabled: false,
                required: false,
                id: 'englishTradeName',
            },
            {
                type: 'input',
                key: 'frenchTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
                disabled: false,
                required: false,
                id: 'frenchTradeName',
            },
            getOrganizationEmail({ validators: ['email'] }),
            getOrganizationPhone(),
        ],
    },
    {
        groupFields: true,
        key: 'postalAddress',
        label: 'newOrgPostalAddress',
        changeExpr: 'this.cloneField($event, field, \'authorizedOfficial.postalAddress\', \'authorizedOfficial.postalAddress.sameAsOrganizationAddress\')',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'postalAddressCountry',
                sort: false,
            },
            {
                type: 'dummy',
            },
            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'postalAddressStreetNumber',
            },
            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'postalAddressStreetName',
            },
            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                required: true,
                disabled: false,
                id: 'postalAddressCity',
            },
            PROVINCE,
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'postalAddressPoBox',
            },
            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'postalAddressPostalCode',
            },
        ],
    },
    {
        groupFields: true,
        key: 'authorizedOfficial',
        label: 'newApllicantInfo',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'input',
                key: 'title',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.title',
                disabled: false,
                required: true,
                id: 'title',
            },
            {
                type: 'input',
                key: 'name',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.name',
                disabled: false,
                required: true,
                id: 'name',
            },
            MOBILE,
            // DELIVERY_MEDIUM,
            BUSINESS_TELEPHONE,
            {
                type: 'input',
                key: 'email',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.email',
                disabled: false,
                required: true,
                id: 'email',
                validators: ['email'],
            },
        ],
    },
    {
        groupFields: true,
        key: 'authorizedOfficial.postalAddress',
        label: 'applicantPostalAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'checkbox',
                key: REGISTRATION_FIELDS.sameAsOrganizationAddress,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.samePostalOrgAddress',
                disabled: false,
                required: false,
                changeExpr: 'this.disableForm($event, field, null, \'postalAddress\'); this.cloneFormGroup($event, field, \'postalAddress\', \'authorizedOfficial.postalAddress\')',
                className: 'col-12',
                id: 'sameAsOrganizationAddress',
            },
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
            {
                type: 'dummy',
            },
            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'AOstreetNumber',
            },
            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'AOstreetName',
            },
            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'AOcity',
            },
            PROVINCE,
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'AOpoBox',
            },
            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'AOpostalCode',
            },
        ],
    },
];
export const VB_ORG_INFO = [
    {
        groupFields: false,
        fieldGroup: [
            {
                type: 'input',
                key: 'legalName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.legalEntityName',
                disabled: false,
                required: true,
                id: 'legalname',
                disabledExpression: "model.editMode || model.existingOVB"
            },
            {
                type: 'input',
                key: 'registrationNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.busRegNum',
                disabled: false,
                required: false,
                id: 'registrationNumber',
                minLength: 9
            },
            {
                type: 'input',
                key: 'englishTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
                disabled: false,
                required: false,
                id: 'englishTradeName',
            },
            {
                type: 'input',
                key: 'frenchTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
                disabled: false,
                required: false,
                id: 'frenchTradeName',
            },
            getOrganizationEmail({ validators: ['email'] }),
            getOrganizationPhone(),
        ],
    },
    {
        groupFields: true,
        key: 'postalAddress',
        label: 'postalAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        changeExpr: 'this.cloneField($event, field, \'authorizedOfficial.postalAddress\', \'authorizedOfficial.postalAddress.sameAsOrganizationAddress\')',
        fieldGroup: [
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
            {
                type: 'dummy',
            },
            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'POstreetNumber',
            },
            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'POstreetName',
            },
            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'POcity',
            },
            PROVINCE,
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'POpoBox',
            },
            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'POpostalCode',
            },
        ],
    },
    {
        groupFields: true,
        key: 'authorizedOfficial',
        label: 'apllicantInfo',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'input',
                key: 'title',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.title',
                disabled: false,
                required: true,
                id: 'title',
            },
            {
                type: 'input',
                key: 'name',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.name',
                disabled: false,
                required: true,
                id: 'name',
            },
            MOBILE,
            // DELIVERY_MEDIUM,
            BUSINESS_TELEPHONE,
            {
                type: 'input',
                key: 'email',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.email',
                disabled: false,
                required: true,
                id: 'email',
                validators: ['email'],
            },
        ],
    },
    /**{
        groupFields: true,
        label: 'professionalQualifications',
        translatePrefix:'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup:[

            {
                key: 'authorizedOfficial.professionalQualificationIds',
                type: 'multi-checkbox',
                source: SOURCE_DATA_TYPE.PROFESSIONAL_QUALIFICATIONS,
                disabled: false,
                required:true,
                className: 'col-12',
                id:'PROFESSIONAL_QUALIFICATIONS'
            },
        ]
    },**/
    {
        groupFields: true,
        key: 'authorizedOfficial.postalAddress',
        label: 'applicantPostalAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'checkbox',
                key: REGISTRATION_FIELDS.sameAsOrganizationAddress,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.samePostalOrgAddress',
                disabled: false,
                required: false,
                className: 'col-12',
                changeExpr: 'this.disableForm($event, field); this.cloneFormGroup($event, field, \'postalAddress\', \'authorizedOfficial.postalAddress\')',
                id: 'sameAsOrganizationAddress',
            },
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
            {
                type: 'dummy',
            },
            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'AOstreetNum',
            },
            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'AOstreetName',
            },
            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'AOcity',
            },
            PROVINCE,
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'AOpoBox',
            },
            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'AOpostalCode',
            },
        ],
    },
];
export const VB_REG_REP_SUBM = [
    {
        groupFields: false,
        fieldGroup: [
            {
                type: 'input',
                key: 'legalname',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.legalEntityName',
                disabled: false,
                required: true,
                id: 'legalname',
            },
            {
                type: 'input',
                key: 'busRegNum',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.busRegNum',
                disabled: false,
                required: true,
                id: 'busRegNum',
            },
            {
                type: 'input',
                key: 'engTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
                disabled: false,
                required: true,
                id: 'engTradeName',
            },
            {
                type: 'input',
                key: 'frTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
                disabled: false,
                required: true,
                id: 'frTradeName',
            },
        ],
    },
    {
        groupFields: true,
        key: 'postalAddress',
        label: 'postalAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'input',
                key: 'streetNum',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'streetNum',
            },
            {
                type: 'input',
                key: 'streetNum',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'streetNum',
            },
            {
                type: 'input',
                key: 'cityTown',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'cityTown',
            },
            PROVINCE,
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'poBox',
            },
            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'postalCode',
            },
            {
                type: 'input',
                key: 'county',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                id: 'county',
            },
        ],
    },
    {
        groupFields: true,
        key: 'postalAddress',
        label: 'civicAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'checkbox',
                key: 'samePostalOrgAddress',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.samePostalOrgAddress',
                disabled: false,
                required: true,
                className: 'col-12',
                id: 'samePostalOrgAddress',
            },
            {
                type: 'input',
                key: 'streetNum',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'streetNum',
            },
            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'streetName',
            },
            {
                type: 'input',
                key: 'cityTown',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'cityTown',
            },
            {
                type: 'dict-select',
                source: SOURCE_DATA_TYPE.COUNTRY,
                key: 'provState',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.provState',
                disabled: false,
                required: true,
                id: 'provState',
                sort: false,
            },
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'poBox',
            },
            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'postalCode',
            },
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.country',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
        ],
    },
];
export const CFS_REGISTRATION_ACCOUNT_SUMMARY = [
    {
        groupFields: false,
        fieldGroup: [
            {
                type: 'input',
                key: 'englishTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
                disabled: false,
                required: false,
                id: 'englishTradeName',
            },
            {
                type: 'input',
                key: 'frenchTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
                disabled: false,
                required: false,
                id: 'frenchTradeName',
            },
            {
                type: 'input',
                key: 'registrationNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.busRegNum',
                disabled: false,
                required: false,
                id: 'registrationNumber',
            },
            getOrganizationEmail({ validators: [] }),
            getOrganizationPhone({ validators: [], placeholder: null }),
        ],
    },
    {
        groupFields: true,
        key: 'postalAddress',
        label: 'orgPostalAddress',
        changeExpr: 'this.cloneField($event, field, \'authorizedOfficial.postalAddress\', \'authorizedOfficial.postalAddress.sameAsOrganizationAddress\')',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'streetNumber',
            },
            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'streetName',
            },
            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                required: true,
                disabled: false,
                id: 'city',
            },
            PROVINCE,
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'poBox',
            },
            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'postalCode',
            },
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: true,
                required: true,
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
        ],
    },
];
export const POSTAL_ADDRESS_FORM_SECTION = {
    prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
    key: 'postalAddress',
    changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
    sameAsOrganization: {
        hide: false,
        changeExpr: null,
    },
    hidePoBox: false,
    sameAsPostal: {
        hide: true,
    },
    hideLatLong: true,
};
export const CIVIC_ADDRESS_FORM_SECTION = {
    prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
    key: 'civicAddress',
    sameAsOrganization: {
        hide: true,
    },
    hidePoBox: true,
    sameAsPostal: {
        hide: false,
        changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
    },
    hideLatLong: true,
};
export const REQUEST_REPLACING_MAO = [
    {
        groupFields: false,
        fieldGroup: [
            {
                type: 'readonly-input',
                className: 'col-12',
                key: REGISTRATION_FIELDS.legalName,
                label: 'ACCOUNTS_MODULE.accountSummary.organizationName',
                disabled: true,
                required: false,
                id: 'organizationName',
            },
            {
                type: 'input',
                className: 'col-12',
                key: 'email',
                label: 'ACCOUNTS_MODULE.accountSummary.maoEmail',
                disabled: false,
                required: true,
                id: 'maoEmail',
            },
            {
                type: 'textarea',
                className: 'col-12',
                key: 'reason',
                label: 'ACCOUNTS_MODULE.accountSummary.reqReason',
                disabled: false,
                required: false,
                id: 'reqReason',
            },
            {
                type: 'input',
                key: 'accountId',
                id: 'accountId',
                disabled: true,
                required: true,
                hideExpression: true,
            },
        ],
    },
];
export const PRIMARY_SUPPLIER_ACTIVITY_DETAILS = [
    {
        groupFields: false,
        fieldGroup: [
            {
                type: 'input',
                className: 'col-6',
                key: 'name',
                label: 'FACILITY_MODULE.facilities.facilityName',
                disabled: true,
                required: false,
                id: 'facilityName',
            },
            {
                type: 'input',
                className: 'col-6',
                key: 'organizationName',
                label: 'FACILITY_MODULE.facilities.organizationName',
                disabled: true,
                required: true,
                id: 'organizationName',
            },
        ],
    },
    {
        groupFields: true,
        label: 'FACILITY_MODULE.facilities.fuelProducedAtFacility',
        fieldGroup: [
            {
                type: 'multi-checkbox',
                key: 'fuelList',
                className: 'col-12',
                source: SOURCE_DATA_TYPE.FUELS,
                dataType: 'PRODFF/LIQUID',
                disabled: false,
                required: true,
                inline: true,
            },
        ],
    },
];
export const RESPOND_TOADMIN_COMMENTS = [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'textarea',
                className: 'col-12',
                key: 'userComment',
                label: 'REGISTRATION_PAGE.cfrPendingRegistration.respondToAdminComments',
                required: true,
            },
        ],
    }];
export const PRIMARY_SUPPLIER = {
    key: 'primarySupplier',
    type: 'checkbox',
    className: 'ml-5 col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt1',
    required: true,
};
export const REGISTERED_CREATOR = {
    key: 'registeredCreator',
    type: 'checkbox',
    className: 'ml-5 col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt2',
    required: true,
};
export const FOREIGN_SUPPLIER = {
    key: 'foreignSupplier',
    type: 'checkbox',
    className: 'ml-5 col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt3',
    required: true,
};
export const CARBON_INTENSITY_CONTRIBUTOR = {
    key: 'carbonIntensityContributor',
    type: 'checkbox',
    className: 'ml-5 col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt6',
    required: true,
};
export const ACCEPT_CANCELLATION = {
    key: 'acceptCancellation',
    type: 'checkbox',
    className: 'col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt4',
    required: true,
};
export const ACCEPT_CANCELLATION_VB = {
    key: 'acceptCancellation',
    type: 'checkbox',
    className: 'col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt4VB',
    required: true,
};
export const REQUEST_REGISTRATION_CANCELLATION_REMARKS = [
    {
        type: 'textarea',
        className: 'col-12',
        key: 'adminRemark',
        label: 'ACCOUNTS_MODULE.reqRegCancellation.remark',
        required: false,
        disabled: false,
        hideExpression: false,
    },
    {
        key: 'discloseInfo',
        type: 'checkbox',
        className: 'col-12',
        label: 'ACCOUNTS_MODULE.reqRegCancellation.opt5',
        required: false,
        disabled: false,
        hideExpression: false,
    },
];
export const ITP_ADMIN_REMARKS = [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'textarea',
                className: 'col-12',
                key: 'itpAdminRemarks',
                label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
                required: true,
            },
        ],
    }];
export const REGISTRATION_REVIEW_UPDATE = [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'textarea',
                className: 'col-12',
                key: 'adminRemark',
                label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
                required: true,
            },
        ],
    }];
export const APPLICATION_CONTACT_PERSON_POSTAL_ADDRESS_FORM_SECTION = {
    prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
    key: 'postalAddress',
    changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
    sameAsOrganization: {
        hide: false,
        changeExpr: null,
    },
    hidePoBox: false,
    sameAsPostal: {
        hide: true,
    },
    hideLatLong: true,
};
export const APPLICATION_CONTACT_PERSON_CIVIC_ADDRESS_FORM_SECTION = {
    prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
    key: 'civicAddress',
    sameAsOrganization: {
        hide: true,
    },
    hidePoBox: true,
    sameAsPostal: {
        hide: false,
        changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
    },
    hideLatLong: true,
};
export const CREDIT_ADJUSTMENT_REPORT_REVIEW = [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'textarea',
                className: 'col-12',
                key: 'adminRemark',
                label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
                required: true,
            },
        ],
    }];
export const COCR_REVIEW_UPDATE = [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'textarea',
                className: 'col-12',
                key: 'adminRemark',
                label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
                required: true,
                validators: ['validateEmptyString']
            },
        ],
    }];
export const RESPOND_TOADMIN_COMMENTS_COCR = [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'textarea',
                className: 'col-12',
                key: 'comment',
                label: 'REGISTRATION_PAGE.cfrPendingRegistration.respondToAdminComments',
                required: true,
                validators: ['validateEmptyString']
            },
        ],
    }];
export const RESPOND_TOADMIN_COMMENTS_CCRR = [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'textarea',
                className: 'col-12',
                key: 'comment',
                label: 'REGISTRATION_PAGE.cfrPendingRegistration.respondToAdminComments',
                required: true,
                validators: ['validateEmptyString']
            },
        ],
    }];
export const getMBRReviewFields = (sources, model) => {
    return [{
            groupFields: false,
            fieldGroup: [
                {
                    type: 'dict-select',
                    key: 'carbonIntensityStatus',
                    label: 'TASK_TO_DO.carbonIntensityPathwayReportModal.carbonIntensityStatus',
                    disabled: false,
                    translatePrefix: 'TASK_TO_DO.carbonIntensityPathwayReportModal',
                    required: false,
                    placeholder: 'selectItem',
                    source: sources.carbonIntensityStatus,
                },
                {
                    type: 'dict-select',
                    key: 'ciInvalidReason',
                    label: 'TASK_TO_DO.carbonIntensityPathwayReportModal.reason',
                    disabled: false,
                    requiredExpression: 'model.carbonIntensityStatus === "INVALID"',
                    placeholder: 'selectItem',
                    hideExpression: 'model.carbonIntensityStatus!== "INVALID"',
                    bindValue: 'code',
                    source: sources.ciInvalidReason,
                },
                {
                    type: 'textarea',
                    className: 'col-12',
                    key: 'adminRemark',
                    label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
                    required: true,
                    validators: ['validateEmptyString'],
                },
            ],
        }];
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
